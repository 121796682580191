import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Message } from "../../../redux/actions";

import _ from "lodash";

const PREFIX = "Messages";
const classes = {
  html: `${PREFIX}-html`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.html}`]: {
    "& pre": {
      color: theme.palette.text.primary,
    },
  },
}));

const FindingMessages = (props) => {
  const { comments, users, currentUser } = props;

  useEffect(() => {
    props.fetchComments();
  }, []);

  if (comments.length === 0) {
    return <div className="findingMessages" />;
  }

  return (
    <Root className="findingMessages hasComments">
      {comments.map((comment, index) => {
        const sender = users.find((u) => u.id === comment.sender);
        const senderName =
          comment.sender === currentUser.id
            ? `${currentUser.first_name} ${currentUser.last_name} (Me)`
            : sender?.name;
        return (
          <div key={index} className="comment">
            <div className="commentLabel">
              {senderName ? (
                <span className="commentSender">{senderName}</span>
              ) : null}
              <span>
                {moment
                  .utc(comment.created)
                  .tz(moment.tz.guess(true))
                  .format("lll z")}
              </span>
            </div>
            <div
              className={classes.html}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: comment.body }}
            />
          </div>
        );
      })}
    </Root>
  );
};

FindingMessages.propTypes = {
  fetchComments: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = (state, { findingId }) => {
  const comments = _(state.message.getIn(["messages", findingId], []))
    .map((obj) => {
      if (!obj["created"]) {
        obj.params["created"] = moment
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS999");
      }
      return obj;
    })
    .orderBy("created")
    .value();
  return {
    comments,
    currentUser: state.session.settings.user,
  };
};

const mapDispatchToProps = (dispatch, { findingId }) => ({
  fetchComments: () => dispatch(Message.getCommentsForFinding({ findingId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindingMessages);
