import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import Codex from "@blumira/blu_constants";

// External CSS dependencies must be imported before ours.
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import "./index.css";
import "./index.scss";
import App from "./App";
import configureStore from "./redux/store";
import { initSentry } from "./lib/sentry";
import config from "./config";

import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import { Auth0Provider } from "@auth0/auth0-react";

// Initialize blu_constants
Codex.initialize(
  `${config.api}/blu-constants/` ||
    `${window.location.protocol}//${window.location.hostname}:8090/blu-constants/`
);

// Initialize Sentry
initSentry();

const {
  store,
  store: { dispatch, getState },
} = configureStore();

let isAppUpdateAvailable = false;

const checkForUpdates = () => {
  fetch("/manifest.json", {
    method: "HEAD",
  })
    .then((response) => {
      const lastModified = response.headers.get("last-modified");
      const storedLastModified = localStorage.getItem("last-modified");
      if (storedLastModified == null)
        localStorage.setItem("last-modified", lastModified);
      else if (storedLastModified !== lastModified) isAppUpdateAvailable = true;
    })
    .catch((err) => err); // TODO: what would we do with an error here?
};

setInterval(checkForUpdates, 300 * 1000); // every 5 minutes

const action = (
  <Button color="secondary" onClick={() => window.location.reload()}>
    Refresh
  </Button>
);

const container = document.getElementById("root");
const root = createRoot(container);

(async () => {
  root.render(
    <Auth0Provider
      cacheLocation={"localstorage"}
      useRefreshTokens={true}
      domain={config.auth.auth0.domain}
      clientId={config.auth.auth0.client_id}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.auth.auth0.audience,
        scope: "openid profile",
      }}
    >
      <Provider store={store}>
        <App />
        <Snackbar
          action={action}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={isAppUpdateAvailable}
          message={"A new version of Blumira is available."}
        />
      </Provider>
    </Auth0Provider>
  );
})();

export { store, getState, dispatch };
