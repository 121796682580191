import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { get, isNil } from "lodash";
import moment from "moment-timezone";

import Request from "lib/api/Request";
import { ExecutiveSummariesAPI } from "lib/api/ExecutiveSummariesAPI";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Checkbox,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { Alert, AlertTitle } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Root, rootClasses } from "./styles.js";
import SimpleTable from "views/Components/SimpleTable.js";
import { validateEmail } from "views/Components/Form/validators";

import { baseContactSalesUrl } from "views/Pages/BillingPageView";

import unrotatedSummary from "../../../images/unrotatedSummary.png";
import logo from "../../../images/blumira-logo-blue.svg";

import { isValidURL, isOrganizationChildOfNFR } from "utils";

import SnapshotDialog from "./SnapshotDialog";

import {
  getNewArray,
  getHasOrgChanged,
  recipientCheckBoxList,
  contentSectionCheckBoxList,
} from "./utils.js";

const API = {
  executiveSummaries: new ExecutiveSummariesAPI(),
};

// legacy licenses outside of MSPs should receive upsell CTA
export const isOrgNotMspAndLicenseIsLegacy = ({
  license,
  isOrgAChildOrg,
  isOrgAParentOrg,
}) => {
  const isMsp = isOrgAChildOrg || isOrgAParentOrg;
  return (
    !isMsp &&
    (license.isTagged("limited-to-advanced") ||
      license.isTagged("limited-to-cloud") ||
      license.isTagged("limited-to-microsoft"))
  );
};

const renderDateCell = (column, model) => {
  const timeValue = model[column.field];
  if (!timeValue) {
    return "";
  }
  return moment.utc(timeValue).tz(moment.tz.guess(true)).format("YYYY-MM");
};

const ExecutiveSummariesPageView = (props) => {
  const [shouldHideAnnouncementBanner, setShouldHideAnnouncementBanner] =
    useState(false);
  const [currentOrg, setCurrentOrg] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [roleRecipients, setRoleRecipients] = useState([10, 20]);
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [contentSections, setContentSections] = useState([1, 2, 3]); // default to all
  const [customText, setCustomText] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [newEmailRecipientText, setNewEmailRecipientText] = useState("");
  const [isSnapshotDialogOpen, setIsSnapshotDialogOpen] = useState(false);
  const [
    isAddEmailRecipientButtonDisabled,
    setIsAddEmailRecipientButtonDisabled,
  ] = useState(true);
  const [hasOrgConfigChanged, setHasOrgConfigChanged] = useState(false);
  const [isFooterLogoCustom, setIsFooterLogoCustom] = useState(false);
  const [isSummaryPageCustom, setIsSummaryPageCustom] = useState(false);
  const [previousSummaries, setPreviousSummaries] = useState([]);
  const [contactSalesUrl, setContactSalesUrl] = useState(baseContactSalesUrl);

  useEffect(() => {
    const newShouldHideBannerVal = localStorage.getItem(
      "hideAnnouncementBanner"
    );
    setShouldHideAnnouncementBanner(!!newShouldHideBannerVal);
  }, []);

  useEffect(() => {
    if (props.currentUser) {
      const { first_name = "", last_name = "", email = "" } = props.currentUser;
      const contactUrlToSet =
        baseContactSalesUrl +
        `?firstname=${first_name}&lastname=${last_name}&email=${email}`;
      setContactSalesUrl(contactUrlToSet);
    }
  }, [props.currentUser]);

  useEffect(() => {
    const getPreviousSummariesData = async () => {
      try {
        const { data = [] } = await API.executiveSummaries.list();
        setPreviousSummaries(data);
      } catch (e) {
        setErrorMessage("Oops, we had trouble loading your previous summaries");
        setPreviousSummaries([]);
      }
    };

    const getOrgData = async (orgId) => {
      try {
        setIsLoading(true);
        const orgRequest = new Request("/org", [{ field: "id", value: orgId }]);
        const currentOrgModelArr = await orgRequest.get();
        setCurrentOrg(currentOrgModelArr[0]);
        setIsLoading(false);
      } catch (e) {
        setErrorMessage(
          "Oops, we had trouble loading your organization's details"
        );
        setIsLoading(false);
      }
    };

    if (props.currentOrgId) {
      setIsLoading(true);
      getOrgData(props.currentOrgId);
      getPreviousSummariesData();
    }
  }, [props.currentOrgId]);

  useEffect(() => {
    if (currentOrg) {
      resetOrgExecutiveSummariesValues(currentOrg);
    }
  }, [currentOrg]);

  useEffect(() => {
    if (newEmailRecipientText) {
      const isEmailValid = validateEmail(newEmailRecipientText);
      if (isEmailValid) setIsAddEmailRecipientButtonDisabled(false);
      else setIsAddEmailRecipientButtonDisabled(true);
    } else {
      setIsAddEmailRecipientButtonDisabled(true);
    }
  }, [newEmailRecipientText]);

  useEffect(() => {
    const executiveSummarySettingsInState = {
      roleRecipients: roleRecipients,
      emailRecipients: emailRecipients,
      contentSections: contentSections,
      customText: customText,
      logoUrl: logoUrl,
    };

    const newHasOrgConfigChanged = getHasOrgChanged({
      org: currentOrg,
      executiveSummarySettings: executiveSummarySettingsInState,
    });

    setHasOrgConfigChanged(newHasOrgConfigChanged);
  }, [
    roleRecipients,
    emailRecipients,
    contentSections,
    customText,
    logoUrl,
    currentOrg,
  ]);

  const resetOrgExecutiveSummariesValues = (organization) => {
    const defaultRoleRecipients =
      organization.market && organization.market === 20 ? [] : [10, 20];
    const roleRecipientsToSet = get(
      organization,
      ["executiveSummaries", "role_recipients"],
      defaultRoleRecipients
    );
    setRoleRecipients(roleRecipientsToSet);

    const emailRecipientsToSet = get(
      organization,
      ["executiveSummaries", "email_recipients"],
      []
    );
    setEmailRecipients(emailRecipientsToSet);

    const contentSectionsToSet = get(
      organization,
      ["executiveSummaries", "content_sections"],
      [1, 2, 3] // default to all
    );
    setContentSections(contentSectionsToSet);

    const customTextToSet = get(
      organization,
      ["executiveSummaries", "custom_text"],
      ""
    );
    setCustomText(customTextToSet);

    // if this user has custom summary text,
    // set the radio button to reflect
    if (customTextToSet) setIsSummaryPageCustom(true);

    const logoUrlToSet = get(
      organization,
      ["executiveSummaries", "logo_url"],
      ""
    );
    setLogoUrl(logoUrlToSet);

    // if this user has a logo url,
    // set the radio button to reflect
    if (logoUrlToSet) setIsFooterLogoCustom(true);
  };

  const handleSaveChanges = () => {
    const newExecutiveSummarySettings = {
      role_recipients: roleRecipients,
      email_recipients: emailRecipients,
      content_sections: contentSections,
      custom_text: isSummaryPageCustom ? customText : "",
      logo_url: isFooterLogoCustom ? logoUrl : "",
    };

    setIsSaving(true);
    try {
      currentOrg.set({ executiveSummaries: newExecutiveSummarySettings });
      currentOrg.update().then((updatedOrg) => {
        setCurrentOrg(updatedOrg);
        setIsSaving(false);
        setHasOrgConfigChanged(false);
      });
    } catch (e) {
      setErrorMessage(
        "Oops, we had trouble saving your organization's details"
      );
      setIsSaving(false);
    }
  };

  const handleResetChanges = () => {
    resetOrgExecutiveSummariesValues(currentOrg);
  };

  const handleHideAnnouncementBanner = (evt) => {
    evt.stopPropagation();
    setShouldHideAnnouncementBanner(true);
    localStorage.setItem("hideAnnouncementBanner", "true");
  };

  const toggleSnapshotDialog = () => {
    setIsSnapshotDialogOpen(!isSnapshotDialogOpen);
  };

  const renderIntroductionSection = () => (
    <div className={rootClasses.introductionOuterContainer}>
      <div>
        <h2 className={rootClasses.introductionPrimaryText}>
          Executive Summaries are high-level snapshots of your Blumira
          environment delivered right to your inbox every month + quarter!
        </h2>
        <ul className={rootClasses.unorderdList}>
          <li>
            Created automatically at the beginning of each calendar month and
            calendar quarter (Email + PDF)
          </li>
          <li>
            Helpful in demonstrating the value of Blumira to business
            stakeholders
          </li>
          <li>Including rolling trends of the previous 12 months/quarters</li>
        </ul>
        <div className={rootClasses.linkContainer}>
          <span className={rootClasses.span} onClick={toggleSnapshotDialog}>
            <p>Click here to see sample snapshot</p> <OpenInNewIcon />
          </span>
          <a
            target={"_blank"}
            rel={"noopener noreferrer"}
            href={"https://blumira.help/executive-summaries"}
          >
            {" "}
            Learn more <OpenInNewIcon />
          </a>
        </div>
      </div>
      <div>
        <img
          src={unrotatedSummary}
          className={rootClasses.image}
          alt={"Executive Summaries"}
        />
      </div>
    </div>
  );

  const shouldOrgBeRestricted = () => {
    const shouldRestrict = isOrgNotMspAndLicenseIsLegacy({
      license: props.license,
      isOrgAChildOrg: props.isOrgAChildOrg,
      isOrgAParentOrg: props.isOrgAParentOrg,
    });

    return shouldRestrict;
  };

  const isRoleRecipientChecked = (recipientValue) => {
    return !!roleRecipients.find(
      (recipientRoleType) => recipientRoleType === recipientValue
    );
  };

  const handleRoleRecipientClick = (selectedRole) => {
    const newRoleRecipientsArray = getNewArray({
      selection: selectedRole,
      array: roleRecipients,
    });
    setRoleRecipients(newRoleRecipientsArray);
  };

  const handleRemoveEmailRecipient = (emailRecipientToRemove) => {
    let emailRecipientsCopy = emailRecipients;
    setEmailRecipients(
      emailRecipientsCopy.filter(
        (emailRecipient) => emailRecipient !== emailRecipientToRemove
      )
    );
  };

  const handleAddNewEmailRecipient = () => {
    setEmailRecipients([...emailRecipients, newEmailRecipientText]);
    setNewEmailRecipientText("");
  };

  const handleEmailRecipientTextChange = (e) => {
    setNewEmailRecipientText(e.target.value);
  };

  const renderRecipientsSection = () => (
    <div>
      <div>
        <p className={rootClasses.sectionSubtitle}>
          Send to Blumira user roles
        </p>
        <div className={rootClasses.checkBoxOuterContainer}>
          {recipientCheckBoxList.map(({ label, value }) => (
            <div className={rootClasses.checkboxContainer} key={value}>
              <FormControlLabel
                value={value}
                label={label}
                control={
                  <Checkbox
                    color={"primary"}
                    disabled={shouldOrgBeRestricted()}
                    checked={isRoleRecipientChecked(value)}
                    onChange={() => handleRoleRecipientClick(value)}
                  />
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div>
        <p className={rootClasses.sectionSubtitle}>
          Send to specific email addresses
        </p>
        <div className={rootClasses.textFieldOuterContainer}>
          {emailRecipients.map((emailRecipient, idx) => (
            <div
              key={`${emailRecipient}-${idx}`}
              className={rootClasses.textFieldContainer}
            >
              <TextField
                disabled
                className={rootClasses.textField}
                value={emailRecipient}
              />
              <IconButton
                disabled={shouldOrgBeRestricted()}
                className={rootClasses.textFieldRowIcon}
                onClick={() => handleRemoveEmailRecipient(emailRecipient)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <div className={rootClasses.textFieldContainer}>
            <TextField
              disabled={shouldOrgBeRestricted()}
              value={newEmailRecipientText}
              className={rootClasses.textField}
              onChange={handleEmailRecipientTextChange}
              placeholder={"Click here to add email address"}
            />
            <IconButton
              className={rootClasses.textFieldRowIcon}
              onClick={handleAddNewEmailRecipient}
              disabled={
                shouldOrgBeRestricted() || isAddEmailRecipientButtonDisabled
              }
            >
              <AddCircleIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={rootClasses.sectionButtonContainer}>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={handleSaveChanges}
          disabled={isSaveDisabled()}
        >
          {isSaving ? "Saving changes..." : "Save changes"}
        </Button>
        <Button
          variant={"text"}
          color={"primary"}
          onClick={handleResetChanges}
          disabled={shouldOrgBeRestricted() || !hasOrgConfigChanged || isSaving}
        >
          Reset changes
        </Button>
      </div>
    </div>
  );

  const renderPDFCell = (column, model) => {
    if (!model[column.field]) return "";

    return (
      <a
        href={model[column.field]}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        Download
      </a>
    );
  };

  const renderPreviousSummariesSection = () => (
    <div className={rootClasses.previousSummarySectionWrapper}>
      <SimpleTable
        isSearchHidden
        data={previousSummaries}
        initialOrderBy={["created", "desc"]}
        columns={[
          {
            field: "name",
            title: "Name",
          },
          {
            field: "created",
            title: "Created",
            renderValue: renderDateCell,
          },
          {
            field: "signedUrl",
            title: "Download PDF",
            renderValue: renderPDFCell,
          },
        ]}
      />
    </div>
  );

  const isContentSectionChecked = (sectionValue) => {
    return !!contentSections.find(
      (contentSection) => contentSection === sectionValue
    );
  };

  const handleContentSectionClick = (selectedContentSection) => {
    const newContentSectionsArray = getNewArray({
      selection: selectedContentSection,
      array: contentSections,
    });
    setContentSections(newContentSectionsArray);
  };

  const isSaveDisabled = () => {
    return (
      shouldOrgBeRestricted() ||
      !hasOrgConfigChanged ||
      (isFooterLogoCustom && !isValidURL(logoUrl)) ||
      isSaving
    );
  };

  const handleFooterLogoRadio = () => {
    // if user was using a custom logo and switches to default
    if (isFooterLogoCustom) setLogoUrl("");

    setIsFooterLogoCustom(!isFooterLogoCustom);
  };

  const handleLogoUrlChange = (e) => {
    setLogoUrl(e.target.value);
  };

  const handleSummaryPageRadio = () => {
    // if user was using custom text and switches to default
    if (isSummaryPageCustom) setCustomText("");
    setIsSummaryPageCustom(!isSummaryPageCustom);
  };

  const handleCustomTextChange = (e) => {
    setCustomText(e.target.value);
  };

  const renderContentSettings = () => (
    <div>
      <div className={rootClasses.contentSettingsCheckboxContainer}>
        {contentSectionCheckBoxList.map(({ label, value }) => (
          <div className={rootClasses.checkboxContainer} key={value}>
            <FormControlLabel
              value={value}
              label={label}
              control={
                <Checkbox
                  color={"primary"}
                  disabled={shouldOrgBeRestricted() || value === 1}
                  checked={isContentSectionChecked(value)}
                  onChange={() => handleContentSectionClick(value)}
                />
              }
            />
          </div>
        ))}
      </div>
      <div className={rootClasses.radioGroupOuterContainer}>
        <p className={rootClasses.sectionSubtitle}>Footer logo</p>
        <RadioGroup>
          <FormControlLabel
            label={<img style={{ height: "20px" }} src={logo} alt="Logo" />}
            className={rootClasses.formControlLabel}
            onChange={handleFooterLogoRadio}
            control={
              <Radio
                disabled={shouldOrgBeRestricted()}
                checked={!isFooterLogoCustom}
              />
            }
          />
          <FormControlLabel
            label={"Custom"}
            onChange={handleFooterLogoRadio}
            className={rootClasses.formControlLabel}
            control={
              <Radio
                disabled={shouldOrgBeRestricted()}
                checked={isFooterLogoCustom}
              />
            }
          />
        </RadioGroup>
        {isFooterLogoCustom && (
          <TextField
            value={logoUrl}
            disabled={shouldOrgBeRestricted()}
            className={rootClasses.logoUrlTextField}
            onChange={handleLogoUrlChange}
            placeholder={"Enter a public url for your logo"}
            helperText={
              isValidURL(logoUrl) ? "" : "Please ensure you use a valid url"
            }
          />
        )}
      </div>
      <div className={rootClasses.radioGroupOuterContainer}>
        <p className={rootClasses.sectionSubtitle}>Summary page description</p>
        <RadioGroup>
          <FormControlLabel
            onChange={handleSummaryPageRadio}
            className={rootClasses.formControlLabel}
            label={"Default"}
            control={
              <Radio
                disabled={shouldOrgBeRestricted()}
                checked={!isSummaryPageCustom}
              />
            }
          />
          <p className={rootClasses.radioHelperText}>
            "Blumira helps prevent ransomware and data breaches, saving you
            money and time while reducing your overall risk. Blumira's security
            platform analyzes your data, detecting and alerting you to potential
            threats faster."
          </p>
          <FormControlLabel
            onChange={handleSummaryPageRadio}
            className={rootClasses.formControlLabel}
            label={"Custom"}
            control={
              <Radio
                disabled={shouldOrgBeRestricted()}
                checked={isSummaryPageCustom}
              />
            }
          />
        </RadioGroup>
        {isSummaryPageCustom && (
          <TextField
            value={customText}
            disabled={shouldOrgBeRestricted()}
            onChange={handleCustomTextChange}
            className={rootClasses.logoUrlTextField}
            placeholder={"Enter your summary page description"}
          />
        )}
      </div>
      <div className={rootClasses.sectionButtonContainer}>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={handleSaveChanges}
          disabled={isSaveDisabled()}
        >
          {isSaving ? "Saving changes..." : "Save changes"}
        </Button>
        <Button
          variant={"text"}
          color={"primary"}
          onClick={handleResetChanges}
          disabled={shouldOrgBeRestricted() || !hasOrgConfigChanged || isSaving}
        >
          Reset changes
        </Button>
      </div>
    </div>
  );

  return isLoading ? (
    <Root>
      <div className={rootClasses.loadingContainer}>
        <CircularProgress color={"primary"} />
        <p>Loading your organization details...</p>
      </div>
    </Root>
  ) : (
    <Root>
      <div className={rootClasses.cardContainer}>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {shouldOrgBeRestricted() && (
          <Alert
            severity={"warning"}
            icon={false}
            datacy={"executiveSummaryUpsellBanner"}
          >
            <div className={rootClasses.alertWarningContainer}>
              <p className={rootClasses.alertTitle}>
                Your Blumira Edition includes limited Executive Summaries
                features
              </p>
              {props.license.isTagged("limited-to-siem-pro") ? null : (
                <div>
                  <p className={rootClasses.boldText}>
                    {" "}
                    <CheckIcon /> Quarterly reports
                  </p>
                  <p>
                    <CloseIcon /> Monthly reports (not included)
                  </p>
                  <p>
                    <CloseIcon /> Content settings configuration (not included)
                  </p>
                </div>
              )}
              <p>Contact sales to learn how to try out this feature today.</p>
              <a
                target={"_blank"}
                rel={"noopener noreferrer"}
                href={contactSalesUrl}
              >
                <Button color={"primary"} variant={"contained"}>
                  Contact sales
                </Button>
              </a>
            </div>
          </Alert>
        )}
        {!shouldHideAnnouncementBanner && (
          <Card
            className={rootClasses.card}
            datacy={"executiveSummaryAnnouncementBanner"}
          >
            <Accordion
              className={rootClasses.announcementAccordion}
              defaultExpanded={true}
            >
              <AccordionSummary
                className={rootClasses.accordionSummary}
                expandIcon={<KeyboardArrowDownIcon />}
              >
                <p className={rootClasses.accordionTitle}>
                  Introducing Executive Summaries
                </p>
                {!shouldOrgBeRestricted() && (
                  <Button
                    variant={"text"}
                    color={"primary"}
                    onClick={handleHideAnnouncementBanner}
                  >
                    Hide
                  </Button>
                )}
              </AccordionSummary>
              <AccordionDetails className={rootClasses.accordionPanel}>
                {renderIntroductionSection()}
              </AccordionDetails>
            </Accordion>
          </Card>
        )}
        <Card className={rootClasses.card} datacy={"recipientsAccordion"}>
          <Accordion className={rootClasses.accordion}>
            <AccordionSummary
              className={rootClasses.accordionSummary}
              expandIcon={<KeyboardArrowDownIcon />}
            >
              <p className={rootClasses.accordionTitle}>Recipients</p>
            </AccordionSummary>
            <AccordionDetails className={rootClasses.accordionPanel}>
              {renderRecipientsSection()}
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card
          className={rootClasses.card}
          datacy={"previousSummariesAccordion"}
        >
          <Accordion className={rootClasses.accordion}>
            <AccordionSummary
              className={rootClasses.accordionSummary}
              expandIcon={<KeyboardArrowDownIcon />}
            >
              <p className={rootClasses.accordionTitle}>
                Previous Summaries ({previousSummaries.length})
              </p>
            </AccordionSummary>
            <AccordionDetails className={rootClasses.accordionPanel}>
              {renderPreviousSummariesSection()}
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card className={rootClasses.card} datacy={"contentSettingsAccordion"}>
          <Accordion className={rootClasses.accordion}>
            <AccordionSummary
              className={rootClasses.accordionSummary}
              expandIcon={<KeyboardArrowDownIcon />}
            >
              <p className={rootClasses.accordionTitle}>Content settings</p>
            </AccordionSummary>
            <AccordionDetails className={rootClasses.accordionPanel}>
              {renderContentSettings()}
            </AccordionDetails>
          </Accordion>
        </Card>
        <SnapshotDialog
          open={isSnapshotDialogOpen}
          onClose={toggleSnapshotDialog}
        />
      </div>
    </Root>
  );
};

const mapStateToProps = (state) => {
  const currentOrgId = state.location.payload.orgId;

  const currentOrgJSON = state.session.settings.userOrgs.find(
    ({ id }) => id === currentOrgId
  );

  const isOrgAChildOrg = isOrganizationChildOfNFR(currentOrgJSON);
  const isOrgAParentOrg =
    currentOrgJSON &&
    currentOrgJSON.market === 20 &&
    isNil(currentOrgJSON.parentId);

  return {
    currentOrgId,
    license: state.license,
    isOrgAChildOrg,
    isOrgAParentOrg,
    currentUser: state?.session?.settings?.user,
  };
};

export default connect(mapStateToProps, null)(ExecutiveSummariesPageView);
