import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import {
  Grid,
  Card,
  List,
  Alert,
  Button,
  Popover,
  Divider,
  Checkbox,
  ListItem,
  TextField,
  Accordion,
  Typography,
  Pagination,
  CardActions,
  CardContent,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  ListItemSecondaryAction,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import moment from "moment-timezone";
import Spark from "./charts/spark";
import SimpleTable from "views/Components/SimpleTable";

import { abbreviateNumber, navToInvestigation } from "./helpers";
import { entityFields, entityTypes } from "./defs";

const PREFIX = "SimpleSearchStyles";
const fieldControlClasses = {
  button: `${PREFIX}-button`,
  withFilter: `${PREFIX}-withFilter`,
  primaryText: `${PREFIX}-primaryText`,
  secondaryText: `${PREFIX}-secondaryText`,
  tertiaryText: `${PREFIX}-tertiaryText`,
  checkIcon: `${PREFIX}-checkIcon`,
  fieldControlContainer: `${PREFIX}-fieldControlContainer`,
};

const overviewClasses = {
  italic: `${PREFIX}-italic`,
};

const popoverClasses = {
  listRoot: `${PREFIX}-listRoot`,
  listItemSecondaryAction: `${PREFIX}-listItemSecondaryAction`,
  listItem: `${PREFIX}-listItem`,
  searchContainer: `${PREFIX}-searchContainer`,
  closeIcon: `${PREFIX}-closeIcon`,
  textField: `${PREFIX}-textField`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${fieldControlClasses.button}`]: {
    margin: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  [`& .${fieldControlClasses.withFilter}`]: {
    backgroundColor: theme.palette.background.alert,
  },
  [`& .${fieldControlClasses.primaryText}`]: {
    fontWeight: "bold",
    marginLeft: 5,
    maxWidth: 100,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  [`& .${fieldControlClasses.secondaryText}`]: {
    fontWeight: "bold",
    marginLeft: 5,
  },
  [`& .${fieldControlClasses.tertiaryText}`]: {
    fontWeight: "bold",
    marginLeft: 5,
  },
  [`& .${fieldControlClasses.fieldControlContainer}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  [`& .${fieldControlClasses.checkIcon}`]: {
    paddingRight: 10,
  },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`& .${overviewClasses.italic}`]: {
    fontStyle: "italic",
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.listRoot}`]: {
    minWidth: "450px",
  },
  [`& .${popoverClasses.listItemSecondaryAction}`]: {
    visibility: "hidden",
    position: "unset",
    transform: "none",
    minWidth: "120px",
  },
  [`& .${popoverClasses.listItem}`]: {
    visibility: "inherit",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover .SimpleSearchStyles-listItemSecondaryAction": {
      visibility: "visible",
    },
  },
  [`& .${popoverClasses.searchContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 15px 10px 15px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${popoverClasses.closeIcon}`]: {
    marginLeft: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`& .${popoverClasses.textField}`]: {
    height: 10,
    width: 250,
  },
}));

const FieldControl = ({ column, data, callback }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortedIndex, setSortedIndex] = useState([]);
  const [values, setValues] = useState({});
  const [filterState, setFilterState] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;

  useEffect(() => {
    readData();
  }, [data, searchTerm]);

  useEffect(() => {
    const checked = Object.values(values).filter((o) => o.checked);
    /***
     * -1 : all selected
     *  0 : none selected
     *  1+: more than one, but not all selected
     */
    var state = -1;
    if (sortedIndex.length > checked.length) {
      if (checked.length === 0) {
        state = 0;
      } else {
        state = checked.length;
      }
    }
    setFilterState(state);
  }, [values]);

  const readData = () => {
    const _uniqueSorted = Object.keys(data).map((k) => {
      return { value: k, count: data[k].count };
    });
    _uniqueSorted.sort((a, b) => (a.count > b.count ? -1 : 1));

    // Sort the complete data set first
    const sortedData = _uniqueSorted.map((o) => o.value);

    // Then apply the search filter
    const filteredItems = sortedData.filter((item) => {
      return item.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setSortedIndex(filteredItems);
    setValues(data);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    readData();
    handleClose();
  };

  const handleApply = () => {
    callback(values);
    handleClose();
  };

  const handleToggle = (item, setOnly = false) => {
    if (setOnly) {
      Object.values(values).forEach((v) => (v.checked = false));
      values[item.toLowerCase()].checked = true;
    } else {
      values[item.toLowerCase()].checked = !values[item].checked;
    }
    setValues({ ...values });
  };

  const handleToggleAll = () => {
    const uncheck = filterState != 0;
    Object.values(values).forEach((v) => (v.checked = !uncheck));
    setValues({ ...values });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getAdditionalButtonLabelText = () => {
    let result = { primary: "", secondary: "", tertiary: "" };
    let checkedCount = 0;
    let additionalCheckedCount = 0;

    for (const key in values) {
      const entry = values[key];
      if (entry.checked) {
        if (checkedCount === 0) {
          result.primary = ` ${entry.value}`;
          result.secondary = ` (${entry.count})`;
        } else {
          additionalCheckedCount++;
        }
        checkedCount++;
      }
    }

    if (additionalCheckedCount > 0) {
      result.tertiary = ` +${additionalCheckedCount}`;
    }

    return result;
  };

  const open = Boolean(anchorEl);
  const id = open ? "field-popover" : undefined;
  return (
    <Root sx={{ display: "inline-flex" }}>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        className={`${fieldControlClasses.button} ${
          filterState > 0 ? fieldControlClasses.withFilter : ""
        }`}
        variant="outlined"
        endIcon={<ArrowDropDownIcon />}
      >
        {filterState > 0 && (
          <CheckIcon className={fieldControlClasses.checkIcon} />
        )}
        {column}
        {filterState > 0 && (
          <>
            <span className={fieldControlClasses.primaryText}>
              {getAdditionalButtonLabelText().primary}
            </span>
            <span className={fieldControlClasses.secondaryText}>
              {getAdditionalButtonLabelText().secondary}
            </span>
            <span className={fieldControlClasses.tertiaryText}>
              {getAdditionalButtonLabelText().tertiary}
            </span>
          </>
        )}
      </Button>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card>
          <div className={popoverClasses.searchContainer}>
            <TextField
              fullWidth
              margin={"normal"}
              value={searchTerm}
              variant={"outlined"}
              placeholder={"Search"}
              onChange={handleSearchChange}
              InputProps={{
                classes: {
                  input: popoverClasses.textField,
                },
              }}
            />
            <CloseIcon
              onClick={() => setSearchTerm("")}
              className={popoverClasses.closeIcon}
            />
          </div>
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={filterState < 0}
                  onChange={handleToggleAll}
                  name="checkedF"
                  indeterminate={filterState > 0}
                />
              }
              label={`Select ${column}`}
            />
            <List className={popoverClasses.listRoot}>
              {sortedIndex
                .slice(
                  itemsPerPage * currentPage - itemsPerPage,
                  itemsPerPage * currentPage
                )
                .map((key) => (
                  <ListItem
                    key={key}
                    role={undefined}
                    dense
                    button
                    onClick={() => handleToggle(key)}
                    classes={{
                      container: popoverClasses.listItem,
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={values[key].checked}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": `checkbox-list-label-${key}`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={`checkbox-list-label-${key}`}
                      primary={`${values[key].value} (${values[key].count})`}
                    />
                    <ListItemSecondaryAction
                      className={popoverClasses.listItemSecondaryAction}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => handleToggle(key, true)}
                      >
                        Exclude others
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={handleApply}>
              Apply
            </Button>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Pagination
              count={Math.ceil(sortedIndex.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </CardActions>
        </Card>
      </StyledPopover>
    </Root>
  );
};

const OverviewContolBar = ({ fieldData, setOverviewContext }) => {
  const columns = Object.keys(fieldData);
  const callback = (fieldName, data) => {
    fieldData[fieldName] = data;
    setOverviewContext({ ...fieldData });
  };

  return (
    <Root>
      <div className={fieldControlClasses.fieldControlContainer}>
        {Object.keys(fieldData)
          .filter((fieldName) => columns.includes(fieldName))
          .map((fieldName) => (
            <FieldControl
              key={fieldName}
              column={fieldName}
              data={{ ...fieldData[fieldName] }}
              callback={(data) => {
                callback(fieldName, data);
              }}
            />
          ))}
      </div>
    </Root>
  );
};

export const LegacyOverview = ({
  entity,
  entityEvents,
  originData,
  searchValue,
  autoSelectedFields,
  orgId,
  children,
  searchParams,
}) => {
  const [sparkData, setSparkData] = useState([{ name: entity.name, data: [] }]);
  const [filteredData, setFilteredData] = useState([]);
  const [eventCount, setEventCount] = useState(0);
  const [uniqueValues, setUniqueValues] = useState({});
  const [firstPoint, setFirstPoint] = useState(false);
  const [lastPoint, setLastPoint] = useState(false);
  const [logTypes, setLogTypes] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [reportLink, setReportLink] = useState({});
  const [useAutoSelect, setUseAutoSelect] = useState(true);

  const defaultSelectValue = true;
  const isSelected = (key, value) =>
    autoSelectedFields.includes(key)
      ? String(value) === String(searchValue).toLowerCase()
      : defaultSelectValue;

  useEffect(() => {
    const populatedFields = filteredData
      .map((o) => Object.keys(o))
      .reduce((a, v) => {
        v.forEach((f) => a.add(f));
        return a;
      }, new Set([]));
    const defaultFields = Object.keys(entityFields)
      .filter((k) => populatedFields.has(k))
      .map((k) => {
        return { title: k, field: k, searchable: false };
      });

    setTableColumns([
      { title: "Total", field: "sum_total", searchable: false },
      { title: "Association", field: "associationType", searchable: false },
      ...defaultFields,
      { title: "First Event", field: "fromTime", searchable: false },
      { title: "Last Event", field: "toTime", searchable: false },
    ]);
  }, [entityEvents, filteredData]);

  useEffect(() => {
    const cData = {
      name: entity.name,
      data: [],
      color: entity.color,
    };
    const dt = {};

    entityTypes.forEach((eT) => {
      if (eT.name === entity.name) {
        eT.queryFields.forEach((field) => {
          entityEvents.forEach((o) => {
            // this code is an attempt at smoothing the data until we can get histogram support in the FEA API
            const month = o.month < 10 ? `0${o.month}` : o.month;
            const day = o.day < 10 ? `0${o.day}` : o.day;
            const hour = o.hour < 10 ? `0${o.hour}` : o.hour;
            const dateString = `${o.year}-${month}-${day}T${hour}:00:00`;
            const startUTS = new moment(dateString).startOf("hour");
            const endUTS = new moment(dateString).endOf("hour");
            const hoursDelta = endUTS.diff(startUTS, "hours") || 1;

            if (hoursDelta < 24 * 60) {
              const total = o[`sum_${field.fieldName}`] || 0;

              Array(hoursDelta)
                .fill(Number(total / hoursDelta))
                .forEach((v) => {
                  const uts = startUTS.unix() * 1000;
                  if (dt[uts] === undefined) {
                    dt[uts] = 0;
                  }
                  dt[uts] += v;
                  startUTS.add(1, "hours");
                });
            }
          });
        });
      }
    });

    cData.data = Object.keys(dt).map((t) => [Number(t), dt[t]]);
    cData.data.sort((a, b) => a[0] - b[0]);
    setSparkData([cData]);
    setEventCount(cData.data.reduce((_sum, obj) => _sum + obj[1], 0));

    setFirstPoint(cData.data.find((a) => a[1] > 0));
    setLastPoint(cData.data.findLast((a) => a[1] > 0));
  }, [entityEvents]);

  useEffect(() => {
    const _uniqueValues = {};

    Object.keys(originData).forEach((fieldName) => {
      _uniqueValues[fieldName] = {};
      Object.keys(originData[fieldName]).forEach((uniqueValue) => {
        _uniqueValues[fieldName][uniqueValue] = {
          ...originData[fieldName][uniqueValue],
          checked: isSelected(fieldName, uniqueValue),
        };

        if (uniqueValues[fieldName] && uniqueValues[fieldName][uniqueValue]) {
          _uniqueValues[fieldName][uniqueValue].checked =
            uniqueValues[fieldName][uniqueValue].checked || true;
        }
      });
    });
    setUniqueValues(_uniqueValues);
  }, [originData]);

  useEffect(() => {
    const _logTypes = new Set([]);
    const entityMatches = [];

    const filteredFieldNames = Object.keys(uniqueValues).filter(
      (fieldName) =>
        Object.keys(uniqueValues[fieldName]).filter(
          (value) => uniqueValues[fieldName][value].checked == false
        ).length > 0
    );

    if (Object.keys(uniqueValues).length > 0) {
      const _filteredData = [];
      var filtered;
      const dataIsFiltered = filteredFieldNames.length > 0;

      entityEvents.forEach((row) => {
        if (entity.matchFields.find((e) => row[e.fieldName] !== undefined)) {
          entityMatches.push(row);
          // only count the logtype if its a matched entity
          _logTypes.add(row["type"]);
        }

        filtered = false;

        // if filteredFieldNames.length is 0, bypass all kilter logic
        if (dataIsFiltered) {
          const rowFilterNames = filteredFieldNames.filter(
            (v) => row[v] !== undefined
          );
          // if we have a filter in place (dataIsFiltered) but none of the filter fields
          // are applicable to this model, then the row should be filtered out.
          if (rowFilterNames.length === 0) {
            filtered = true;
          } else {
            // if the row has a field that is on the rowFilterNames list
            // that row must match ALL of the rowFilterNames, or be filtered
            filtered =
              rowFilterNames.length !==
              rowFilterNames.filter(
                (fieldName) =>
                  Object.values(uniqueValues[fieldName])
                    .filter((uVal) => uVal.checked)
                    .filter((uVal) => row[fieldName] === uVal.value).length > 0
              ).length;
          }
        }
        if (!filtered) {
          _filteredData.push(row);
        }
      });
      setFilteredData(_filteredData);
    }
    setLogTypes([..._logTypes]);
  }, [uniqueValues]);

  const getDaysAgo = (dt) => {
    const _now = new moment();
    return _now.diff(moment(dt), "d");
  };

  const handleFilterOnly = ({ model, fieldName }) => {
    Object.keys(uniqueValues[fieldName]).forEach((v) => {
      uniqueValues[fieldName][v].checked =
        uniqueValues[fieldName][v].value === model[fieldName];
    });
    setUniqueValues({ ...uniqueValues });
  };

  const handleFilterOut = ({ model, fieldName }) => {
    uniqueValues[fieldName][model[fieldName]].checked = false;
    setUniqueValues({ ...uniqueValues });
  };

  const jumpToReportBuilder = ({ model, fieldName }) => {
    // use the timestamps from the search params
    // to match the timespan of the presented data
    const startTime = moment
      .unix(searchParams.startTime / 1000)
      .format("YYYY-MM-DD HH:mm:ss.SSSSSSZ");
    const endTime = moment
      .unix(searchParams.endTime / 1000)
      .format("YYYY-MM-DD HH:mm:ss.SSSSSSZ");

    const query = {
      "created.gt": startTime,
      "created.lt": endTime,
      "type.eq": model.type,
    };

    const bqQuery = model.toReportFields([fieldName]);
    if (bqQuery.length > 0 && bqQuery[0].length > 0) {
      bqQuery.forEach((fieldQuery) => {
        var queryField = undefined;
        var queryString = "";
        // for each matching bq field
        fieldQuery.forEach((fieldMatch) => {
          if (queryField === undefined) {
            queryField = `${fieldMatch.field}.eq`;
            queryString = fieldMatch.value;
          } else {
            queryString = `${queryString}|${fieldMatch.field}.eq=${fieldMatch.value}`;
          }
        });
        query[queryField] = queryString;
      });
    }

    setReportLink({
      type: "PAGE",
      payload: {
        orgId,
        toplevel: "reporting",
        secondlevel: "builder",
        query,
      },
    });
  };

  return (
    <StyledAccordion disabled={!firstPoint}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper": {
            order: -1,
            marginRight: "15px !important",
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={2}>
            <Typography variant="h6">{entity.name}</Typography>
          </Grid>
          {firstPoint ? (
            <>
              <Grid item xs={2}>
                <Typography variant="caption" display="block" gutterBottom>
                  Events
                </Typography>
                <Typography variant="h2" gutterBottom>
                  {abbreviateNumber(eventCount)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  {firstPoint &&
                    moment(lastPoint[0]).diff(moment(firstPoint[0]), "d")}{" "}
                  days
                </Typography>
                <Spark data={sparkData} />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" display="block" gutterBottom>
                  Most Recent
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {lastPoint && getDaysAgo(lastPoint[0])} days ago
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className={overviewClasses.italic}
                >
                  {lastPoint &&
                    moment(lastPoint[0]).format("MMM D, YYYY h:mm A")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" display="block" gutterBottom>
                  Oldest
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {firstPoint && getDaysAgo(firstPoint[0])} days ago
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className={overviewClasses.italic}
                >
                  {firstPoint &&
                    moment(firstPoint[0]).format("MMM D, YYYY h:mm A")}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="caption" display="block" gutterBottom>
                  Data Sources
                </Typography>
                <Typography variant="h2" gutterBottom>
                  {logTypes.length}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={10}>
              <Typography>
                No data available for selected time window
              </Typography>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {useAutoSelect && autoSelectedFields.length > 0 && (
          <Alert
            severity="info"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => setUseAutoSelect(false)}
              >
                Close
              </Button>
            }
          >
            Filters for {autoSelectedFields} were automatically added based on
            your search for "{searchParams.searchValue}" in order to focus the
            results on the most relevant matches. To see all results, you can
            clear these filters.
          </Alert>
        )}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <OverviewContolBar
              fieldData={uniqueValues}
              setOverviewContext={setUniqueValues}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SimpleTable
              isSearchHidden={true}
              data={filteredData}
              columns={tableColumns}
              contextMenuItems={[
                {
                  onClick: ({ model, fieldName }) =>
                    navToInvestigation(orgId, { search: model[fieldName] }),
                  limitToFields: Object.keys(entityFields),
                  text: "Investigate in new window",
                },
                {
                  onClick: jumpToReportBuilder,
                  link: reportLink,
                  text: "View Logs",
                },
                {
                  onClick: handleFilterOnly,
                  limitToFields: Object.keys(entityFields),
                  text: "Include",
                },
                {
                  onClick: handleFilterOut,
                  limitToFields: Object.keys(entityFields),
                  text: "Exclude",
                },
              ]}
            />
          </Grid>
          <>{children}</>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions></AccordionActions>
    </StyledAccordion>
  );
};
