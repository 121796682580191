import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "DevicesPageView";
export const rootClasses = {
  alertTitle: `${PREFIX}-alertTitle`,
  leftMarginLink: `${PREFIX}-leftMarginLink`,
  linkContainer: `${PREFIX}-linkContainer`,
  secondaryLink: `${PREFIX}-secondaryLink`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  quickFilterContainer: `${PREFIX}-quickFilterContainer`,
  quickFilterLabel: `${PREFIX}-quickFilterLabel`,
  quickFilterButton: `${PREFIX}-quickFilterButton`,
  activateButton: `${PREFIX}-activateButton`,
  unorderedList: `${PREFIX}-unorderedList`,
  errorBanner: `${PREFIX}-errorBanner`,
  errorAction: `${PREFIX}-errorAction`,
};

export const dialogClasses = {
  errorTextButton: `${PREFIX}-errorTextButton`,
  errorContainedButton: `${PREFIX}-errorContainedButton`,
  deviceDetailOuterContainer: `${PREFIX}-deviceDetailOuterContainer`,
  deviceDetailInnerContainer: `${PREFIX}-deviceDetailInnerContainer`,
  displayNameText: `${PREFIX}-displayNameText`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  close: `${PREFIX}-close`,
  dialogActions: `${PREFIX}-dialogActions`,
  formControlLabel: `${PREFIX}-formControlLabel`,
  warning: `${PREFIX}-warning`,
  ahiCheckboxLabel: `${PREFIX}-ahiCheckboxLabel`,
  ahiCheckboxLegend: `${PREFIX}-ahiCheckboxLegend`,
  ahiCheckboxWrap: `${PREFIX}-ahiCheckboxWrap`,
  tabs: `${PREFIX}-tabs`,
  loadingDialogContainer: `${PREFIX}-loadingDialogContainer`,
  platformTypeModalWrap: `${PREFIX}-platformTypeModalWrap`,
  platformTypeIconWrap: `${PREFIX}-platformTypeIconWrap`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${rootClasses.alertTitle}`]: {
    fontWeight: "bold",
  },
  [`& .${rootClasses.leftMarginLink}`]: {
    marginLeft: 10,
  },
  [`& .${rootClasses.linkContainer}`]: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.shape.padding,
  },
  [`& .${rootClasses.secondaryLink}`]: {
    paddingLeft: theme.shape.padding,
  },
  [`& .${rootClasses.sectionTitle}`]: {
    margin: 0,
    fontWeight: "bold",
  },
  [`&.${rootClasses.quickFilterContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${rootClasses.quickFilterLabel}`]: {
    marginRight: 20,
  },
  [`& .${rootClasses.quickFilterButton}`]: {
    textTransform: "none",
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.action.toggleButtonHover,
    },
  },
  [`& .${rootClasses.activateButton}`]: {
    width: 250,
    marginRight: theme.shape.padding,
  },
  [`& .${rootClasses.unorderedList}`]: {
    paddingLeft: theme.shape.padding,
    "& li": {
      paddingBottom: theme.shape.padding,
    },
  },
  [`& .${rootClasses.errorBanner}`]: {
    marginBottom: theme.shape.padding,
    "& .MuiAlert-icon": {
      alignItems: "center",
    },
  },
  [`& .${rootClasses.errorAction}`]: {
    fontWeight: "bold",
    color: theme.palette.error.main,
    marginRight: theme.shape.padding,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.error.main,
    },
  },
}));

export const DevicesDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.errorTextButton}`]: {
    color: theme.palette.error.main,
  },
  [`& .${dialogClasses.errorContainedButton}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
  [`& .${dialogClasses.deviceDetailOuterContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.shape.padding,
    "& p": {
      marginBottom: 0,
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${dialogClasses.deviceDetailInnerContainer}`]: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
  },
  [`& .${dialogClasses.displayNameText}`]: {
    marginBottom: 0,
    fontWeight: "bold",
  },
  [`& .${dialogClasses.dialogTitle}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.shape.padding,
  },
  [`& .${dialogClasses.close}`]: {
    cursor: "pointer",
  },
  [`& .${dialogClasses.dialogActions}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${dialogClasses.formControlLabel}`]: {
    margin: 0,
    "& span": {
      fontSize: 14,
    },
  },
  [`& .${dialogClasses.warning}`]: {
    marginBottom: 0,
    color: theme.palette.error.main,
  },
  [`& .${dialogClasses.ahiCheckboxLabel}`]: {
    "& span": {
      fontSize: 14,
    },
  },
  [`& .${dialogClasses.ahiCheckboxLegend}`]: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: 14,
  },
  [`& .${dialogClasses.ahiCheckboxWrap}`]: {
    marginTop: 20,
  },
  [`& .${dialogClasses.tabs}`]: {
    marginTop: -theme.shape.padding,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& span": {
      textTransform: "none",
    },
  },
  [`& .${dialogClasses.loadingDialogContainer}`]: {
    height: 300,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& p": {
      marginTop: theme.shape.padding,
    },
  },
  [`& .${dialogClasses.platformTypeModalWrap}`]: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: 5,
    },
  },
  [`& .${dialogClasses.platformTypeIconWrap}`]: {
    marginRight: 5,
  },
}));

export const FailedInstallDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.errorTextButton}`]: {
    color: theme.palette.error.main,
  },
  [`& .${dialogClasses.errorContainedButton}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
  [`& .${dialogClasses.deviceDetailOuterContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.shape.padding,
    "& p": {
      marginBottom: 0,
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${dialogClasses.deviceDetailInnerContainer}`]: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
  },
  [`& .${dialogClasses.displayNameText}`]: {
    marginBottom: 0,
    fontWeight: "bold",
  },
  [`& .${dialogClasses.dialogTitle}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.shape.padding,
  },
  [`& .${dialogClasses.close}`]: {
    cursor: "pointer",
  },
  [`& .${dialogClasses.dialogActions}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${dialogClasses.formControlLabel}`]: {
    margin: 0,
    "& span": {
      fontSize: 14,
    },
  },
  [`& .${dialogClasses.warning}`]: {
    marginBottom: 0,
    color: theme.palette.error.main,
  },
  [`& .${dialogClasses.ahiCheckboxLabel}`]: {
    "& span": {
      fontSize: 14,
    },
  },
  [`& .${dialogClasses.ahiCheckboxLegend}`]: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: 14,
  },
  [`& .${dialogClasses.ahiCheckboxWrap}`]: {
    marginTop: 20,
  },
  [`& .${dialogClasses.tabs}`]: {
    marginTop: -theme.shape.padding,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& span": {
      textTransform: "none",
    },
  },
  [`& .${dialogClasses.loadingDialogContainer}`]: {
    height: 300,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& p": {
      marginTop: theme.shape.padding,
    },
  },
  [`& .${dialogClasses.platformTypeModalWrap}`]: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: 5,
    },
  },
  [`& .${dialogClasses.platformTypeIconWrap}`]: {
    marginRight: 5,
  },
}));
