import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import moment from "moment";

import { get } from "lodash";

import {
  Grid,
  Table,
  Paper,
  Button,
  Dialog,
  Toolbar,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableContainer,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import { DateTimePicker } from "@mui/x-date-pickers-pro";

import { getShouldDisplayDataRetentionMessage } from "views/Pages/MSPPortal/utils";

import Request from "lib/api/Request";

import LoadingTable from "../Components/LoadingTable";
import ActionDialog from "views/Components/ActionDialog";
import SimpleModelForm from "views/Components/SimpleModelForm";

import { newLicenseDefs } from "views/Components/MSP/MSPDetailDialog/constants";

import countries from "i18n-iso-countries";

import { DEFAULT_FEATURES } from "utils";

const PREFIX = "OrganizationPageView";

const pageClasses = {
  titleBar: `${PREFIX}-titleBar`,
  toolBar: `${PREFIX}-toolBar`,
  tableLeftHeaderCell: `${PREFIX}-tableLeftHeaderCell`,
  tableDescription: `${PREFIX}-tableDescription`,
};

const dialogClasses = {
  loadingContainer: `${PREFIX}-loadingContainer`,
  alert: `${PREFIX}-alert`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${pageClasses.titleBar}`]: {
    width: "fit-content",
    flex: "1 1 100%",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  [`& .${pageClasses.toolBar}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  [`& .${pageClasses.tableLeftHeaderCell}`]: {
    textAlign: "right",
    fontWeight: 700,
    whiteSpace: "nowrap",
  },
  [`& .${pageClasses.tableDescription}`]: {
    fontSize: 14,
    marginLeft: 5,
    marginBottom: 0,
  },
}));

const OrgDialog = styled(Dialog)(({ theme }) => ({
  [`&.${dialogClasses.loadingContainer}`]: {
    height: 250,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${dialogClasses.alert}`]: {
    margin: `0 24px`,
    "& p": {
      margin: 0,
    },
    alignItems: "center",
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const blumiraEditionsUrl =
  "https://blumira.zendesk.com/hc/en-us/articles/5178628123283-About-the-Blumira-editions";

export const generalOrgFields = ["name", "shortName", "webUrl"];

export const superadminOrgFields = [
  "orgType",
  "market",
  "license",
  "licenseFeatures",
  "notes",
  "userCount",
];

export const childAccountFields = ["parentId"];

export const blumiraAgentSuperadminFields = ["agentSeatCount"];

export const dataRetentionSuperadminFields = ["dataTableExpireDays"];

export const mspBillingSuperadminFields = [
  "maxioCustomerId",
  "stripeCustomerId",
];

export const getOrgFieldsForMapping = ({
  isParentOrg,
  isSuperadmin,
  isMarketTypeMSP,
  isBlumiraAgentEnabled,
  currentLicenseSelection,
  isChangeManagementPolicyTrue,
  selectedOrgModel,
}) => {
  let fieldsToReturn = generalOrgFields;

  // if this is a super admin and the org is
  // not restricted there are additional
  // fields to display as well as the potential to
  // display a parent id field if the current org is
  // not a parent organization
  if (isSuperadmin && !isChangeManagementPolicyTrue) {
    fieldsToReturn = fieldsToReturn.concat(superadminOrgFields);

    // if this is a super admin and the org being edited
    // has Blumira Agent enabled, display the seat count
    if (isBlumiraAgentEnabled)
      fieldsToReturn = fieldsToReturn.concat(blumiraAgentSuperadminFields);

    // if this is a super admin and they are viewing
    // an organization that *could* be a child account
    if (!isParentOrg && isMarketTypeMSP)
      fieldsToReturn = fieldsToReturn.concat(childAccountFields);

    // if this is a super admin user and the selected license is SIEM Pro, SIEM Endpoint, Advanced, or XDR
    if (
      currentLicenseSelection === "SIEM_PRO" ||
      currentLicenseSelection === "SIEM_ENDPOINT" ||
      currentLicenseSelection === "XDR" ||
      currentLicenseSelection === "ADVANCED"
    )
      fieldsToReturn = fieldsToReturn.concat(dataRetentionSuperadminFields);

    // if this is a super admin and they are viewing an MSP parent org show the maxio and stripe customer id fields
    if (isSuperadmin && isMarketTypeMSP && !selectedOrgModel?.parentId)
      fieldsToReturn = fieldsToReturn.concat(mspBillingSuperadminFields);
  }

  return fieldsToReturn;
};

export const ORG_TYPES = [
  { value: "normal", label: "Paying Customer" },
  { value: "poc", label: "Customer Trial" },
  { value: "demo", label: "Internal Blumira Demo Account" },
  { value: "internal", label: "Internal" },
];

export const MARKETS = [
  { value: 0, label: "Direct" },
  { value: 10, label: "MSSP" },
  { value: 20, label: "MSP" },
  { value: 30, label: "Self-Service" },
  { value: 40, label: "Reseller" },
  { value: 50, label: "Referral" },
];

export const LICENSE_TYPES = [
  { value: "FREE", label: "Free SIEM" },
  { value: "M365", label: "M365" },
  { value: "CLOUD", label: "Cloud" },
  { value: "ADVANCED", label: "Advanced" },
  { value: "SIEM_STARTER", label: "SIEM Starter" },
  { value: "SIEM_STARTER_COMPLIANCE", label: "SIEM Starter + Compliance" },
  { value: "SIEM_PRO", label: "SIEM Pro" },
  { value: "SIEM_ENDPOINT", label: "SIEM + Endpoint Visibility" },
  { value: "XDR", label: "XDR Platform" },
];

export const SCHEDULED_LICENSE_TYPES = [
  { value: "XDR_TRIAL", label: "XDR Platform Trial" },
];

export const LICENSE_FEATURE_TYPES = [
  { value: "LOGSHIPPING", label: "Blumira Agent" },
  { value: "AUTOBOT", label: "Auto Isolation" },
  { value: "HONEYPOT", label: "Honeypot Sensors" },
  { value: "BLOCKLISTS", label: "Dynamic Blocklists" },
  { value: "SIMPLESEARCH", label: "Simple Search" },
  { value: "GCP", label: "GCP Connector Beta" },
  { value: "SOPHOS", label: "Sophos Connector Beta" },
  { value: "CARBONBLACK", label: "Carbon Black Connector Beta" },
  { value: "GCC", label: "GCC High Connector" },
  { value: "SSO", label: "SSO Configuration" },
  { value: "AZUREHUB", label: "Azure Event Hub Connector Beta" },
  { value: "MSPBILLING", label: "Pre-alpha MSP Billing" },
  { value: "MSPBULK", label: "MSP Bulk Actions" },
  { value: "MSCLOUD", label: "MS Defender for Cloud Apps Connector Beta" },
  { value: "MSPPSA", label: "Pre-alpha MSP Integrations" },
];

export const DATA_TABLE_EXPIRE_DAYS = [
  { value: 30, label: "30 days" },
  { value: 90, label: "90 days" },
];

export const EXTENDED_DATA_TABLE_EXPIRE_DAYS = [
  { value: 366, label: "1 year" },
  { value: 732, label: "2 years" },
  { value: 1098, label: "3 years" },
  { value: 1464, label: "4 years" },
  { value: 1830, label: "5 years" },
  { value: 2196, label: "6 years" },
  { value: 2562, label: "7 years" },
];

const getUserTimezone = () => {
  let userTz = null;

  try {
    const payload = JSON.parse(localStorage.getItem("idTokenPayload"));
    userTz = payload["https://b5a.io/geoip"].time_zone;
  } catch (err) {
    userTz = moment.tz.guess();
  }

  return userTz;
};

export const getIsBlumiraAgentEnabled = (model) => {
  // list of features lives on config as well but should
  // be present at 'licenseFeature' from FEA translation
  const licenseFeatures = get(model, ["licenseFeatures"], []);

  // LOGSHIPPING indicates access to blumira agent
  const isBlumiraAgentFeatureFlagPresent = licenseFeatures.find(
    (feature) => feature === "LOGSHIPPING"
  );

  // return bool
  return !!isBlumiraAgentFeatureFlagPresent;
};

export const getIsParentIdModifiedAndValid = (model) => {
  // get array of modified properties
  const modifiedParams = Object.keys(model._original_params);

  // determine if user is editing parentId
  const wasParentIdModified = modifiedParams.some((p) => p === "parentId");

  // if user did not modify parentID
  // no need to continue with logic
  if (!wasParentIdModified) return false;

  // validation error will appear in UI if value isn't
  // UUID but we should not even try to query if the length
  // doesn't match the expected 36 char length of a UUID
  const parentId = get(model, ["parentId"], "");
  if (parentId && parentId.split("").length !== 36) return false;

  // if the parent id was modified and the length
  // of the entered id is enough for a UUID we can
  // try to query for a matching parent organization
  return true;
};

const getLicenseLookup = ({ model = {}, isSuperadmin = false }) => {
  if (model.market === 20 && !isSuperadmin) {
    return LICENSE_TYPES.filter(
      ({ value }) =>
        !newLicenseDefs.find((newLicenseValue) => newLicenseValue === value)
    );
  } else {
    return LICENSE_TYPES;
  }
};

export const EditOrgDialog = (props) => {
  const {
    isSaving,
    openForm,
    userModel,
    handleClose,
    isSuperadmin,
    locationModel,
    timezones = [],
    handleSaveClick,
    selectedOrgModel,
  } = props;

  const [isParent, setIsParent] = useState(false);
  const [parentOrg, setParentOrg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [licenseLookup, setLicenseLookup] = useState([]);
  const [isMarketTypeMSP, setIsMarketTypeMSP] = useState(false);
  const [isBlumiraAgentEnabled, setIsBlumiraAgentEnabled] = useState(false);
  const [licenseFeatureHelperText, setLicenseFeatureHelperText] = useState("");
  const [currentLicenseSelection, setCurrentLicenseSelection] =
    useState("FREE");
  const [
    shouldDataRetentionMessageBeDisplayed,
    setShouldDataRetentionMessageBeDisplayed,
  ] = useState(false);
  const [dataRetentionValueError, setDataRetentionValueError] = useState(null);

  useEffect(() => {
    if (props.error) setErrorMessage(props.error);
    else setErrorMessage(null);
  }, [props.error]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await handleGetChildAndParentOrgs(selectedOrgModel, props.isSuperadmin);
      setIsLoading(false);
    };

    if (selectedOrgModel?.license)
      setCurrentLicenseSelection(selectedOrgModel.license);

    // in order to fetch child orgs we need an id additionaly
    // if a model does not have an id it is a net new org
    if (selectedOrgModel?.id) fetchData();
  }, [selectedOrgModel, props.isSuperadmin]);

  const timezoneOptions = timezones
    .map((tz) => ({ value: tz.name, label: tz.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const findDefaultDataRetentionValue = () => {
    if (currentLicenseSelection === "SIEM_PRO") {
      return 30;
    } else if (
      currentLicenseSelection === "ADVANCED" ||
      currentLicenseSelection === "XDR" ||
      currentLicenseSelection === "SIEM_ENDPOINT"
    ) {
      return 366;
    } else {
      return null;
    }
  };

  // FIELDS
  // if this is not a SA restrict fields allowed
  const organizationFields = !isSuperadmin
    ? {
        name: {
          type: "text",
          default: "",
          label: "Name",
        },
        shortName: {
          xs: 6,
          type: "text",
          default: "",
          label: "Short Name",
        },
        webUrl: {
          xs: 6,
          type: "text",
          default: "",
          label: "Web Domain",
        },
      }
    : {
        name: {
          type: "text",
          default: "",
          label: "Name",
        },
        shortName: {
          xs: 6,
          type: "text",
          default: "",
          label: "Short Name",
        },
        webUrl: {
          xs: 6,
          type: "text",
          default: "",
          label: "Web Domain",
        },
        orgType: {
          xs: 6,
          type: "select",
          lookup: ORG_TYPES,
          default: "poc",
          label: "Account Type",
        },
        market: {
          xs: 6,
          type: "select",
          lookup: MARKETS,
          default: 0,
          label: "Market",
        },
        license: {
          type: "select",
          label: "License",
          lookup: licenseLookup,
        },
        dataTableExpireDays: {
          default: findDefaultDataRetentionValue(),
          type: "select",
          label: "Data retention (days)",
          lookup:
            currentLicenseSelection === "SIEM_PRO"
              ? DATA_TABLE_EXPIRE_DAYS.concat(EXTENDED_DATA_TABLE_EXPIRE_DAYS)
              : EXTENDED_DATA_TABLE_EXPIRE_DAYS,
          helperText: dataRetentionValueError
            ? dataRetentionValueError
            : "Only use in tandem with SIEM Pro, SIEM Endpoint, XDR, or Advanced licenses",
          error: dataRetentionValueError ? true : false,
        },
        licenseFeatures: {
          type: "multiselect",
          lookup: LICENSE_FEATURE_TYPES,
          label: "License Features",
          helperText: licenseFeatureHelperText,
          default: [],
        },
        notes: {
          type: "text",
          default: "",
          label: "Internal Notes",
        },
        // if viewing in the context of administering an org allow
        // for edit otherwise parent id is read only even for SAs
        parentId: {
          type: "text",
          default: null,
          label: "Parent ID",
          readOnly: !props.isAdministeringOrg,
          plainText: !props.isAdministeringOrg,
        },
        agentSeatCount: {
          type: "number",
          label: "Agent Seat Count",
        },
        userCount: {
          type: "number",
          label: "User Count",
        },
        maxioCustomerId: {
          type: "text",
          default: null,
          label: "Maxio Customer ID",
          helperText:
            "DANGER ZONE: DO NOT EDIT UNLESS YOU KNOW WHAT YOU ARE DOING!!!",
        },
        stripeCustomerId: {
          type: "text",
          default: null,
          label: "Stripe Customer ID",
          helperText:
            "DANGER ZONE: DO NOT EDIT UNLESS YOU KNOW WHAT YOU ARE DOING!!!",
        },
      };

  const locationFields = {
    name: {
      xs: 6,
      type: "text",
      default: "Headquarters",
      label: "Name",
    },
    timezone: {
      xs: 6,
      type: "select",
      label: "Timezone",
      lookup: timezoneOptions,
      default: getUserTimezone(),
      helperText: "* Required",
    },
    city: {
      xs: 6,
      type: "text",
      default: "",
      label: "City",
    },
    country: {
      xs: 6,
      type: "select",
      default: "US",
      label: "Country",
      lookup: Object.entries(countries.getNames("en"))
        .map(([key, val]) => ({ value: key, label: val }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  };

  const userFields = {
    firstName: {
      xs: 6,
      type: "text",
      default: "",
      label: "First Name",
    },
    lastName: {
      xs: 6,
      type: "text",
      default: "",
      label: "Last Name",
    },
    email: {
      type: "text",
      default: "",
      label: "Email",
    },
  };

  const getLayout = (modelType, model) => {
    switch (modelType) {
      case "location":
        return Object.keys(locationFields).map((field) => {
          return {
            field: field,
            xs: locationFields[field].xs ? locationFields[field].xs : 12,
          };
        });
      case "user":
        return Object.keys(userFields).map((field) => {
          return {
            field: field,
            xs: userFields[field].xs ? userFields[field].xs : 12,
          };
        });
      default: {
        let keysForFieldMap = getOrgFieldsForMapping({
          isParent,
          isSuperadmin,
          isMarketTypeMSP,
          isBlumiraAgentEnabled,
          currentLicenseSelection,
          isChangeManagementPolicyTrue: props.isChangeManagementPolicyTrue,
          selectedOrgModel,
        });
        return keysForFieldMap.map((field) => {
          return {
            field: field,
            xs: organizationFields[field]?.xs
              ? organizationFields[field].xs
              : 12,
          };
        });
      }
    }
  };

  const getFields = (modelType) => {
    let fieldsToReturn = organizationFields;
    switch (modelType) {
      case "location":
        fieldsToReturn = locationFields;
        break;
      case "user":
        fieldsToReturn = userFields;
        break;
      default:
        fieldsToReturn = organizationFields;
        break;
    }
    return fieldsToReturn;
  };

  const handleGetChildAndParentOrgs = async (model, isSuperAdmin = false) => {
    // no need to make MSP-related requests on orgs
    // that are not of the MSP market type (20)
    // additionally, only Blumira superadmins are
    // able to edit MSP child/parent relationships
    if (model?.market === 20 && isSuperAdmin) {
      try {
        // fetch children of editing org to validate that this org is not
        // a parent to avoid a parent org being a child in a differnt org
        const childOrgs = await new Request("/org", [
          { field: "parentId", value: model?.id },
        ]).get();

        // if no orgs are returned or the model has an existing parentId the org being
        // edited is not a parent and can therefore be a child of a different org
        if (!childOrgs.length || model?.parentId) setIsParent(false);
        else if (model.market === 20 && !model?.parentId) setIsParent(true);

        // if we do not yet have a parent org or the user changed the
        // parentID and it does not match current parent org in state
        if ((parentOrg && parentOrg.id !== model.parentId) || !parentOrg) {
          const existingParentOrg = await new Request("/org", [
            { field: "id", value: model.parentId },
          ]).get();
          setParentOrg(existingParentOrg[0]);
        }

        // if we have success do not display err banner
        setErrorMessage(null);
      } catch (e) {
        // display an error message to super admins
        setErrorMessage(
          "We had an issue loading parent or child account information. Please double check the desired parent ID."
        );
        setIsLoading(false);
      }
    }
  };

  const onValidate = (model, fields) => {
    // check if the model is now MSP market type and if it is
    // trim out the new license definitions from the select
    const newLicenseLookup = getLicenseLookup({
      model: model,
      isSuperadmin: props.isSuperadmin,
    });

    // check length and only set state if result is
    // different to avoid memory leaks
    if (newLicenseLookup.length !== licenseLookup.length)
      setLicenseLookup(newLicenseLookup);

    // reset license feature helper text at start of validation
    setLicenseFeatureHelperText("");

    const isBlumiraAgentEnabledOnModel = getIsBlumiraAgentEnabled(model);

    // get license value to compare against existing license
    const newLicenseField = get(fields, ["license", "value"], undefined);

    if (newLicenseField) {
      const dataRetentionLicenses = [
        "XDR",
        "SIEM_ENDPOINT",
        "SIEM_PRO",
        "ADVANCED",
      ];

      if (!dataRetentionLicenses.includes(newLicenseField)) {
        model.set({ dataTableExpireDays: null });
      }

      // Make sure that an already set data retention value is not later set to a lower value
      if (
        selectedOrgModel?.config?.data_table_expire_days >
        fields?.dataTableExpireDays?.value
      ) {
        setDataRetentionValueError(
          "The selected data retention value cannot be lower than the current data retention value"
        );
      } else {
        setDataRetentionValueError(null);
      }

      // set license in state to track if additional fields are needed
      setCurrentLicenseSelection(newLicenseField);

      // compare against existing license
      const newShouldShowMessageBoolean = getShouldDisplayDataRetentionMessage(
        newLicenseField,
        model
      );

      // show informational alert for data retention if appropriate
      setShouldDataRetentionMessageBeDisplayed(newShouldShowMessageBoolean);

      if (DEFAULT_FEATURES[model.license]) {
        // if the selected license has default features, add 'em here
        // create a 'new Set' to ensure we store unique values only
        const modelFeaturesWithDefaultFeatures = [
          ...new Set(
            model.licenseFeatures.concat(DEFAULT_FEATURES[model.license])
          ),
        ];

        model.set({
          licenseFeatures: modelFeaturesWithDefaultFeatures,
        });

        // if the number of features selected exceeds the number in the default list
        // notify the user that the features selected are not included in the current plan
        // else display the default feature values to the user
        if (
          Object.values(DEFAULT_FEATURES[model.license]).length !==
          model.licenseFeatures.length
        ) {
          setLicenseFeatureHelperText(
            "We detected additional features outside of the edition selected"
          );
        } else {
          setLicenseFeatureHelperText(
            `We detected default features: ${Object.values(
              DEFAULT_FEATURES[model.license]
            ).join(", ")}`
          );
        }
      }
      // if the user has selected license features but there are no default features
      // notify the user that the features selected are not included in the current plan
      else if (
        model.licenseFeatures.length &&
        !DEFAULT_FEATURES[model.license]
      ) {
        setLicenseFeatureHelperText(
          `We detected additional features outside of the edition selected: ${model.licenseFeatures.join(
            ", "
          )}`
        );
      }

      // if the market is self-service and the new license is FREE or XDR_TRIAL and the org type is normal
      // set org type to poc, otherwise if the org type is poc set it to normal
      if (model.market === 30) {
        if (
          (newLicenseField === "FREE" || newLicenseField === "XDR_TRIAL") &&
          model.orgType === "normal"
        ) {
          model.set({ orgType: "poc" });
        } else if (
          newLicenseField !== "FREE" &&
          newLicenseField !== "XDR_TRIAL" &&
          model.orgType === "poc"
        ) {
          model.set({ orgType: "normal" });
        }
      }
    }

    setIsBlumiraAgentEnabled(isBlumiraAgentEnabledOnModel);

    // only handle parent/child logic if
    // current organziation is market MSP
    if (model.market === 20) {
      setIsMarketTypeMSP(true);
      const isParentIdModifiedAndValid = getIsParentIdModifiedAndValid(model);

      // if this is a super admin who is administering
      // an org, update UI to reflect potential changes
      if (isParentIdModifiedAndValid)
        handleGetChildAndParentOrgs(model, props.superadmins);
      // if modified but unable to retrieve parent
      // account based on improper UUID structure
      // reflect this in the UI
      else setParentOrg(false);
    }

    return fields;
  };

  const shouldAlertBeDisplayed = () => {
    // if we have an error to dsiplay, do so
    if (errorMessage) return true;

    // if this is an SA editing a restricted org,
    // display directions for updating properly
    if (props.isChangeManagementPolicyTrue && isSuperadmin) return true;

    // if the current org is not of type MSP or this
    // is not an SA administering, do not display
    if (!isMarketTypeMSP || !props.isAdministeringOrg) return false;

    // if this is a parent org
    if (isMarketTypeMSP && isParent) return true;

    // if market type is MSP and this
    // org is not a parent, display
    if (isMarketTypeMSP && !isParent) return true;

    // if we have a parentOrg loaded that
    // means this is a child account, display
    if (parentOrg) return true;

    // if any other situation, do not display
    return false;
  };

  const getAlertTextBeDisplayed = () => {
    if (errorMessage) return errorMessage;

    if (props.isChangeManagementPolicyTrue)
      return "For modifications to this org, you must follow the ops team CHANGE management policy";

    // this org is a parent
    if (isParent)
      return "This organization *is* a parent, NFR account and cannot be made a child account";

    // super admin has entered a parent id
    // that matches the id of an org in our db
    if (parentOrg)
      return `As is, this organization will be a child of the organization named: ${parentOrg.name}`;

    // this org is not a parent
    if (!isParent)
      return "This organization is not a parent, NFR account and can safely be made a child account";
  };

  return (
    <OrgDialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={openForm}
      fullWidth={true}
      maxWidth="md"
      datacy={"editOrganizationModal"}
    >
      <DialogTitle id="simple-dialog-title">
        {selectedOrgModel.id
          ? `Edit ${selectedOrgModel.name}`
          : `New Organization, Location, User`}
      </DialogTitle>
      {shouldAlertBeDisplayed() && (
        <Alert
          severity={errorMessage ? "error" : "info"}
          className={dialogClasses.alert}
        >
          {getAlertTextBeDisplayed()}
        </Alert>
      )}
      {shouldDataRetentionMessageBeDisplayed && (
        <Alert
          severity={errorMessage ? "error" : "info"}
          className={dialogClasses.alert}
        >
          <p>
            Your license selection will move you to a shorter data retention
            period. Any logging data outside of your new retention period will
            be immediately purged. If you have any questions about this, please
            reference{" "}
            <a
              target={"_blank"}
              href={blumiraEditionsUrl}
              rel={"noopener noreferrer"}
            >
              About the Blumira Editions
            </a>{" "}
            or email{" "}
            <a
              target={"_blank"}
              href={"mailto:support@blumira.com"}
              title={"support@blumira.com"}
              rel={"noopener noreferrer"}
            >
              support@blumira.com
            </a>{" "}
            before saving your changes.
          </p>
        </Alert>
      )}
      {isLoading ? (
        <div className={dialogClasses.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <SimpleModelForm
            spacing={1}
            isSaving={isSaving}
            onValidate={onValidate}
            model={selectedOrgModel}
            onClose={handleClose}
            layout={getLayout("org")}
            fields={getFields("org")}
            actions={
              !locationModel
                ? [
                    {
                      title: "Cancel",
                      type: "button",
                      action: handleClose,
                    },
                    {
                      title: "Save",
                      type: "submit",
                    },
                  ]
                : null
            }
            formError={dataRetentionValueError}
          />
          {!!locationModel && (
            <SimpleModelForm
              spacing={1}
              isSaving={isSaving}
              model={locationModel}
              onClose={handleClose}
              layout={getLayout("location")}
              fields={getFields("location")}
            />
          )}
          {!!userModel && (
            <SimpleModelForm
              spacing={1}
              isSaving={isSaving}
              model={userModel}
              onClose={handleClose}
              actions={[
                {
                  title: "Cancel",
                  type: "button",
                  action: handleClose,
                },
                {
                  title: "Save",
                  type: "button",
                  action: handleSaveClick,
                },
              ]}
              layout={getLayout("user")}
              fields={getFields("user")}
            />
          )}
        </div>
      )}
    </OrgDialog>
  );
};

const OrganizationPageView = (props) => {
  const [openForm, setOpenForm] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [selectedOrgModel, setSelectedOrgModel] = useState(false);
  const [org, setOrg] = useState(null);
  const [openScheduledLicenseForm, setOpenScheduledLicenseForm] =
    useState(false);
  const [scheduledLicenseName, setScheduledLicenseName] = useState("XDR_TRIAL");
  const [scheduledLicenseStartTime, setScheduledLicenseStartTime] = useState(
    moment(new Date()).utc().tz(moment.tz.guess(true))
  );
  const [scheduledLicenseEndTime, setScheduledLicenseEndTime] = useState(
    moment(new Date()).utc().tz(moment.tz.guess(true)).add(37, "days")
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (props.ready) {
      if (props.org.length > 0) {
        setOrg(props.org[0]);
      } else {
        setOrg(null);
      }
    }
  }, [props.org, props.ready]);

  const handleEditOrgClick = () => {
    setSelectedOrgModel(org);
    setOpenForm(true);
  };

  const handleClose = (action = null) => {
    setOpenForm(false);
    setSelectedOrgModel(false);
    window.location.reload();
  };

  const handleDelete = () => {
    selectedOrgModel.delete().then(() => {
      props.handleDeleteDone();
    });
  };

  const leftHeader = {
    component: "th",
    width: 1,
    variant: "head",
    className: pageClasses.tableLeftHeaderCell,
  };

  const handleScheduleLicenseSave = () => {
    if (selectedOrgModel?.licenseScheduled) {
      selectedOrgModel.set({
        licenseScheduled: {
          created: selectedOrgModel.licenseScheduled.created,
          license_name: scheduledLicenseName,
          start: scheduledLicenseStartTime.toISOString(),
          end: scheduledLicenseEndTime.toISOString(),
        },
      });
    } else {
      selectedOrgModel.set({
        licenseScheduled: {
          license_name: scheduledLicenseName,
          start: scheduledLicenseStartTime.toISOString(),
          end: scheduledLicenseEndTime.toISOString(),
        },
      });
    }

    setIsSaving(true);
    selectedOrgModel
      .update()
      .then(() => {
        window.location.reload();
        setOpenScheduledLicenseForm(false);
        setIsSaving(false);
        setSelectedOrgModel(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOpenScheduledLicenseModal = () => {
    setOpenScheduledLicenseForm(true);
    setSelectedOrgModel(org);

    if (org?.licenseScheduled) {
      setScheduledLicenseStartTime(moment(org?.licenseScheduled.start));
      setScheduledLicenseEndTime(moment(org?.licenseScheduled.end));
    }
  };

  const handleCloseScheduledLicenseModal = () => {
    setOpenScheduledLicenseForm(false);
    setSelectedOrgModel(false);
    setScheduledLicenseStartTime(
      moment(new Date()).utc().tz(moment.tz.guess(true))
    );
    setScheduledLicenseEndTime(
      moment(new Date()).utc().tz(moment.tz.guess(true)).add(37, "days")
    );
  };

  const renderWarningText = () => {
    if (org?.licenseScheduled) {
      return (
        <div>
          <p style={{ marginBottom: 10 }}>
            You are about to edit an existing scheduled license for an XDR Timed
            Trial on this org. Please do so with caution and only if you are
            100% sure of what you're doing and why you're doing it.
          </p>
          <p>
            A scheduled license should mainly be edited for one of the following
            reasons:
          </p>
          <ul>
            <li>
              A customer has reached out to sales to upgrade from the trial to
              the full version of XDR and their trial end date needs to be
              adjusted to end their trial early.
            </li>
            <li>
              A customer is working with the sales team and their trial end date
              needs to be extended to a later date to accommodate the sales
              process.
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div>
          <p style={{ marginBottom: 10 }}>
            You are about to add a new scheduled license for an XDR Timed Trial
            to this org. Please do so with caution and only if you are 100% sure
            of what you're doing and why you're doing it.
          </p>
          <p>
            Scheduled licenses for an XDR Timed Trial are only meant for orgs
            that meet the following requirements:
          </p>
          <ul>
            <li>A Free license org</li>
            <li>An org with a direct or self-service market</li>
          </ul>
          <p>
            A scheduled license should mainly be added from this modal for the
            following reason:
          </p>
          <ul>
            <li>
              Sales has decided not to grant an org the ability to self start a
              trial by back dating a trial on that org with a time period that
              has already passed.
            </li>
          </ul>
        </div>
      );
    }
  };

  return (
    <Root className="dashboard organization-page">
      <div id="organization-information">
        {!props.ready || !org ? (
          <LoadingTable isMaterial />
        ) : (
          <>
            <Paper style={{ marginBottom: 30 }}>
              <Toolbar variant="dense" className={pageClasses.toolBar}>
                <Grid
                  container
                  alignItems="center"
                  className={pageClasses.titleBar}
                >
                  <Typography variant="h6" id="tableTitle" component="div">
                    Organization Details
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleEditOrgClick}
                    startIcon={<EditIcon />}
                    datacy={"simpleTableRowIconButton"}
                  >
                    Edit org details
                  </Button>

                  {!!props.superadmin && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={handleOpenScheduledLicenseModal}
                      startIcon={<EditIcon />}
                      datacy={"simpleTableScheduledLicenseButton"}
                    >
                      {org?.licenseScheduled
                        ? "Edit scheduled license"
                        : "Add scheduled license"}
                    </Button>
                  )}
                </Grid>
              </Toolbar>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell {...leftHeader}>Name:</TableCell>
                      <TableCell>{org?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell {...leftHeader}>Short Name:</TableCell>
                      <TableCell>{org?.shortName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell {...leftHeader}>Web Domain:</TableCell>
                      <TableCell>{org?.webUrl}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell {...leftHeader}>License:</TableCell>
                      <TableCell>
                        {org.license
                          ? LICENSE_TYPES.find(
                              ({ value }) => value === org.license
                            ).label
                          : ""}
                      </TableCell>
                    </TableRow>
                    {!!props.superadmin && (
                      <TableRow>
                        <TableCell {...leftHeader}>Account Type:</TableCell>
                        <TableCell>
                          {
                            (
                              ORG_TYPES.find(
                                (ot) => ot.value === org?.orgType
                              ) || { label: org?.orgType }
                            ).label
                          }
                        </TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && (
                      <TableRow>
                        <TableCell {...leftHeader}>Market:</TableCell>
                        <TableCell>
                          {
                            (
                              MARKETS.find((m) => m.value === org?.market) || {
                                label: org?.market,
                              }
                            ).label
                          }
                        </TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && (
                      <TableRow>
                        <TableCell {...leftHeader}>Internal Notes:</TableCell>
                        <TableCell>{org?.notes}</TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && (
                      <TableRow>
                        <TableCell {...leftHeader}>Parent ID:</TableCell>
                        <TableCell>{org?.parentId}</TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && getIsBlumiraAgentEnabled(org) && (
                      <TableRow>
                        <TableCell {...leftHeader}>Agent Seat Count:</TableCell>
                        <TableCell>{org?.agentSeatCount}</TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && (
                      <TableRow>
                        <TableCell {...leftHeader}>User Count:</TableCell>
                        <TableCell>{org?.userCount}</TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && (
                      <TableRow>
                        <TableCell {...leftHeader}>
                          Data Retention (days):
                        </TableCell>
                        <TableCell>{org?.dataTableExpireDays}</TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && (
                      <TableRow datacy={"tableRowScheduledLicenseName"}>
                        <TableCell {...leftHeader}>
                          Scheduled License Name:
                        </TableCell>
                        <TableCell>
                          {
                            (
                              SCHEDULED_LICENSE_TYPES.find(
                                (sl) =>
                                  sl.value ===
                                  org?.licenseScheduled?.license_name
                              ) || {
                                label: org?.licenseScheduled?.license_name,
                              }
                            ).label
                          }
                        </TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && (
                      <TableRow datacy={"tableRowScheduledLicenseStart"}>
                        <TableCell {...leftHeader}>
                          Scheduled License Start Time:
                        </TableCell>
                        <TableCell>
                          {org?.licenseScheduled?.start
                            ? moment
                                .utc(org?.licenseScheduled?.start)
                                .tz(moment.tz.guess(true))
                                .format("lll z")
                            : ""}
                        </TableCell>
                      </TableRow>
                    )}
                    {!!props.superadmin && (
                      <TableRow datacy={"tableRowScheduledLicenseEnd"}>
                        <TableCell {...leftHeader}>
                          Scheduled License End Time:
                        </TableCell>
                        <TableCell>
                          {org?.licenseScheduled?.end
                            ? moment
                                .utc(org?.licenseScheduled?.end)
                                .tz(moment.tz.guess(true))
                                .format("lll z")
                            : ""}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}

        <Dialog
          open={openScheduledLicenseForm}
          onClose={handleCloseScheduledLicenseModal}
          datacy={"scheduledLicenseModal"}
        >
          <DialogTitle>
            {org?.licenseScheduled ? "Edit existing" : "Add new"} scheduled
            license
          </DialogTitle>
          <DialogContent>
            <Alert severity="warning">{renderWarningText()}</Alert>
            <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
              <InputLabel id="scheduled-license-name-label">
                Scheduled License Name
              </InputLabel>
              <Select
                labelId="scheduled-license-name-label"
                id="scheduled-license-name"
                value={scheduledLicenseName}
                label="Scheduled License Name"
                onChange={(e) => setScheduledLicenseName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {SCHEDULED_LICENSE_TYPES.map((license) => (
                  <MenuItem key={license.value} value={license.value}>
                    {license.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: 500,
              }}
            >
              <DateTimePicker
                value={scheduledLicenseStartTime}
                label={"Scheduled License Start Time"}
                onChange={(newValue) => setScheduledLicenseStartTime(newValue)}
                slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
              />
              <DateTimePicker
                value={scheduledLicenseEndTime}
                label={"Scheduled License End Time"}
                onChange={(newValue) => setScheduledLicenseEndTime(newValue)}
                slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant={"text"}
              onClick={handleCloseScheduledLicenseModal}
              color={"primary"}
              datacy={"scheduledLicenseModalCancelButton"}
            >
              Cancel
            </Button>
            <Button
              onClick={handleScheduleLicenseSave}
              variant={"contained"}
              color={"primary"}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </Dialog>

        <ActionDialog
          open={openAction}
          title={`Delete ${selectedOrgModel && selectedOrgModel.name}`}
          description="Are you sure you want to delete this organization?"
          actions={[
            {
              title: "Cancel",
              action: () => setOpenAction(false),
            },
            {
              title: "Delete",
              action: handleDelete,
            },
          ]}
        />

        <EditOrgDialog
          openForm={openForm}
          handleClose={handleClose}
          isSuperadmin={!!props.superadmin}
          selectedOrgModel={selectedOrgModel}
          isAdministeringOrg={props.isAdministeringOrg}
          isChangeManagementPolicyTrue={!!org?.changeManagementPolicy}
        />
      </div>
    </Root>
  );
};

export const OrganizationComponent = OrganizationPageView;

const mapStateToProps = (state) => {
  return {
    sessionOrgId: state.page.payload.orgId,
    superadmin: state.session?.settings?.user?.superadmin,
  };
};

OrganizationPageView.propTypes = {
  orgs: PropTypes.array,
  payload: PropTypes.shape({}),
};

OrganizationPageView.defaultProps = {
  org: [],
  isFetching: true,
  orgsError: null,
  payload: { toplevel: "settings", secondlevel: "organization" },
};

export default connect(mapStateToProps)(OrganizationPageView);
