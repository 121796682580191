import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tab,
  Tabs,
  List,
  Radio,
  Button,
  ListItem,
  Checkbox,
  FormLabel,
  FormGroup,
  Typography,
  RadioGroup,
  FormControl,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  Autocomplete,
} from "@mui/material";
import { DropzoneArea } from "react-mui-dropzone";
import papa from "papaparse";
import { intersection, get } from "lodash";
import { Alert } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import GetAppIcon from "@mui/icons-material/GetApp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SouthIcon from "@mui/icons-material/South";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

import DotProgress from "../../DotProgress";
import SimpleTable from "../../SimpleTable";
import SimpleModelForm from "../../SimpleModelForm";
import { LICENSE_TYPES } from "../../../Pages/OrganizationPageView";
import {
  tabDialogClasses,
  confirmDialogClasses,
  StyledTabsDialog,
  StyledConfirmationDialog,
} from "./styles";
import {
  layoutObj,
  getColumns,
  roleTypes,
  multipleCSVcolumnsObj,
  multCSVColsNames,
  MAPPING_METHODS,
  mappingMethodDisplay,
  mappingMethod,
  matchByMappingMethodType,
} from "../helpers";
import {
  MSPEmailUrl,
  MSPPricingAndBillingUrl,
  translateColumnMap,
  numericTypeValues,
  billingAffectFields,
  newLicenseDefs,
  legacyLicenseDefs,
  licensesToExclude,
  WIZARD_STEPS_DEFAULT,
  WIZARD_STEPS_WITH_MAPPING,
} from "./constants";
import { connect } from "react-redux";
import { User } from "lib/models/User";
import { Org } from "lib/models/Org";
import { createUser } from "redux/actions/Users";
import { PSACompanyMappingPSA } from "lib/models/PSACompanyMappingPSA";
import { PSAIntegration } from "lib/models/PSAIntegration";

const modelType = {
  user: User,
  account: Org,
};

const translateColumnNames = (translateColumnMap, parsedCSVData) => {
  return parsedCSVData.map((obj) => {
    const objectKeys = Object.keys(obj);
    const newObj = {};
    for (const key of objectKeys) {
      newObj[translateColumnMap[key]] = obj[key];
    }
    return newObj;
  });
};

const createModelList = (
  orgId,
  context,
  childOrganizationsList,
  personsList,
  modelList
) => {
  if (context === "user") {
    const userOrgRoles = [{ org_id: orgId, role_id: 40 }];

    childOrganizationsList.forEach((obj) => {
      userOrgRoles.push({ org_id: obj.id, role_id: 40 });
    });

    return modelList.map((model) => {
      const { firstName, lastName, email } = model;
      return {
        first_name: firstName,
        last_name: lastName,
        email,
        org_roles: userOrgRoles,
        config_alerts: {
          contact_details: {
            text_number: model["textNumber"],
            voice_number: model["voiceNumber"],
          },
        },
      };
    });
  } else if (context === "account") {
    return modelList.map((obj) => {
      const { name, userCount, webUrl } = obj;
      return new modelType[context]({
        name,
        webUrl,
        license: "FREE",
        parentId: orgId,
        persons: personsList,
        config: { userCount: userCount },
        market: 20, // child accounts should default to MSP market
      });
    });
  }
};

export const getTabName = (tabValue, model, context) => {
  if (parseInt(tabValue) === 1) {
    return model.id ? "Details" : "Single";
  } else if (parseInt(tabValue) === 2) {
    return model.id
      ? `${context === "user" ? "Accounts" : "Users"}`
      : "Multiple";
  }
};

export const shouldShowDialogActions = (currentTabName, user = {}) =>
  !(currentTabName === "Multiple" && !user.superadmin);

// license changing is handled outside of the
// simple model form, so verify that on its own
export const hasModelBeenModified = (model, hasLicenseChangeed) => {
  return !!Object.values(model._original_params).length || hasLicenseChangeed;
};

export const shouldShowConfirmationDialog = (model, hasLicenseChanged) => {
  // get the org's license to determine if it's not FREE
  const licenseSelection = get(model, ["config", "license"], null);

  const modelKeysModified = Object.keys(model._original_params);

  // if there is an intersection of these arrays
  // we know that the config has been modified
  // and therefore we should show a confirmation
  let isBillingFieldChanged = !!intersection(
    modelKeysModified,
    billingAffectFields
  ).length;

  // if the edited org is a FREE license we do not need to show
  // the confirmation dialog since billing is unaffected
  if (licenseSelection === "FREE") return false;

  return isBillingFieldChanged || hasLicenseChanged;
};

export const isCheckboxChecked = (boxRole = null, rolesBoxesChecked = []) => {
  if (Array.isArray(rolesBoxesChecked) && rolesBoxesChecked.length === 0) {
    return false;
  }
  if (rolesBoxesChecked.some((role) => role === boxRole)) {
    return true;
  } else {
    return false;
  }
};

export const getMSPLicenseListToRender = ({
  licenseListToFilter = [],
  license,
}) => {
  // if the Parent has any of the 3 new edition licenses, filter out the old options and show the new options
  if (
    license.isTagged("limited-to-xdr") ||
    license.isTagged("limited-to-siem-endpoint") ||
    license.isTagged("limited-to-siem-pro")
  ) {
    return licenseListToFilter.filter(
      (licenseValue) =>
        !legacyLicenseDefs.includes(licenseValue.value) &&
        !licensesToExclude.includes(licenseValue.value)
    );
  }
  // otherwise filter the new options out and show the legacy options
  return licenseListToFilter.filter(
    (licenseValue) =>
      !newLicenseDefs.includes(licenseValue.value) &&
      !licensesToExclude.includes(licenseValue.value)
  );
};

const MappingComponent = ({
  accountName,
  autocompleteKey,
  handleMappingChange,
  mappingMethodTypeDisplay,
  mappingVal,
  model,
  psaCompanyList,
  psaCompanyListLoading,
  psaConnectionError,
  psaHelperMessage,
  psaIntegrationData,
  setNewMapping,
}) => {
  return (
    <div className={tabDialogClasses.mappingWrapper}>
      <TextField
        label="Blumira Account Name"
        type="text"
        value={accountName}
        slotProps={{
          input: {
            readOnly: true,
          },
        }}
        required
      />
      <Typography className={tabDialogClasses.mapToText}>
        Map to <SouthIcon />
      </Typography>
      <Autocomplete
        id="mapping-autocomplete-label"
        options={psaCompanyList.sort((a, b) => {
          if (a.id === psaIntegrationData.defaultCompany) return -1;
          if (b.id === psaIntegrationData.defaultCompany) return 1;
          return 0;
        })}
        autoHighlight
        disableClearable
        loading={psaCompanyListLoading}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) =>
          psaIntegrationData.companySyncing ===
          MAPPING_METHODS.companyName.value
            ? option.name
            : option.identifier
        }
        key={autocompleteKey}
        value={mappingVal}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={handleMappingChange}
        groupBy={(option) =>
          option.id === psaIntegrationData.defaultCompany
            ? `Catchall Company ${mappingMethodTypeDisplay}`
            : `Company ${mappingMethodTypeDisplay}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            error={psaConnectionError}
            helperText={psaHelperMessage}
            label={`ConnectWise Manage Company ${mappingMethodTypeDisplay}`}
            fullWidth
          />
        )}
      />
    </div>
  );
};

MappingComponent.propTypes = {
  accountName: PropTypes.string,
  autocompleteKey: PropTypes.number,
  handleMappingChange: PropTypes.func,
  mappingMethodTypeDisplay: PropTypes.string,
  mappingVal: PropTypes.object,
  model: PropTypes.object,
  psaCompanyList: PropTypes.arrayOf(PropTypes.shape({})),
  psaCompanyListLoading: PropTypes.bool,
  psaConnectionError: PropTypes.string,
  psaHelperMessage: PropTypes.object,
  psaIntegrationData: PropTypes.object,
  setNewMapping: PropTypes.func,
};

const MSPDetailDialog = (props) => {
  const {
    open,
    model,
    toggle,
    context,
    isSaving,
    tableData,
    errorMessage,
    handleSubmit,
    isConfirming,
    selectedTableData,
    handleSelectTableItem,
    childOrganizationsList,
    orgId,
    orgType,
    addNewUser,
    personsList = [],
    rolesBoxesChecked,
    disabledTableData,
    user,
    license,
    creationStep,
    setCreationStep,
    doesAnyChildOrgHaveLegacyLicense,
  } = props;

  const [csvFile, setCSVFile] = useState("");
  const [tabValue, setTabValue] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [parsedCSVData, setParsedCSVData] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [isMultipleUpload, setIsMultipleUpload] = useState(false);
  const [isAllRowsProcessed, setAllRowsProcessed] = useState(false);
  const [hasLicenseChangeed, setHasLicenseChanged] = useState(false);
  const [initialModelValues, setInitialModelValues] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [maximumDeployableAgents, setMaximumDeployableAgents] = useState(0);
  const [isNonSuperAdminUploading, setIsNonSuperAdminUploading] =
    useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [creationStepVal, setCreationStepVal] = useState(WIZARD_STEPS_DEFAULT);
  const [psaCompanyList, setPSACompanyList] = useState([]);
  const [psaCompanyListLoading, setPSACompanyListLoading] = useState();
  const [psaHelperMessage, setPsaHelperMessage] = useState("");
  const [psaConnectionError, setPsaConnectionError] = useState(false);
  const [accountName, setAccountName] = useState(model.name || "");
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [mappingVal, setMappingVal] = useState();
  const [psaIntegrationData, setPsaIntegrationData] = useState({});

  // xx remove superadmin at release
  const canSeeMapping =
    context === "account" && props.isSuperadmin && orgType === "normal";

  useEffect(() => {
    // if we have a model and have not set
    // intial values in the state, do so now
    if (model && !initialModelValues) {
      setInitialModelValues(model);

      // if we are editing a model disable
      // until a value is changed to save
      if (model.id) setIsNextButtonDisabled(true);
    }
  }, [model, initialModelValues]);

  useEffect(() => {
    if (open === false) {
      setTabValue("1");
      setMappingVal();
      setAccountName("");
      setPsaHelperMessage("");
    }
  }, [open]);

  useEffect(() => {
    // the user is viewing the 'multiple' csv upload tab and
    // they are not a super admin user - if all works correctly
    // this will never happen but better safe than sorry
    if (
      !model.id &&
      creationStep === creationStepVal.details &&
      tabValue === "2" &&
      !user.superadmin
    )
      setIsNonSuperAdminUploading(true);
    else setIsNonSuperAdminUploading(false);

    if (tabValue === "3" && psaIntegrationData.id && !psaCompanyList.length) {
      setAccountName(model.name);
      fetchPSACompanyList();
    } else if (
      tabValue === "3" &&
      psaIntegrationData.id &&
      psaCompanyList.length
    ) {
      setAccountName(model.name);
      setMappingVal(
        psaCompanyList.find((psaCompany) => psaCompany.id === model.psaOrgId)
      );
      setAutocompleteKey((prevKey) => prevKey + 1);
    }
  }, [model, creationStep, tabValue, user]);

  useEffect(() => {
    if (
      creationStep === creationStepVal.psaMapping &&
      psaIntegrationData.id &&
      !psaCompanyList.length
    ) {
      fetchPSACompanyList();
    }
    if (creationStep === creationStepVal.psaMapping && model.name) {
      setAccountName(model.name);
    }

    if (
      creationStep === creationStepVal.psaMapping &&
      !!psaCompanyList.length &&
      accountName !== model.name
    ) {
      setNewMapping(psaCompanyList);
    }
  }, [creationStep]);

  useEffect(() => {
    const iconMap = {
      success: <CheckCircleIcon color="success" />,
      failed: <CancelIcon color="error" />,
      processing: <RotateLeftIcon className={tabDialogClasses.rotateIcon} />,
    };

    multipleCSVcolumnsObj[context].find(
      (obj) => obj.field === "status"
    ).renderValue = (column, model) => {
      const status = model[column.field];
      return iconMap[status];
    };

    if (!psaIntegrationData.id && canSeeMapping) {
      getPSAIntegration();
    }
  }, []);

  const connectwise = new PSAIntegration();

  const getPSAIntegration = () => {
    connectwise
      .read(props.orgId)
      .then((res) => {
        const params = res.params;
        if (params[0].enabled) {
          setPsaIntegrationData(params[0]);

          setCreationStepVal(WIZARD_STEPS_WITH_MAPPING);
        }
      })
      .catch((err) => null);
  };

  const fetchPSACompanyList = () => {
    const queryParams = { id: psaIntegrationData.id };

    setPSACompanyListLoading(true);
    setIsNextButtonDisabled(true);
    const PSAModel = new PSACompanyMappingPSA(queryParams);
    PSAModel.read()
      .then((result) => {
        const updatedCompanyList = result.companies.map((company) => ({
          ...company,
          id: company.id.toString(),
        }));
        setPSACompanyList(updatedCompanyList);

        if (!model.psaOrgId) {
          setNewMapping(updatedCompanyList);
          setIsNextButtonDisabled(false);
        } else {
          setMappingVal(
            updatedCompanyList.find(
              (updatedCompany) => updatedCompany.id === model.psaOrgId
            )
          );
          setAutocompleteKey((prevKey) => prevKey + 1);
        }
        setPSACompanyListLoading(false);
      })
      .catch(() => {
        setPsaHelperMessage("Failed to connect to ConnectWise API.");
        setPsaConnectionError(true);
        setPSACompanyListLoading(false);
        if (!model.psaOrgId) {
          setIsNextButtonDisabled(false);
        }
      });
  };

  const setNewMapping = (updatedPSACompanyList) => {
    if (!model.name) {
      return;
    }
    const mappingMethodType = mappingMethod(psaIntegrationData.companySyncing);
    const catchall = updatedPSACompanyList.find(
      (psaCompany) => psaCompany.id === psaIntegrationData.defaultCompany
    );

    const checkMappingMatch = matchByMappingMethodType(
      updatedPSACompanyList,
      model.name,
      mappingMethodType,
      catchall
    );

    if (checkMappingMatch === catchall) {
      setPsaHelperMessage(
        <div className={tabDialogClasses.mappingFail}>
          <ErrorIcon /> No match found
        </div>
      );
    } else {
      setPsaHelperMessage(
        <div className={tabDialogClasses.mappingSuccess}>
          <CheckIcon /> Match found
        </div>
      );
    }

    model.set({ ...model, psaOrgId: checkMappingMatch.id.toString() });
    setMappingVal(checkMappingMatch);
    setAutocompleteKey((prevKey) => prevKey + 1);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
    setCreationStep(creationStepVal.details);
    if (!model.id) {
      if (value === "2") {
        setIsMultipleUpload(true);
        if (csvFile) setIsNextButtonDisabled(false);
        else setIsNextButtonDisabled(true);
      } else if (value === "1") {
        setIsMultipleUpload(false);
      }
    }
  };

  const handleBack = () => {
    setCreationStep((prevState) => prevState - 1);
  };

  const handleNext = () => {
    // multiple/csv upload
    if (isMultipleUpload) {
      // this shouldn't ever happen but if somehow a
      // non-sa is viewing multiple upload, stop them
      if (!user.superadmin) return;

      // first step
      if (creationStep === creationStepVal.details) {
        setIsLoading(true);
        papa.parse(csvFile, {
          header: true,
          error: (err) => {
            console.log("papa parse error: ", err);
          },
          complete: (res) => {
            const validCSVCols = Object.keys(res.data[0]).every(
              (CSVColHeader) => multCSVColsNames[context].includes(CSVColHeader)
            );

            if (
              Array.isArray(res.data) &&
              res.data.length > 0 &&
              validCSVCols
            ) {
              setParsedCSVData(
                res.data.map((obj) => {
                  if (context === "user") {
                    obj.id = obj["Email"];
                  } else {
                    obj.id = obj["Organization Name"];
                  }
                  for (const [key, value] of Object.entries(obj)) {
                    if (numericTypeValues.includes(key)) {
                      obj[key] = parseInt(value);
                    }
                  }
                  obj.status = "";
                  return obj;
                })
              );

              const translatedObjectList = translateColumnNames(
                translateColumnMap[context],
                res.data
              );
              const modelList = createModelList(
                props.orgId,
                context,
                childOrganizationsList,
                personsList,
                translatedObjectList
              );
              setModelList(modelList);
            }
            setIsLoading(false);
          },
        });
      }

      // final step
      if (creationStep === creationStepVal.access) {
        setIsLoading(true);
        if (context === "user") {
          (async () => {
            for (const model of modelList) {
              try {
                updateMultipleUploadRowStatus(
                  "email",
                  "Email",
                  "processing",
                  model
                );
                await addNewUser(orgId, model);
                updateMultipleUploadRowStatus(
                  "email",
                  "Email",
                  "success",
                  model
                );
              } catch (e) {
                updateMultipleUploadRowStatus(
                  "email",
                  "Email",
                  "failed",
                  model
                );
              }
            }
            setAllRowsProcessed(true);
          })();
        } else if (context === "account") {
          (async () => {
            for (const model of modelList) {
              try {
                updateMultipleUploadRowStatus(
                  "name",
                  "Organization Name",
                  "processing",
                  model
                );
                await model.create();
                updateMultipleUploadRowStatus(
                  "name",
                  "Organization Name",
                  "success",
                  model
                );
              } catch (e) {
                updateMultipleUploadRowStatus(
                  "name",
                  "Organization Name",
                  "failed",
                  model
                );
              }
            }
            setAllRowsProcessed(true);
          })();
        }
        setIsLoading(false);
        return;
      }
    }

    // final step or editing and trying to save
    if (creationStep === creationStepVal.access || model.id) {
      // open confirmation dialog with warning text
      if (shouldShowConfirmationDialog(model, hasLicenseChangeed))
        return toggleConfirmationDialog();
      // if no change was made that affects billing
      // do not confirm, simply save the model
      else return handleSubmit(model);
    }

    setCreationStep((prevState) => prevState + 1);
  };

  const handleToggle = () => {
    // reset state values for refreshed
    // experience when dialog is closed
    setHasLicenseChanged(false);
    setInitialModelValues(false);
    setCreationStep(creationStepVal.details);
    toggle();
  };

  const getSelectedTableData = () => {
    // when an msp is editing a user or an org
    // determine what items to pre-select
    let tableDataToReturn = [];

    // editing a user shows orgs enrolled in
    if (context === "user")
      tableDataToReturn = model.orgRoles.map((role) => role.orgId);

    // editing an account (org) shows users enrolled
    if (context === "account") {
      // filter users based on their enrollment
      // and then return the user's id as an array
      // *this is handled in the msp accounts page
      tableDataToReturn = selectedTableData;
    }

    return tableDataToReturn;
  };

  const dropzoneFile = (file) => {
    if (file.length) {
      // clear any previous parsed CSVs
      setParsedCSVData([]);

      setCSVFile(file[0]);

      // once user selects file
      setIsNextButtonDisabled(false);
    }
  };

  const onValidate = (model, fields) => {
    if (context === "account") {
      const newMaximumDeployableAgents = get(model, ["config", "userCount"], 0);

      // If there are any children with legacy licenses, use 1:1 agent count to seat count
      if (doesAnyChildOrgHaveLegacyLicense) {
        setMaximumDeployableAgents(newMaximumDeployableAgents);
        // Otherwise, use new 2:1 agent count to user count
      } else {
        setMaximumDeployableAgents(newMaximumDeployableAgents * 2);
      }
      // name, webUrl and minimum userCount are
      // required for first step in creating org
      if (creationStep === creationStepVal.details) {
        if (
          !model.name ||
          !model.config.userCount ||
          model.config.userCount < 1 ||
          !model.webUrl
        ) {
          return setIsNextButtonDisabled(true);
        } else if (model.id) {
          // original params are updated when
          // a field changes on the model, enable
          // save as soon as a user edits a value
          const modelHasBeenModified = hasModelBeenModified(
            model,
            hasLicenseChangeed
          );

          shouldShowConfirmationDialog(model, hasLicenseChangeed);

          if (modelHasBeenModified) return setIsNextButtonDisabled(false);

          return setIsNextButtonDisabled(true);
        }
      }
    } else {
      // handle user validation here
      if (creationStep === creationStepVal.details) {
        // if user has no first/last name or no email
        // disable 'next' button
        if (
          !model.firstName ||
          !model.lastName ||
          !model.email ||
          !rolesBoxesChecked.length
        )
          return setIsNextButtonDisabled(true);
      }
    }

    // all requirements met, enable
    setIsNextButtonDisabled(false);
  };

  const getMaxWidth = () => {
    let maxWidth = "sm";
    if (!!csvFile && creationStep > 0) maxWidth = "md";
    return maxWidth;
  };

  const isButtonDisabled = () => {
    return isNextButtonDisabled || isSaving || isNonSuperAdminUploading;
  };

  const displayDialogActions = (showDialogActions) => {
    if (showDialogActions === false) {
      return <></>;
    }
    if (isConfirming) {
      return (
        <DialogActions className={tabDialogClasses.dialogActions}>
          <div
            className={`${tabDialogClasses.dialogActionsColumn} ${tabDialogClasses.dialogActionsLastColumn}`}
          >
            <div className={tabDialogClasses.dialogActionsRightColumnWrapper}>
              <Button variant={"text"} onClick={handleToggle} color={"primary"}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant={"contained"}
                color={"primary"}
                disabled={!!isSaving}
              >
                {isSaving ? "Saving..." : "Save Changes"}
              </Button>
            </div>
          </div>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions className={tabDialogClasses.dialogActions}>
          <div
            className={`${tabDialogClasses.dialogActionsColumn} ${tabDialogClasses.dialogActionsFirstColumn}`}
          >
            <div className={tabDialogClasses.dialogActionsLeftColumnWrapper}>
              <Button
                hidden={creationStep === creationStepVal.details}
                variant={"text"}
                onClick={handleBack}
                color={"primary"}
              >
                Back
              </Button>
            </div>
          </div>
          <div
            className={`${tabDialogClasses.dialogActionsColumn} ${tabDialogClasses.progressDotsCol}`}
          >
            <div
              className={tabDialogClasses.dialogActionsCenterColumnWrapper}
              hidden={!!model.id}
            >
              <DotProgress
                stepsLength={Object.keys(creationStepVal).length}
                stepsCompleted={creationStep + 1}
              />
            </div>
          </div>
          <div
            className={`${tabDialogClasses.dialogActionsColumn} ${tabDialogClasses.dialogActionsLastColumn}`}
          >
            <div className={tabDialogClasses.dialogActionsRightColumnWrapper}>
              <Button
                variant={"text"}
                onClick={handleToggle}
                color={"primary"}
                datacy={"cancelButton"}
              >
                {isSaving ? "Close" : "Cancel"}
              </Button>
              <Button
                color={"primary"}
                variant={"contained"}
                onClick={handleNext}
                disabled={isButtonDisabled()}
              >
                {isSaving ? (
                  "Saving..."
                ) : model.id ? (
                  "Save changes" // the user is saving an existing model
                ) : !model.id &&
                  creationStep === creationStepVal.details &&
                  tabValue === "2" ? (
                  "Upload" // the user is viewing the 'multiple' csv upload tab
                ) : (
                  <>
                    {
                      creationStep === creationStepVal.access ? ( // the user is on the final step of creating a new model
                        <>
                          Create {context}
                          {tabValue === "2" ? "s" : ""}
                        </> // make plural if 'multiple' tab
                      ) : (
                        "Next"
                      ) // the user is on the first step in the first tab of creating a new model
                    }
                  </>
                )}
              </Button>
            </div>
          </div>
        </DialogActions>
      );
    }
  };

  const accountFields = {
    name: {
      type: "text",
      label: "Account name",
    },
    webUrl: {
      type: "text",
      label: "Web domain",
      helperText: "* Required",
    },
    "config.userCount": {
      type: "number",
      label: "User count",
      helperText:
        '* Required (minimum of 1) - A "user" refers to the number of knowledge workers at your organization; basically the number of employees with emails who use them.',
    },
  };

  const userFields = {
    firstName: {
      type: "text",
      label: "First name",
      helperText: "* Required",
    },
    lastName: {
      type: "text",
      label: "Last name",
      helperText: "* Required",
    },
    email: model.id
      ? {
          //edit user
          type: "text",
          readOnly: true,
          plainText: true,
          label: "Email address",
        }
      : //new user
        {
          type: "text",
          label: "Email address",
        },
    "configAlerts.contact_details.voice_number": {
      type: "text",
      default: "",
      label: "Phone number for voice alerts",
    },
    "configAlerts.contact_details.text_number": {
      type: "text",
      default: "",
      label: "Phone number for text alerts",
    },
  };

  const getFields = (context) => {
    //pulling the fields object out of the helpers file back into here
    //because when in the helpers file, the form wasn't updating the model properly
    //putting this back in here like this solves that problem
    let fieldsToReturn = userFields;
    switch (context) {
      case "account":
        fieldsToReturn = accountFields;
        break;
      case "user":
        fieldsToReturn = userFields;
        break;
      default:
        fieldsToReturn = userFields;
        break;
    }
    return fieldsToReturn;
  };

  const updateMultipleUploadRowStatus = (
    modelAttribute,
    tableAttribute,
    status,
    model
  ) => {
    setParsedCSVData(
      parsedCSVData.map((obj) => {
        if (obj[tableAttribute] === model[modelAttribute]) {
          obj.status = status;
        }
        return obj;
      })
    );
  };

  const toggleConfirmationDialog = () => {
    setIsConfirmationDialogOpen(!isConfirmationDialogOpen);
  };

  const handleConfirm = () => {
    toggleConfirmationDialog();
    return handleSubmit(model);
  };

  const selectTableItem = (items) => {
    setIsNextButtonDisabled(false);
    handleSelectTableItem(items);
  };

  const currentTabName = getTabName(tabValue, model, context);

  const showDialogActions = shouldShowDialogActions(currentTabName, user);

  const handleMappingChange = (_, newValue) => {
    model.set({ ...model, psaOrgId: newValue.id.toString() });
    setMappingVal(newValue);
    setPsaHelperMessage("");
    setIsNextButtonDisabled(false);
  };

  const mappingMethodTypeDisplay = mappingMethodDisplay(
    psaIntegrationData.companySyncing
  );

  return (
    <div>
      <StyledTabsDialog
        open={open}
        fullWidth={true}
        onClose={handleToggle}
        maxWidth={getMaxWidth()}
        classes={{ paper: tabDialogClasses.dialogPaper }}
      >
        <DialogTitle id={"msp-account-dialog-title"}>
          {model.id
            ? `${context === "user" ? "User" : "Account"} details`
            : `Add ${context}`}
        </DialogTitle>
        <DialogContent>
          <TabContext value={tabValue}>
            <Tabs
              value={tabValue}
              textColor={"primary"}
              indicatorColor={"primary"}
              onChange={handleTabChange}
              className={tabDialogClasses.tabs}
            >
              <Tab label={model.id ? "Details" : "Single"} value={"1"} />
              <Tab
                label={
                  model.id
                    ? `${context === "user" ? "Accounts" : "Users"}`
                    : "Multiple"
                }
                value={"2"}
              />
              {canSeeMapping && !!model.id && (
                <Tab label="CONNECTWISE MANAGE" value={"3"} />
              )}
            </Tabs>
            <TabPanel value={"1"} className={tabDialogClasses.tabPanel}>
              {creationStep === creationStepVal.details && (
                <>
                  {errorMessage && (
                    <Alert
                      className={tabDialogClasses.alert}
                      severity={"error"}
                    >
                      {errorMessage}
                    </Alert>
                  )}
                  {(model instanceof Org || model instanceof User) && (
                    <SimpleModelForm
                      model={model}
                      onValidate={onValidate}
                      fields={getFields(context)}
                      layout={layoutObj[context]}
                    />
                  )}
                  <FormControl className={tabDialogClasses.formControl}>
                    {context === "account" ? (
                      // 'account' license editions radio buttons
                      <>
                        <FormLabel className={tabDialogClasses.formLabel}>
                          Select edition for this account
                        </FormLabel>
                        <RadioGroup
                          defaultValue={
                            model?.config?.license
                              ? model.config.license
                              : "FREE"
                          }
                          onChange={(e) => {
                            e.stopPropagation();
                            setHasLicenseChanged(true);
                            props.handleChange(
                              e.target.value,
                              "config.license"
                            );
                          }}
                        >
                          {getMSPLicenseListToRender({
                            licenseListToFilter: LICENSE_TYPES,
                            license: license,
                          })
                            .reverse()
                            .map((license) => (
                              <span key={license.value}>
                                <FormControlLabel
                                  value={license.value} // ie: SIEM_PRO
                                  control={<Radio />}
                                  label={`${license.label} edition`} // ie: SIEM Pro
                                  className={tabDialogClasses.formControlLabel}
                                />
                                {props.licenseValueToDisplayAgentCountHelperText ===
                                  license.value && (
                                  <div
                                    className={
                                      tabDialogClasses.agentSeatCountTextContainer
                                    }
                                  >
                                    <p
                                      className={
                                        tabDialogClasses.agentSeatPrimaryText
                                      }
                                    >
                                      Maximum Deployable Agents:{" "}
                                      {maximumDeployableAgents}
                                    </p>
                                    {doesAnyChildOrgHaveLegacyLicense ? (
                                      <p
                                        className={
                                          tabDialogClasses.agentSeatSecondaryText
                                        }
                                      >
                                        Having a limit ensures you will not be
                                        billed for more agents than expected.
                                        This limit can be increased later under
                                        Blumira Agent {">"} Installation.{" "}
                                        <a
                                          href={
                                            "https://blumira.help/msppricing"
                                          }
                                          rel={"noopener noreferrer"}
                                          target={"_blank"}
                                        >
                                          Learn More <OpenInNewIcon />
                                        </a>
                                      </p>
                                    ) : (
                                      <p
                                        className={
                                          tabDialogClasses.agentSeatSecondaryText
                                        }
                                      >
                                        The agent limit is 200% of the User
                                        count. If you need more agents, please
                                        increase the user count.
                                        <a
                                          href={
                                            "https://blumira.help/msppricing"
                                          }
                                          rel={"noopener noreferrer"}
                                          target={"_blank"}
                                        >
                                          Learn More <OpenInNewIcon />
                                        </a>
                                      </p>
                                    )}
                                  </div>
                                )}
                              </span>
                            ))}
                        </RadioGroup>
                        {props.shouldDisplayDataRetentionMessage && (
                          <p
                            className={
                              tabDialogClasses.dataRetentionWarningText
                            }
                          >
                            Your edition selection will move you to a shorter
                            data retention period. Any logging data outside of
                            your new retention period will be immediately
                            purged. If you have any questions about this, please
                            email{" "}
                            <a
                              target={"_blank"}
                              href={MSPEmailUrl}
                              title={MSPEmailUrl}
                              rel={"noopener noreferrer"}
                            >
                              msp@blumira.com
                            </a>{" "}
                            before saving your changes.
                          </p>
                        )}
                      </>
                    ) : (
                      // 'user' roles checkboxes
                      <>
                        <FormLabel className={tabDialogClasses.formLabel}>
                          Select role(s) for this user
                        </FormLabel>
                        <FormGroup>
                          {Object.keys(roleTypes).map((role) => (
                            <FormControlLabel
                              key={role}
                              label={role}
                              control={<Checkbox />}
                              value={roleTypes[role]}
                              className={tabDialogClasses.formControlLabel}
                              checked={isCheckboxChecked(
                                roleTypes[role],
                                rolesBoxesChecked
                              )}
                              onChange={(e) => {
                                e.stopPropagation();
                                props.handleChange(
                                  parseInt(e.target.value),
                                  "roleSelection"
                                );
                              }}
                            />
                          ))}
                        </FormGroup>
                      </>
                    )}
                  </FormControl>
                </>
              )}
              {creationStep === creationStepVal.psaMapping && (
                <MappingComponent
                  tabDialogClasses={tabDialogClasses}
                  accountName={accountName}
                  psaCompanyListLoading={psaCompanyListLoading}
                  model={model}
                  setNewMapping={setNewMapping}
                  psaCompanyList={psaCompanyList}
                  psaIntegrationData={psaIntegrationData}
                  autocompleteKey={autocompleteKey}
                  mappingVal={mappingVal}
                  handleMappingChange={handleMappingChange}
                  mappingMethodTypeDisplay={mappingMethodTypeDisplay}
                  psaConnectionError={psaConnectionError}
                  psaHelperMessage={psaHelperMessage}
                />
              )}
              {creationStep === creationStepVal.access && (
                <div>
                  <p className={tabDialogClasses.subHeaderText}>
                    {context === "account"
                      ? "Select users that can access this account"
                      : "Select accounts that this user can access"}
                  </p>
                  {errorMessage && (
                    <Alert
                      className={tabDialogClasses.alert}
                      severity={"error"}
                    >
                      {errorMessage}
                    </Alert>
                  )}
                  <div className={tabDialogClasses.tableContainer}>
                    <SimpleTable
                      data={tableData}
                      hasMultiSelect={true}
                      columns={getColumns(context, model)}
                      selectedTableData={selectedTableData}
                      handleSelectTableItem={handleSelectTableItem}
                      disabledTableData={disabledTableData}
                    />
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel value={"2"} className={tabDialogClasses.tabPanel}>
              {model.id ? (
                <div>
                  <p className={tabDialogClasses.subHeaderText}>
                    {context === "account" &&
                      "Select users that can access this account"}
                  </p>
                  <div className={tabDialogClasses.tableContainer}>
                    <SimpleTable
                      data={tableData}
                      pagination={false}
                      hasMultiSelect={true}
                      columns={getColumns(context, model)}
                      handleSelectTableItem={selectTableItem}
                      selectedTableData={getSelectedTableData()}
                      disabledTableData={disabledTableData}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {user.superadmin === false && (
                    <div className={tabDialogClasses.multipleCSV}>
                      <List>
                        <ListItem>
                          1.&nbsp;
                          <a
                            href={
                              context === "user"
                                ? "https://dl.blumira.com/Blumira-User-Upload-Template.csv"
                                : "https://dl.blumira.com/Blumira-Account-Upload-Template.csv"
                            }
                          >
                            Download CSV template
                          </a>
                          &nbsp;and enter account information
                        </ListItem>
                        <ListItem>
                          2. Email completed CSV to&nbsp;
                          <a href="mailto:MSP@blumira.com">MSP@blumira.com</a>
                        </ListItem>
                      </List>
                      <Typography variant="caption">
                        Note - Please allow up to 1 business day for the Blumira
                        MSP team to either complete your request or reach out
                        for clarification
                      </Typography>
                    </div>
                  )}
                  {user.superadmin === true &&
                    creationStep === creationStepVal.details && (
                      <div className={tabDialogClasses.multipleCSV}>
                        <DropzoneArea
                          filesLimit={1}
                          showFileNames={true}
                          showAlerts={["error"]}
                          onChange={dropzoneFile}
                          acceptedFiles={["text/csv"]}
                          dropzoneText={
                            <span>
                              Drag and drop CSV file here,
                              <br />
                              or click here to select a file from your computer.
                            </span>
                          }
                        />
                        <div className={tabDialogClasses.csvTemplate}>
                          <a
                            href={
                              context === "user"
                                ? "https://dl.blumira.com/Blumira-User-Upload-Template.csv"
                                : "https://dl.blumira.com/Blumira-Account-Upload-Template.csv"
                            }
                          >
                            <GetAppIcon /> Download CSV Template
                          </a>
                        </div>
                      </div>
                    )}
                  {user.superadmin === true &&
                    creationStep === creationStepVal.access && (
                      <>
                        {errorMessage && (
                          <Alert
                            className={tabDialogClasses.alert}
                            severity={"error"}
                          >
                            {errorMessage}
                          </Alert>
                        )}
                        <div className={tabDialogClasses.tableContainer}>
                          <SimpleTable
                            data={parsedCSVData}
                            isFetching={isLoading}
                            columns={multipleCSVcolumnsObj[context]}
                            emptyText={
                              <span>
                                You provided invalid (or no) data.
                                <br />
                                <br />
                                Be sure to follow the CSV template provided in
                                the previous step and enter valid information
                                into the file.
                                <br />
                                <br />
                                Please go back to the previous step and try
                                again.
                              </span>
                            }
                          />
                        </div>
                        {isAllRowsProcessed && (
                          <p>All rows have been processed</p>
                        )}
                      </>
                    )}
                </>
              )}
            </TabPanel>
            {canSeeMapping && !!model.id && (
              <TabPanel value={"3"} className={tabDialogClasses.tabPanel}>
                <MappingComponent
                  tabDialogClasses={tabDialogClasses}
                  accountName={accountName}
                  psaCompanyListLoading={psaCompanyListLoading}
                  model={model}
                  setNewMapping={setNewMapping}
                  psaCompanyList={psaCompanyList}
                  psaIntegrationData={psaIntegrationData}
                  autocompleteKey={autocompleteKey}
                  mappingVal={mappingVal}
                  handleMappingChange={handleMappingChange}
                  mappingMethodTypeDisplay={mappingMethodTypeDisplay}
                  psaConnectionError={psaConnectionError}
                  psaHelperMessage={psaHelperMessage}
                />
              </TabPanel>
            )}
          </TabContext>
        </DialogContent>
        {displayDialogActions(showDialogActions)}
      </StyledTabsDialog>
      <StyledConfirmationDialog
        open={isConfirmationDialogOpen}
        classes={{ paper: confirmDialogClasses.innerDialogPaper }}
      >
        <DialogContent>
          Changing the “User count” and/or the Blumira “edition” impacts monthly
          billing. By clicking "Confirm" you acknowledge and accept that billing
          will be updated for this account. Refer to{" "}
          <a
            target={"_blank"}
            rel={"noopener noreferrer"}
            href={MSPPricingAndBillingUrl}
            title={MSPPricingAndBillingUrl}
          >
            MSP pricing and billing
          </a>{" "}
          for more information or email{" "}
          <a
            target={"_blank"}
            href={MSPEmailUrl}
            title={MSPEmailUrl}
            rel={"noopener noreferrer"}
          >
            msp@blumira.com
          </a>{" "}
          for questions.
        </DialogContent>
        <DialogActions>
          <div
            className={`${confirmDialogClasses.dialogActionsColumn} ${confirmDialogClasses.dialogActionsLastColumn}`}
          >
            <div
              className={confirmDialogClasses.dialogActionsRightColumnWrapper}
            >
              <Button
                variant={"text"}
                onClick={toggleConfirmationDialog}
                color={"primary"}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                variant={"contained"}
                color={"primary"}
                disabled={!!isSaving}
              >
                Confirm
              </Button>
            </div>
          </div>
        </DialogActions>
      </StyledConfirmationDialog>
    </div>
  );
};

MSPDetailDialog.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  context: PropTypes.string,
  isSaving: PropTypes.bool,
  handleSubmit: PropTypes.func,
  showConfirmation: PropTypes.bool,
  selectedTableData: PropTypes.array,
  handleSelectTableItem: PropTypes.func,
  radioGroupSelection: PropTypes.array,
  childOrganizationsList: PropTypes.arrayOf(PropTypes.shape({})),
  orgType: PropTypes.string,
  rolesBoxesChecked: PropTypes.array,
};

MSPDetailDialog.defaultProps = {
  model: {},
  open: false,
  isSaving: false,
  context: "account",
  selectedTableData: [],
  showConfirmation: false,
  radioGroupSelection: [],
  childOrganizationsList: [],
  orgType: {},
  rolesBoxesChecked: [],
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const { orgId: mspOrgId } = location.payload;
  return {
    childOrganizationsList: session.settings.userOrgs.filter(
      (obj) => obj.parentId === mspOrgId
    ),
    orgId: mspOrgId,
    user: session.settings.user,
    isSuperadmin: session?.settings?.user?.superadmin,
    license: state.license,
    orgType: session.settings.userOrgs.find((obj) => obj.id === mspOrgId)
      .orgType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewUser: (orgId, data) => dispatch(createUser({ orgId, data })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MSPDetailDialog);
