export const freeProductPlacholder = {
  name: "Free",
  bannerText: "Blumira's Free Edition connects up to three Cloud Connectors.",
  productId: null,
  priceId: null,
  listItems: [
    { text: "Up to 3 Cloud Connectors" },
    { text: "Unlimited users and data ingestion" },
    { text: "1 week data retention" },
    { text: "Detections & response" },
    { text: "Basic reporting" },
    { text: "Summary dashboard" },
  ],
};

export const productKeys = ["Microsoft 365", "Cloud", "Advanced"];

const freeSiemPlanJson = {
  name: "Free SIEM",
  price: {
    text: "$0",
  },
  description: "No commitment",
  licenseValue: "FREE",
  listItems: [
    {
      bulletPoints: [{ text: "14 days of data retention" }],
    },
    {
      sectionTitle: "Data Collection",
      bulletPoints: [{ text: "Up to 3 cloud connectors" }],
    },
    {
      sectionTitle: "Features",
      bulletPoints: [
        { text: "Response Playbooks" },
        { text: "Dashboard Summary" },
        { text: "Basic Saved Reports" },
        { text: "Basic Compliance Reports" },
        {
          text: "Report Builder",
          tooltipPendoId: "billing-detail-report-builder",
          tooltip:
            "Choose from a list of your integrations to populate reports on what's going on across your environment, customizable by date range (like the past 24 hours, 7 days, and more).",
        },
        { text: "Email notifications" },
      ],
    },
  ],
};

const siemStarterCompliancePlanJson = {
  name: "SIEM Starter + Compliance",
  stripeConfig: {
    purchaseButtonText: "Buy SIEM Starter + Compliance",
  },
  price: {
    text: "+$4 per seat",
    tooltipPendoId: "billing-price-siem-starter-compliance",
    tooltip:
      "For SIEM Starter and SIEM Starter + Compliance each seat equals one device with Blumira Agent installed, which sends logs to Blumira.",
  },
  description:
    "Achieve regulatory compliance effortlessly by truly meeting the required standards for 365-day data retention and beyond, encompassing:",
  licenseValue: "SIEM_STARTER_COMPLIANCE",
  listItems: [
    {
      bulletPoints: [
        { text: "365-day Data Retention" },
        { text: "No data collection limit" },
        {
          text: "Executive Summaries",
          tooltipPendoId: "billing-detail-executive-summaries",
          tooltip:
            "Get monthly and quarterly snapshots of your security delivered directly to your email inbox.",
        },
      ],
    },
    {
      sectionTitle: "Additional Reports",
      bulletPoints: [
        { text: "CIS Control Compliance Rpts" },
        { text: "CMMC Compliance Rpts" },
        { text: "ISO 27001 Compliance Rpts" },
      ],
    },
  ],
};

const siemStarterPlanJson = {
  name: "SIEM Starter",
  stripeConfig: {
    purchaseButtonText: "Buy SIEM Starter",
  },
  price: {
    text: "$15 per seat",
    tooltipPendoId: "billing-price-siem-starter",
    tooltip:
      "For SIEM Starter and SIEM Starter + Compliance each seat equals one device with Blumira Agent installed, which sends logs to Blumira.",
  },
  description: "Monthly billing",
  licenseValue: "SIEM_STARTER",
  bannerText: "Everything in Free SIEM and:",
  listItems: [
    {
      sectionTitle: "Data Collection",
      bulletPoints: [
        {
          text: "Up to 50 Blumira Agents",
          tooltipPendoId: "billing-detail-50-blumira-agents",
          tooltip:
            "Add up to 50 maximum agents. There is a minimum of 10 agents/seats.",
        },
        { text: "No Cloud Connector limit" },
      ],
    },
    {
      sectionTitle: "Features",
      bulletPoints: [
        { text: "Detection filters" },
        { text: "Manual Dynamic Blocklists" },
        { text: "Manual Host Isolation for Agent" },
        { text: "Advanced Dashboards" },
        { text: "Advanced Saved Reports" },
        { text: " Advanced Compliance Reports" },
        { text: "Optional Executive Summaries *" },
        { text: "Text and phone call notifications" },
        { text: "$250 white glove onboarding" },
        { text: "Ongoing Support (9am - 8pm ET)" },
      ],
    },
    { text: "Standard 90-day data retention (Optional 365 days *)" },
    { text: "Monthly billing" },
  ],
  optionalUpgradeLicense: siemStarterCompliancePlanJson,
};

const siemEndpointPlanJson = {
  contactSales: true,
  name: "SIEM Plus",
  price: {
    text: "$20 per seat",
    tooltipPendoId: "billing-price-siem-plus",
  },
  description: "Annual billing",
  licenseValue: "SIEM_ENDPOINT",
  bannerText: "Everything in SIEM Starter + Compliance and:",
  listItems: [
    {
      sectionTitle: "Data Collection",
      bulletPoints: [
        {
          text: "No Blumira Agent limit",
          tooltipPendoId: "billing-detail-no-blumira-agent",
          tooltip:
            "Proactively detect and respond to endpoint security threats with Blumira Agent.",
        },
        {
          text: "On-premises Logging",
          tooltipPendoId: "billing-detail-on-premises-logging",
        },
      ],
    },
    {
      sectionTitle: "Features",
      bulletPoints: [
        {
          text: "Blumira Investigate",
          tooltipPendoId: "billing-detail-blumira-investigate",
          tooltip:
            "Blumira Investigate, provides a holistic view of security events to accelerate investigations. Blumira Investigate enables IT teams to quickly identify patterns, anomalies, and potential threats, ultimately accelerating incident response times.",
        },
        {
          text: "Honeypots",
          tooltipPendoId: "billing-detail-honeypots",
          tooltip:
            "Deception technology that identifies unauthorized access attempts and lateral movement, alerts you and helps you respond.",
        },
        { text: "$500 white glove onboarding" },
        {
          text: "Emergency After Hours Support (24/7 for Critical Priority issues)",
        },
        { text: "External Threat Surface Scans (Biannually)" },
        { text: "Dedicated CSM + Recurring Syncs (Quarterly)" },
      ],
    },
  ],
};

const xdrPlatformPlanJson = {
  contactSales: true,
  name: "XDR Platform",
  price: {
    text: "$25 per seat",
    tooltipPendoId: "billing-price-xdr-platform",
  },
  description: "Annual billing",
  licenseValue: "XDR",
  bannerText: "Everything in SIEM Plus and...",
  listItems: [
    {
      sectionTitle: "Features",
      bulletPoints: [
        {
          text: "Long term retention options",
          tooltipPendoId: "billing-detail-long-term-retention",
          tooltip:
            "More than 1 year is available to satisfy compliance & cyber insurance.",
        },
        { text: "Automated Dynamic Blocklists" },
        { text: "Automated Host Isolation for Agent" },
        { text: "No cost white glove onboarding" },
      ],
    },
  ],
};

export const availablePlans = [
  freeSiemPlanJson,
  siemStarterPlanJson,
  siemEndpointPlanJson,
  xdrPlatformPlanJson,
];
