import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";

import SimpleTable from "views/Components/SimpleTable";
import { MSPIntegrationLogo } from "./MSPIntegrationLogo";
import MSPIntegrationDialog from "../../Components/MSP/MSPIntegrationsDialog";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";

import moment from "moment-timezone";
import { loadPageData } from "../../../redux/actions/Request";
import { ConnectwiseAPI } from "lib/api/ConnectwiseAPI";

import { getChildOrgs } from "views/Pages/MSPPortal/utils";
import _ from "lodash";
import Codex from "@blumira/blu_constants";
import { ConnectionStatusIcon } from "./styles";

const PREFIX = "MSPIntegrationsPageView";
const classes = {
  statusChip: `${PREFIX}-statusChip`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.statusChip}`]: {
    backgroundColor: "#FBDBB4",
    color: "#A84700",
    fontSize: 14,
    borderRadius: 20,
    padding: "3px 10px",
    width: "fit-content",
    "& svg": {
      fontSize: 14,
    },
  },
}));

const connectwise = new ConnectwiseAPI();

export const MSPIntegrationsPageView = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mspIntegrations, setMSPIntegrations] = useState([]);
  const [isAddIntegrationModalOpen, setIsAddIntegrationModalOpen] =
    useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [serviceBoards, setServiceBoards] = useState([]);

  const getServiceBoardsList = async () => {
    await connectwise
      .getServiceBoards(props.orgId)
      .catch((e) => console.warn(e))
      .then((val) => {
        if (val) {
          setServiceBoards(val.data.boards);
        }
      });
  };

  useEffect(() => {
    if (props.ready) {
      setMSPIntegrations(props.mspIntegrations);
      setIsLoading(false);

      if (props.currentOrg && props.mspIntegrations?.length > 0) {
        getServiceBoardsList();
      }
    }
  }, [props.ready, props.currentOrg, props.mspIntegrations]);

  const renderLogoCell = (column, model) => {
    return (
      <MSPIntegrationLogo
        integrationType={model[column.field]}
        width="98"
        height="45"
      />
    );
  };

  const renderStatusChipCell = (column, model) => {
    if (!model.enabled)
      return (
        <div className={classes.statusChip}>
          <WarningIcon /> Setup not complete
        </div>
      );
  };

  const renderDateCell = (column, model) => {
    const timeValue = model[column.field];
    if (!timeValue) {
      return "Pending";
    }
    return moment.utc(timeValue).tz(moment.tz.guess(true)).format("lll z");
  };

  const isConnected = (connectionStatus) => connectionStatus === "OK";

  const renderConnectionStatus = (column, model) => {
    const connectionStatus = model[column.field];
    if (!connectionStatus) {
      return "";
    }

    let connectionMessage = "Offline";

    if (connectionStatus && /401/.test(connectionStatus)) {
      connectionMessage = "Invalid Credentials";
    } else if (connectionStatus && /403/.test(connectionStatus)) {
      connectionMessage = "Permission Error";
    }

    return isConnected(connectionStatus) ? "Online" : connectionMessage;
  };

  const columns = [
    {
      field: "psaType",
      renderValue: renderLogoCell,
      searchable: false,
    },
    {
      title: "Name",
      field: "psaType",
      searchable: true,
    },
    {
      title: "Type",
      searchable: true,
      // This is a static value instead of a field from the model because it will be the same for all PSAs no matter their type
      renderValue: () => "PSA Ticketing",
    },
    {
      title: "Findings Flow",
      field: "enabled",
      searchable: false,
      renderValue: (column, model) =>
        model[column.field] === true ? "Enabled" : "Disabled",
    },
    {
      title: "Status",
      field: "connectionStatus",
      searchable: true,
      renderValue: renderConnectionStatus,
      icon: function ConnectionStatus(column, model) {
        const connectionStatus = model[column.field];
        return (
          <ConnectionStatusIcon
            className={
              isConnected(connectionStatus) ? "connected" : "disconnected"
            }
          />
        );
      },
    },
    {
      title: "Last API Connection",
      field: "lastConnection",
      searchable: true,
      renderValue: renderDateCell,
    },
    {
      field: "enabled",
      renderValue: renderStatusChipCell,
      searchable: false,
    },
  ];

  const toggleMSPIntegrationDialog = () => {
    setIsAddIntegrationModalOpen(!isAddIntegrationModalOpen);
  };

  const handleRowClick = (model) => {
    setSelectedModel(model);
    toggleMSPIntegrationDialog();
  };

  const addAnMSPIntegration = () => {
    setSelectedModel(null);
    toggleMSPIntegrationDialog();
  };

  const isAddIntegrationDisabled = () => {
    if (props.mspIntegrations?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Root>
      <SimpleTable
        isNorthStar
        isFetching={isLoading}
        data={mspIntegrations}
        emptyText={<span>No MSP Integrations added yet</span>}
        columns={columns}
        rowClick={handleRowClick}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Add MSP Integration",
            onClick: addAnMSPIntegration,
            disabled: isAddIntegrationDisabled(),
          },
        ]}
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit MSP Integration",
            onClick: (event, model) => {
              handleRowClick(model);
            },
          },
        ]}
      />
      <MSPIntegrationDialog
        open={isAddIntegrationModalOpen}
        toggleModal={toggleMSPIntegrationDialog}
        editingModel={selectedModel}
        reload={(bool) => props.reload(bool)}
        serviceBoards={serviceBoards}
        blumiraOrganizationsList={props.blumiraOrganizationsList}
        findingTypes={props.findingTypes}
      />
    </Root>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
  };
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const orgId = location.payload.orgId;
  const currentOrg = session.settings.userOrgs.find(({ id }) => id === orgId);

  const orgListToFilter = _.get(session, `settings.userOrgs`, []);

  // getChildOrgs expects a a list of orgs and an
  // org id to filter and returns an array of orgs
  const childOrgsList = getChildOrgs(orgListToFilter, orgId);

  const parentOrg = orgListToFilter.find((o) => o.id === orgId);
  let blumiraOrgList = [parentOrg, ...childOrgsList];

  if (parentOrg.orgType !== "normal") {
    const filteredList = blumiraOrgList.filter(
      (org) => org.id === parentOrg.id
    );
    blumiraOrgList = filteredList;
  }

  return {
    orgId,
    currentOrg,
    blumiraOrganizationsList: blumiraOrgList,
    findingTypes: Codex.language.models.finding.type,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MSPIntegrationsPageView);
