import _ from "lodash";

const accountLayout = [
  { field: "name", xs: 12 },
  { field: "webUrl", xs: 12 },
  { field: "config.userCount", xs: 12 },
];

const userLayout = [
  { field: "firstName", xs: 12 },
  { field: "lastName", xs: 12 },
  { field: "email", xs: 12 },
  { field: "configAlerts.contact_details.text_number", xs: 12 },
  { field: "configAlerts.contact_details.voice_number", xs: 12 },
];

export const layoutObj = {
  user: userLayout,
  account: accountLayout,
};

export const roleTypes = {
  Administrator: 10,
  Responder: 40,
};

export const defaultRole = roleTypes["Responder"];

export const renderConfigCell =
  (aggregatedData = {}, defaultValue = "") =>
  (column, model) => {
    if (
      _.isObject(aggregatedData) &&
      !_.isEmpty(aggregatedData) &&
      model.id in aggregatedData
    ) {
      return aggregatedData[model.id];
    }

    let fieldVal = column.field;
    if (column.field.substr(0, 7) === "config.") {
      fieldVal = column.field.substr(7);
    }

    if (!_.isNil(model.config) && !_.isNil(model.config[fieldVal])) {
      return model["config"][fieldVal];
    }

    return defaultValue;
  };

// accountColumns needs a user model so that we are able to render
// roles that correspond only to current user and current org
const accountColumns = (selectedUserModel) => {
  return [
    {
      title: "Name",
      field: "name",
      searchable: true,
    },
    {
      title: "Roles",
      field: "orgRoles",
      renderValue: (column, model) =>
        getRoles(column, model, selectedUserModel),
    },
    // FYI: commenting 'Users' and 'Edition' out as it has been called out that
    // we might add these columns back in for a later version of the feature but
    // do not want to include it for intitial MSP Portal release
    // {
    //     title: 'Users',
    //     field: 'config.userCount',
    //     renderValue: renderConfigCell(null, 0),
    // },
    // {
    //     title: 'Edition',
    //     field: 'config.license',
    //     renderValue: renderConfigCell(null, ''),
    // },
  ];
};

const getRoles = (column, orgModel, userModel) => {
  // use orgModel and userModel to return roles on user
  // that correspond with the orgModel that is provided
  let roles = [];
  if (userModel && userModel[column.field]) {
    roles = userModel[column.field]
      .filter(({ orgId }) => orgId === orgModel.id)
      .map((r) => {
        switch (r.roleId) {
          case 10:
            return "Administrator";
          case 20:
            return "Manager";
          case 30:
            //The Analyst role was removed as a selectable role a while ago
            //but we need to leave this case here in getRoles
            //so that any old users who may still be in the system as Analysts
            //will still show as such in the Users table
            return "Analyst";
          case 40:
            return "Responder";
          case 50:
            return "Monitor";
          default:
            return "";
        }
      });
  }

  // only return unique roles
  let distinctRoles = [...new Set(roles)];
  return distinctRoles.sort().join(", ");
};

const userColumns = [
  {
    title: "Email address",
    field: "email",
    searchable: true,
  },
  {
    title: "Accounts",
    field: "orgs",
    renderValue: (column, model) => model[column.field].length,
  },
  // FYI: commenting 'Role' out as it has been called out that we might add
  // this column back in for a later version of the feature but do not want
  // to include it for intitial MSP Portal release
  // {
  //     title: 'Role',
  //     field: 'orgRoles',
  //     renderValue: getRoles,
  //     searchable: true,
  // },
];

// this might look backward but a user needs to select what
// account (child org) to build roles for when creating a user,
// and needs a list of users when creating a new account (child org)
export const getColumns = (context, model) => {
  if (context === "user") return accountColumns(model);
  else return userColumns;
};

const multipleUsersColumns = [
  {
    title: "Upload Status",
    field: "status",
  },
  {
    title: "First Name",
    field: "First Name",
    searchable: true,
  },
  {
    title: "Last Name",
    field: "Last Name",
    searchable: true,
  },
  {
    title: "Email address",
    field: "Email",
    searchable: true,
  },
  {
    title: "Phone number for voice alerts",
    field: "Phone number for voice alerts (optional)",
    searchable: true,
  },
  {
    title: "Phone number for text alerts",
    field: "Phone number for text alerts (optional)",
    searchable: true,
  },
];

const multipleAccountsColumns = [
  {
    title: "Upload Status",
    field: "status",
  },
  {
    title: "Account Name",
    field: "Organization Name",
    searchable: true,
  },
  {
    title: "User count",
    field: "User Count",
  },
  {
    title: "Web domain",
    field: "Web Domain",
    searchable: true,
  },
  {
    title: "City",
    field: "City",
    searchable: true,
  },
  {
    title: "State",
    field: "State (two letters)",
    searchable: true,
  },
];

export const multipleCSVcolumnsObj = {
  user: multipleUsersColumns,
  account: multipleAccountsColumns,
};

const multUsersColsNames = [
  // these must match the columns header names in the user csv template
  "First Name",
  "Last Name",
  "Email",
  "Phone number for voice alerts (optional)",
  "Phone number for text alerts (optional)",
];

const multAccountsColsNames = [
  // these must match the columns header names in the account csv template
  "Organization Name",
  "User Count",
  "Web Domain",
  "City",
  "State (two letters)",
];

export const multCSVColsNames = {
  user: multUsersColsNames,
  account: multAccountsColsNames,
};

export const MAPPING_METHODS = {
  companyId: {
    label: "Auto-map Blumira “Account Name” to ConnectWise PSA “Company ID”",
    value: "Default",
  },
  companyName: {
    label: "Auto-map Blumira “Account Name” to ConnectWise PSA “Company Name”",
    value: "Auto",
  },
  manual: {
    label: "Manually map",
    value: "Manual",
  },
};

export const mappingMethod = (companySyncing) =>
  companySyncing === MAPPING_METHODS.companyName.value ? "name" : "identifier";

export const mappingMethodDisplay = (companySyncing) =>
  companySyncing === MAPPING_METHODS.companyName.value ? "Name" : "ID";

export const matchByMappingMethodType = (
  psaCompanyList,
  blumiraAccountName,
  mappingType,
  catchall
) => {
  return (
    psaCompanyList.find(
      (psaCompany) =>
        blumiraAccountName.toLowerCase() ===
        psaCompany[mappingType].toLowerCase()
    ) || catchall
  );
};
