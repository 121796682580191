const evidenceSchema = {
  $id: "/evidence",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    matchId: { type: "string", format: "uuid", readOnly: true },
    evidence: { type: "object" },
    created: { type: "string", format: "date-time", readOnly: true },
  },
  required: ["matchId", "evidence"],
  additionalProperties: false,
};

export default evidenceSchema;
