import { BlockingAPI } from "../../lib/api";
import Request from "../../lib/api/Request";

import { handleApiError } from "../utils/helpers";

export const REQUEST_BLOCKS = "BLOCKING.REQUEST_BLOCKS";
export const RECEIVE_BLOCK = "BLOCKING.RECEIVE_BLOCK";
export const RECEIVE_ORG_BLOCKS = "BLOCKING.RECEIVE_ORG_BLOCKS";
export const RESET_BLOCKS = "BLOCKING.RESET_BLOCKS";

export const RECEIVE_ORG_CONFIG = "BLOCKING.RECEIVE_ORG_CONFIG";
export const RESET_CONFIG = "BLOCKING.RESET_CONFIG";

export const RECEIVE_DEVICES = "BLOCKING.RECEIVE_DEVICES";
export const RESET_DEVICES = "BLOCKING.RESET_DEVICES";

export const RECEIVE_FEED_PROPERTIES = "BLOCKING.RECEIVE_FEED_PROPERTIES";
export const RESET_FEED_PROPERTIES = "BLOCKING.RESET_FEED_PROPERTIES";

const API = {
  blocking: new BlockingAPI(),
};

/*
Helpers
*/

/*
Takes blocking configuration data, and optionally orgId, and
returns data for POSTing or PUTing blocking configuration
in the format expected by FEA (configInputPost or configInputPut).
*/
const configurationInput = ({ orgId, data }) => {
  const { automated, block, community, defaultDuration, devices, threatFeeds } =
    data;

  const blockConfiguration = {
    automated,
    community,
    defaultDuration,
    devices,
    threatFeeds,
  };

  const cleanData = {
    block,
    blockConfiguration,
  };

  if (orgId) {
    cleanData.orgId = orgId;
  }

  return cleanData;
};

/*
Actions
*/

/*
Blocks
*/

const resetBlocks = {
  type: RESET_BLOCKS,
};

const requestOrgBlocks = () => ({
  type: REQUEST_BLOCKS,
});

const receiveOrgBlocks = ({ orgId, blocks }) => ({
  type: RECEIVE_ORG_BLOCKS,
  payload: { orgId, blocks },
});

const receiveBlock = (block) => ({
  type: RECEIVE_BLOCK,
  payload: { block },
});

export const fetchBlock = (blockId) => (dispatch) => {
  const request = new Request("/blocklistentry", [
    { field: "id", value: blockId },
  ]);
  request
    .get()
    .then(({ block }) => {
      dispatch(receiveBlock(block));
    })
    .catch((err) => {
      const message = "Failed to load block";
      dispatch(
        handleApiError({
          message,
          err,
        })
      );
    });
};

export const fetchBlocksForOrg =
  (params = {}) =>
  (dispatch) => {
    const { orgId } = params;

    const request = new Request(
      "/blocklistentry",
      [
        {
          field: "until",
          operator: "eq",
          negate: true,
          value: null,
        },
      ],
      {
        page: { count: 20000 },
      }
    );
    dispatch(requestOrgBlocks());
    request
      .get()
      .then((blocks) => dispatch(receiveOrgBlocks({ orgId, blocks })))
      .catch((err) => {
        const message = "Failed to load blocks";
        dispatch(handleApiError({ message, err }));
      });
  };

export const createBlock = (block) => (dispatch) => {
  block
    .create()
    .then(({ createBlock: block }) => dispatch(receiveBlock(block)))
    .catch((err) => {
      const message = "Unable to create block.";
      dispatch(handleApiError({ message, err }));
    });
};

export const updateBlock = (block) => (dispatch) => {
  block
    .update()
    .then(({ updateBlock: block }) => dispatch(receiveBlock(block)))
    .catch((err) => {
      const message = "Unable to update block.";
      dispatch(handleApiError({ message, err }));
    });
};

export const deleteBlock = (block) => (dispatch) => {
  block.delete().catch((err) => {
    const message = "Unable to delete block.";
    dispatch(handleApiError({ message, err }));
  });
};

/*
Blocking Configuration
*/

const resetConfig = {
  type: RESET_CONFIG,
};

export const receiveConfig = ({ orgId, config }) => ({
  type: RECEIVE_ORG_CONFIG,
  payload: { orgId, config },
});

export const fetchConfigForOrg = (orgId) => (dispatch) => {
  const request = new Request("/blocklist");
  request
    .get()
    .then((blocklist) => {
      dispatch(receiveConfig({ orgId, config: blocklist[0] }));
    })
    .catch((err) => {
      const message = "Failed to load config";
      dispatch(handleApiError({ message, err }));
    });
};

export const createConfig =
  ({ data, orgId }) =>
  (dispatch) => {
    if (!orgId) {
      return;
    }

    const cleanData = configurationInput({ orgId, data });

    return API.blocking
      .createConfig(cleanData)
      .then(({ createConfig: config }) =>
        dispatch(receiveConfig({ orgId: config.orgId, config }))
      )
      .catch((err) => {
        // Try to fetch the config in case it has been created from elsewhere
        dispatch(fetchConfigForOrg(orgId));

        const message = "Unable to save blocking configuration.";
        dispatch(handleApiError({ message, err }));
      });
  };

export const updateConfig =
  ({ configId, data, orgId }) =>
  (dispatch) => {
    if (!orgId) {
      return;
    }

    const cleanData = configurationInput({ data });

    return API.blocking
      .updateConfig({ configId, data: cleanData })
      .then(({ updateConfig: config }) =>
        dispatch(receiveConfig({ orgId, config }))
      )
      .catch((err) => {
        const message = "Unable to save blocking configuration.";
        dispatch(handleApiError({ message, err }));
      });
  };

export const deleteConfig =
  ({ configId, orgId }) =>
  (dispatch) =>
    API.blocking
      .deleteConfig(configId)
      .then(() => dispatch(fetchConfigForOrg(orgId)))
      .catch((err) => {
        const message = "Unable to delete blocking configuration.";
        dispatch(handleApiError({ message, err }));
      });

/*
Block Devices
*/

export const resetBlockDevices = {
  type: RESET_DEVICES,
};

export const receiveBlockDevices = (devices) => ({
  type: RECEIVE_DEVICES,
  payload: { devices },
});

export const fetchBlockDevices = () => (dispatch) =>
  API.blocking
    .getBlockDevices()
    .then(({ blockDevices }) => dispatch(receiveBlockDevices(blockDevices)))
    .catch((err) => {
      const message = "Unable to fetch block devices.";
      dispatch(handleApiError({ message, err }));
    });

/*
Feed Properties
*/

const resetFeedProperties = {
  type: RESET_FEED_PROPERTIES,
};

export const receiveFeedProperties = (feedProperties) => ({
  type: RECEIVE_FEED_PROPERTIES,
  payload: { feedProperties },
});

export const fetchFeedProperties = () => (dispatch) =>
  API.blocking
    .getFeedProperties()
    .then(({ feedProperties }) =>
      dispatch(receiveFeedProperties(feedProperties))
    )
    .catch((err) => {
      const message = "Unable to fetch feed properties.";
      dispatch(handleApiError({ message, err }));
    });

/*
Combined actions
*/
export const resetBlocking = () => (dispatch) => {
  dispatch(resetBlocks);
  dispatch(resetBlockDevices);
  dispatch(resetConfig);
  dispatch(resetFeedProperties);
};
