export const MSPEmailUrl = "mailto:msp@blumira.com";

export const MSPPricingAndBillingUrl =
  "https://blumirapartners.zendesk.com/hc/en-us/articles/6431530475283";

export const translateColumnMap = {
  user: {
    "First Name": "firstName",
    "Last Name": "lastName",
    Email: "email",
    "Phone number for voice alerts (optional)": "voiceNumber",
    "Phone number for text alerts (optional)": "textNumber",
  },
  account: {
    "Organization Name": "name",
    "User Count": "userCount",
    "Web Domain": "webUrl",
    City: "city",
    "State (two letters)": "state",
  },
};

export const numericTypeValues = ["User Count"];

// modifications on the config include
// user count and license - both of which
// have an impact on customer billing
export const billingAffectFields = ["config"];

export const newLicenseDefs = ["XDR", "SIEM_PRO", "SIEM_ENDPOINT"];
export const legacyLicenseDefs = ["CLOUD", "ADVANCED"]; // excluding M365 so all MSP NFRs can offer the license
export const licensesToExclude = ["SIEM_STARTER", "SIEM_STARTER_COMPLIANCE"]; // MSP orgs should not have access to new PLP plans

export const WIZARD_STEPS_DEFAULT = {
  details: 0,
  access: 1,
};

export const WIZARD_STEPS_WITH_MAPPING = {
  details: 0,
  psaMapping: 1,
  access: 2,
};
