const lcFailedInstallSchema = {
  $id: "/lcfailedinstall",
  title: "LC Failed Install",
  type: "object",
  properties: {
    hostname: { type: "string", readOnly: true },
    keyname: { type: "string", readOnly: true },
  },
};

export default lcFailedInstallSchema;
