import React from "react";
import { connect } from "react-redux";
import FindingsDetailView from "./FindingsDetailView";
import FindingsListView from "./FindingsListView";
import Loading from "views/Components/Loading";
import { filterSampleFindings } from "lib/util/FindingUtils";

const FindingsView = (props) => {
  if (props.ready) {
    if (props.isDetailsPage) {
      if (props.finding) {
        return <FindingsDetailView {...props} finding={props.finding[0]} />;
      } else {
        return <Loading />;
      }
    } else {
      const findings = filterSampleFindings(
        props.findings,
        props.license,
        props.integrations
      );
      return <FindingsListView {...props} findings={findings} />;
    }
  }
  return <Loading />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    license: state.license,
    isDetailsPage: !!state.location.payload.id1,
  };
};

export default connect(mapStateToProps)(FindingsView);
