import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Link from "redux-first-router-link";
import moment, { utc, tz } from "moment-timezone";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";

import { copyTextToClipboard } from "utils";

import { Typography, AccordionSummary, AccordionDetails } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { StyledAccordion, classes } from "./fullWidthStyles";

import SimpleTable from "../SimpleTable";
import { entityFields } from "../../Pages/SimpleSearch/defs";
import { navToInvestigation } from "../../Pages/SimpleSearch/helpers";

import { SUMMARY_TO_BQ_FIELD_MAP } from "lib/models/BQSummary";

import "./Detail.sass";

const Evidence = ({ evidenceRows, orgId, license, superadmin }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [evidenceTimeFrame, setEvidenceTimeFrame] = useState({});

  useEffect(() => {
    // if no data provided, no need to update
    let minTimeMatched = "ZZ";
    let maxTimeMatched = "";
    if (evidenceRows.length) {
      const formattedData = formatData(evidenceRows);
      setData(formattedData);
      const uniqueKeys = {};
      formattedData.forEach((row) => {
        Object.keys(row).forEach((k) => {
          uniqueKeys[k] = true;
        });
        if (row.timeMatched > maxTimeMatched) {
          maxTimeMatched = row.timeMatched;
        }
        if (row.timeMatched < minTimeMatched) {
          minTimeMatched = row.timeMatched;
        }
      });
      const formattedColumns = formatColumns(Object.keys(uniqueKeys).sort());
      setColumns(formattedColumns);
    }
    if (maxTimeMatched !== "") {
      const timeframeStart =
        moment(minTimeMatched).add(-5, "days").utc().unix() * 1000;

      const currentTime = moment().utc();
      const proposedEnd = moment(maxTimeMatched).add(5, "days").utc();
      // If adding +5 days to the evidence time gives us a future value, then end the search at the current time
      const timeframeEnd = proposedEnd.isAfter(currentTime)
        ? currentTime.unix() * 1000
        : proposedEnd.unix() * 1000;

      setEvidenceTimeFrame({ start: timeframeStart, end: timeframeEnd });
    }
  }, [evidenceRows]);

  // format data to how our table likes it
  // happy table = happy life

  const formatColumns = (uniqueKeys) => {
    const columns = uniqueKeys
      .filter((uniqueKey) => uniqueKey !== "timeMatched")
      .map((uniqueKey) => {
        return { title: uniqueKey, field: `${uniqueKey}`, searchable: true };
      });
    return [{ title: "Time Matched", field: "timeMatched" }, ...columns];
  };

  const isObject = (value) => {
    return typeof value === "object";
  };

  const formatValue = (value) => {
    if (isObject(value)) {
      if (Array.isArray(value)) return value.join(", ");
      return JSON.stringify(value, null, 2);
    }
    return value;
  };

  const formatIndividualRow = (row, index) => {
    // id is for search capability
    let rowToReturn = { id: `${index}`, timeMatched: row.created };
    Object.entries(row.evidence).forEach(
      (el) => (rowToReturn[el[0]] = formatValue(el[1]))
    );
    return rowToReturn;
  };

  const formatData = (evidenceRows) =>
    evidenceRows.map((row, i) => formatIndividualRow(row, i));

  const getContextMenus = () => {
    const menus = [
      {
        onClick: handleContextMenuCopyElementText,
        text: "Copy to Clipboard",
      },
    ];
    if (license.isAllowedPage("investigate/search") || superadmin) {
      const allReduced = [];
      Object.keys(entityFields).map((k) => {
        if (SUMMARY_TO_BQ_FIELD_MAP[k]) {
          allReduced.push(
            Object.values(SUMMARY_TO_BQ_FIELD_MAP[k]).reduce(
              (a, v) => (a = a.union(v)),
              new Set([])
            )
          );
        }
      });

      menus.push({
        onClick: ({ model, fieldName }) =>
          navToInvestigation(orgId, {
            search: model[fieldName],
            ...evidenceTimeFrame,
          }),
        limitToFields: [
          ...allReduced.reduce((a, v) => (a = a.union(v)), new Set([])),
        ],
        text: "Investigate in new window",
      });
    }
    return menus;
  };

  return (
    <SimpleTable
      isNorthStar
      data={data}
      columns={columns}
      contextMenuItems={getContextMenus()}
    />
  );
};

Evidence.propTypes = {
  evidence: PropTypes.arrayOf(PropTypes.shape({})),
  superadmin: PropTypes.bool,
};

Evidence.defaultProps = {
  evidence: [],
  superadmin: false,
};

const FindingDetailView = ({
  orgId,
  license,
  superadmin,
  evidenceLimit = 100,
  srcCountry = [],
  summary = [],
  evidenceLoadStatus,
  relatedFindings = [],
  statusModified,
}) => {
  return (
    <StyledAccordion>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<KeyboardArrowDownIcon />}
      >
        <Typography className={classes.accordionTitle}>Details</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionPanel}>
        <div className={classes.findingsDetailContainer}>
          {statusModified && (
            <div className={classes.sectionContainer}>
              <span className={classes.sectionTitle}>Status Modified</span>
              {utc(statusModified).tz(tz.guess(true)).format("lll z")}
            </div>
          )}

          {summary && (
            <div className={classes.sectionContainer}>
              <span className={classes.sectionTitle}>Summary</span>
              {summary}
            </div>
          )}

          {srcCountry.length > 0 && (
            <div className={classes.sectionContainer}>
              <span className={classes.sectionTitle}>Source Countries</span>
              <ul>
                {srcCountry.map((s) => (
                  <li key={s}>{s}</li>
                ))}
              </ul>
            </div>
          )}

          {evidenceLoadStatus.data &&
            evidenceLoadStatus.data.length > evidenceLimit && (
              <Alert severity="info">
                To ensure reliable app performance, only {evidenceLimit} rows of
                the most recent evidence matches are displayed below.
              </Alert>
            )}

          {evidenceLoadStatus.data && evidenceLoadStatus.data.length > 0 && (
            <div className={classes.sectionContainer}>
              <span className={classes.sectionTitle}>Matched Evidence</span>
              <div className="evidence">
                <Evidence
                  evidenceRows={evidenceLoadStatus.data.slice(0, evidenceLimit)}
                  orgId={orgId}
                  license={license}
                  superadmin={superadmin}
                />
              </div>
            </div>
          )}

          {relatedFindings.length > 0 && (
            <div className={classes.sectionContainer}>
              <span className={classes.sectionTitle}>Related Findings</span>
              <div>
                {relatedFindings.map((finding) => (
                  <div key={finding.id} className="relatedFinding">
                    <Link to={finding.linkTo}>{finding.name}</Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
};

FindingDetailView.propTypes = {
  orgId: PropTypes.string.isRequired,
  evidenceLoadStatus: PropTypes.shape({}),
  superadmin: PropTypes.bool,
  srcCountry: PropTypes.arrayOf(PropTypes.string),
  summary: PropTypes.string,
  statusModified: PropTypes.string,
  relatedFindings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      linkTo: PropTypes.shape({}),
    })
  ),
};

FindingDetailView.defaultProps = {
  superadmin: false,
  srcCountry: [],
  summary: "",
  statusModified: "",
  relatedFindings: [],
};

const mapStatetoProps = (state, { status, relatedFindings }) => ({
  orgId: state.location.payload.orgId,
  license: state.license,
  status: state.findings.get("constants").status.fromId(status),
  relatedFindings: state.findings
    .get("findings")
    .filter((i) => relatedFindings.indexOf(i.id) > -1)
    .map((finding) => ({
      name: finding.name,
      id: finding.id,
      linkTo: {
        type: "PAGE",
        payload: {
          orgId: finding.orgId,
          toplevel: "reporting",
          secondlevel: "findings",
          id1: finding.id,
        },
      },
    })),
});

const handleContextMenuCopyElementText = (contextMenu) => {
  copyTextToClipboard(contextMenu.anchorEl.innerText);
};

export default connect(mapStatetoProps)(FindingDetailView);
