import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ReactQuill from "react-quill";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Codex from "@blumira/blu_constants";
import Schema from "lib/schema";
import { useLoader } from "lib/util/LoaderHook";

import {
  Root,
  StyledDialog,
  rootClasses,
  dialogClasses,
} from "./fullWidthStyles";

import { Alert } from "@mui/material";

import {
  Typography,
  Card,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Link,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
  Button as MuiButton,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import {
  Findings as FindingActions,
  Message as MessageActions,
} from "../../../redux/actions";
import { loadPageData } from "redux/actions/Request";

import Workflow from "./Workflow";
import {
  Flags,
  findingType,
  constantsType,
  MESSAGE_ERROR_TEXT,
  shortIdStr,
} from "./common";
import { setOwners } from "../../../redux/actions/Findings";
import FindingMessages from "./Messages";
import FindingDetails from "./Detail";
import { LabelsAndTags, TAG_OBJECT_TYPES } from "../Label/Label";
import ErrorBoundary from "../ErrorBoundary";
import { sanitize, sortByName } from "../../../lib/helpers";
import { pageWithPayloadAndQuery } from "../../../redux/actions/Page";
import FindingSupport from "./FindingSupport";
import FindingsResolutionNotes from "./FindingsResolutionNotes";

import "./FullWidth.sass";
import FindingAttachmentsView from "./Attachments";

import { Message as MessageModel } from "lib/models/Message";

import AllowlistingFilter from "../Allowlisting/Filter";
import Request from "lib/api/Request";
import { AllowlistEntry } from "lib/models/AllowlistEntry";
import _ from "lodash";
import { findingColumnOptions } from "lib/util/FindingUtils";
import ActionDialog from "views/Components/ActionDialog";

// Pulled from
// https://github.com/quilljs/quill/issues/861#issuecomment-239961806
const Block = ReactQuill.Quill.import("blots/block");
Block.tagName = "DIV";
ReactQuill.Quill.register(Block, true);

const editorFormats = [
  "font",
  "bold",
  "italic",
  "underline",
  "blockquote",
  "code-block",
  "list",
  "indent",
];

const editorModules = {
  toolbar: [
    [{ font: [] }, "bold", "italic", "underline", "blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
};

const fieldSchema = Schema.getSchema("/bq").schema.properties;

export const appendTextToModel = (model, attribute, string) => {
  model.set({ [attribute]: `${model[attribute]} ${string}` }, true);
};

export const unshiftTextToModel = (model, attribute, string) => {
  model.set({ [attribute]: `${string} ${model[attribute]}` }, true);
};

const OwnerSelector = ({
  onChange,
  users = [],
  owners = [],
  currentUser,
  isAddingOwner,
}) => {
  // Make the 'self' option bold
  const optionStyles = {
    option: (styles, { data }) => ({
      ...styles,
      color: "black",
      fontWeight: data.fontWeight,
    }),
  };

  const optionFromUser = (user, self = false) => ({
    value: user.id,
    label: `${user.name ? user.name : user.email} ${
      self === true ? " (You)" : ""
    }`,
    name: user.name ? user.name : user.email,
    fontWeight: `${self === true ? "bold" : "normal"}`,
  });

  const loggedinUser = users.find((user) => user.id === currentUser);

  let options = loggedinUser ? [optionFromUser(loggedinUser, true)] : [];

  options = options.concat(
    users
      .filter((user) => user.id !== currentUser)
      .map(optionFromUser)
      .sort(sortByName)
  );

  return (
    <div className="owner-selector">
      <Select
        value={options.filter((i) => owners.indexOf(i.value) > -1)}
        isMulti
        name="colors"
        options={options}
        isLoading={isAddingOwner}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        styles={optionStyles}
      />
    </div>
  );
};

OwnerSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  owners: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentUser: PropTypes.string,
};

OwnerSelector.defaultProps = {
  currentUser: "",
};

const ScrollIndicator = () => <div className="scroll-indicator-h" />;

const Finding = (props) => {
  const {
    onChangeOwner,
    onFieldChange,
    onSaveComment,
    reload,
    constants,
    currentUser,
    superadmin,
    pageRole,
    matches,
    finding: {
      analysis,
      attachments,
      blocked,
      category: categoryId,
      created,
      id,
      isSample,
      messageError,
      name,
      orgId,
      owners,
      pendingAnswer,
      pendingMessage,
      priority,
      relatedFindings,
      shortId,
      type: typeId,
      status,
      statusModified,
      srcCountry = [],
      summary,
      workflows,
    },
    query,
    gotoBlocks,
    fields,
    finding,
    users,
    ready,
    license,
  } = props;

  const [messageModel, setMessageModel] = useState({});
  const [ownersForRole, setOwnersForRole] = useState([]);
  const [isAddingOwner, setIsAddingOwner] = useState(false);
  const [hasWorkflowError, setWorkflowError] = useState(false);
  const [valuesForResponders, setValuesForResponders] = useState([]);
  const [allowlist, setAllowlist] = useState([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [allowListFields, setAllowListFields] = useState([]);
  const [expandAccordion, setExpandAccordion] = useState(false);
  const [allowlistRequestReady, setAllowlistRequestReady] = useState();
  const [orgUsers, setOrgUsers] = useState([]);
  const [showingTextBox, setShowingTextBox] = useState(false);
  const [detectionType, setDetectionType] = useState("");
  const [isRuleDeleted, setIsRuleDeleted] = useState(false);
  const [openConfirmNotifyBlumiraModal, setOpenConfirmNotifyBlumiraModal] =
    useState(false);

  useEffect(() => {
    if (
      Array.isArray(props.rules) &&
      props.rules.length > 0 &&
      Array.isArray(props.conditions) &&
      props.conditions.length > 0
    ) {
      const rule = props.rules.find((r) => r.id === matches[0].ruleId);

      if (!rule) {
        setIsRuleDeleted(true);
      } else {
        setIsRuleDeleted(false);
      }
      const condition = props.conditions.find(
        (c) => c.id === rule?.conditionSet[0].condition
      );
      setDetectionType(condition?.method === 500 ? "Real-Time" : "Windowed");
    }
  }, [ready, props.rules, props.conditions]);

  const evidenceLimit = 100;

  const evidenceLoadStatus = useLoader(
    async () => {
      // Load evidence
      const queryParams = [{ field: "matchId", value: matches[0].id }];
      const resultParams = { page: { count: evidenceLimit + 1 } };
      const request = new Request("/evidence", queryParams, resultParams);
      return await request.get();
    },
    "FullWidth-evidence",
    [matches[0].id]
  );

  useEffect(() => {
    if (evidenceLoadStatus.data && evidenceLoadStatus.data.length > 0) {
      const foundFields = {};
      const _fields = [];

      evidenceLoadStatus.data.forEach((row) => {
        Object.keys(row.evidence).forEach((k) => {
          if (
            fields[k] &&
            !foundFields[k] &&
            fieldSchema[k]?.format !== "date-time"
          ) {
            foundFields[k] = true;
            _fields.push({
              field: k,
              display_name: fields[k].display_name || k,
            });
          }
        });
      });
      setAllowListFields(_fields);
    }
  }, [evidenceLoadStatus]);

  useEffect(() => {
    if (ownersForRole !== owners[`${pageRole}s`]) {
      setIsAddingOwner(false);
    }
    setOwnersForRole(owners[`${pageRole}s`]);
  }, [owners]);

  useEffect(() => {
    if (!!messageModel?.body && attachments.length) toggleTextbox();
  }, [attachments]);

  useEffect(() => {
    // get all of the responders
    setOrgUsers(
      users.filter((user) => user.orgRoles.some((r) => r.roleId === 40))
    );
  }, [users]);

  const getAllowlistFilters = () => {
    const request = new Request("/allowlistentry", [
      { field: "ruleId", value: matches[0].ruleId },
    ]);
    setAllowlistRequestReady(false);
    request.get().then((filters) => {
      setAllowlist(filters);
      setAllowlistRequestReady(true);
    });
  };

  useEffect(() => {
    if (ready && allowlist.length === 0 && matches.length > 0) {
      getAllowlistFilters();
    }
  }, [ready]);

  const handleChangeOwner = (values) => {
    setIsAddingOwner(true);
    onChangeOwner(values).then(() => setIsAddingOwner(false));
    setValuesForResponders(values);
    setOwnersForRole(values ? values.map((o) => o.value) : []);
  };

  const handleAddSelf = () => {
    // value needed for change owner func is user id
    const selfValue = { value: currentUser };

    // if there are existing responders, include
    let newValues = valuesForResponders
      ? [...valuesForResponders, selfValue]
      : [selfValue];

    // call owner change with relevant values
    handleChangeOwner(newValues);
  };

  const toggleTextbox = () => setShowingTextBox(!showingTextBox);

  const newMessageConstants = {
    type: 20,
    orgId: orgId,
    foreignId: id,
    asynchronous: true,
    sender: currentUser,
    foreignType: "FINDING_COMMENT",
  };

  const updatePendingMessage = (value) => {
    messageModel.set({ body: value });
  };

  const handleNewMessage = () => {
    setMessageModel(new MessageModel(newMessageConstants));
    toggleTextbox();
  };

  const statusName =
    status !== undefined &&
    status !== null &&
    constants.status &&
    // This is looking through findingColumnOptions and finding the Status object
    // Then, it's going through those options and finding the one that matches the status prop
    // Then, it returns the label associated with that status prop
    _.flow([
      (obj) => _.find(obj, { field: "status" }),
      (obj) => obj.options,
      (obj) => _.find(obj, { value: status }),
      (obj) => obj.label,
    ])(findingColumnOptions);

  const cancelTextbox = () => {
    onFieldChange({ id, value: false, field: "messageError" });
    onFieldChange({ id, value: "", field: "pendingMessage" });
    toggleTextbox();
  };

  const submitMessage = () => {
    onSaveComment(messageModel);
    toggleTextbox();
  };

  const submitMessageAndNotifyBlumira = () => {
    unshiftTextToModel(messageModel, "body", "(customer requested support)");
    onSaveComment(messageModel);
    toggleTextbox();
    setOpenConfirmNotifyBlumiraModal(false);
  };

  // The same process as above for Status object is also happening here,
  // just with the Type object
  const type = _.flow([
    (obj) => _.find(obj, { field: "type" }),
    (obj) => obj.options,
    (obj) => _.find(obj, { value: typeId }),
    (obj) => obj.label,
  ])(findingColumnOptions);
  // The same process as above for Status and Type objects is also happening here,
  // just with the Category object
  const category = _.flow([
    (obj) => _.find(obj, { field: "category" }),
    (obj) => obj.options,
    (obj) => _.find(obj, { value: categoryId }),
    (obj) => obj.label,
  ])(findingColumnOptions);

  const uncompletedWorkflows = workflows
    .filter((w) => !w.completed)
    .map((workflow, key) => (
      <Workflow
        onSubmit={() => reload("finding")}
        key={key}
        findingId={id}
        finding={finding}
        workflow={workflow}
        pageRole={pageRole}
        pendingAnswer={pendingAnswer}
        onChangeOwner={() => handleAddSelf()}
        userIsOwner={ownersForRole.includes(currentUser)}
        onWorkflowError={() => setWorkflowError(true)}
      />
    ));

  const completedWorkflows = workflows
    .filter((w) => w.completed)
    .map((workflow, key) => (
      <Workflow
        onSubmit={() => reload("finding")}
        pendingAnswer={null}
        key={key}
        findingId={id}
        workflow={workflow}
        pageRole={pageRole}
        userIsOwner={ownersForRole.includes(currentUser)}
        onWorkflowError={() => setWorkflowError(true)}
      />
    ));

  if (hasWorkflowError) {
    return <span />;
  }

  const addNewFilter = (event) => {
    event.stopPropagation();

    if (!expandAccordion) {
      setExpandAccordion(true);
    }

    setAllowlist([
      new AllowlistEntry({
        ruleId: matches[0].ruleId,
        name: "",
        queryParams: [{ field: allowListFields[0].field }],
      }),
      ...allowlist,
    ]);
  };

  const handleOpenInfoModal = (event) => {
    event.stopPropagation();
    setOpenInfoModal(true);
  };

  // TODO ReactQuill could use a wrapper. Modules.toolbar needs to pass through
  // Also, the way dispatch functions are here could be fixed. No need to pass
  // findingId + UserID should be retrieved from store!

  return (
    <Root>
      <ErrorBoundary>
        <div className="full-width-finding">
          <header>
            <div className="header-first-row">
              <div className="name">
                <div className="col1">Detection rule name</div>
                <div className="col2">{name}</div>
              </div>
              <div className="short-id">{shortIdStr(shortId)}</div>
              {blocked && (
                <div className="blocklists-btn-container">
                  <Button
                    onClick={gotoBlocks({ orgId, findingId: id, query })}
                    className="view-blocklists-btn"
                  >
                    View Blocklists
                  </Button>
                </div>
              )}
            </div>

            <div className="detection-type">
              <div className="col1">Detection type</div>
              <div className="col2">{detectionType}</div>
            </div>

            <div className="finding-tags">
              <div className="col1">Tags</div>
              <div className="col2">
                <LabelsAndTags
                  objId={id}
                  objType={TAG_OBJECT_TYPES.FINDING}
                  orgId={orgId}
                  superadmin={superadmin}
                />
              </div>
            </div>
          </header>

          {isSample ? (
            <Alert severity={"info"} className="sampleFindingWarning">
              This is an example finding you can use to learn what Findings look
              like and how to respond to them.
              <br />
              The activity in this sample finding is not real and does not
              reflect data your organization sent to Blumira.
            </Alert>
          ) : null}

          <Paper>
            <div className={rootClasses.contentContainer}>
              <div className={rootClasses.innerContainer}>
                <div className={rootClasses.sectionTitle}>
                  {category ? category : ""}
                </div>

                <div className={rootClasses.sectionContainer}>
                  <Flags
                    priority={priority}
                    typeLabel={type}
                    typeId={typeId}
                    fullWidth
                  />
                </div>

                <div className={rootClasses.sectionContainer}>
                  <span className={rootClasses.sectionTitle}>Date Created</span>
                  {moment
                    .utc(created)
                    .tz(moment.tz.guess(true))
                    .format("lll z")}
                </div>

                {statusName && (
                  <div className={rootClasses.sectionContainer}>
                    <span className={rootClasses.sectionTitle}>Status</span>
                    {statusName}
                  </div>
                )}

                {!blocked && (
                  <div className={rootClasses.sectionContainer}>
                    <span
                      className={rootClasses.sectionTitle}
                    >{`Assigned ${pageRole}s`}</span>
                    <OwnerSelector
                      users={orgUsers}
                      owners={ownersForRole}
                      currentUser={currentUser}
                      onChange={handleChangeOwner}
                      isAddingOwner={isAddingOwner}
                    />
                  </div>
                )}

                <div className={rootClasses.analaysisContainer}>
                  <div className={rootClasses.sectionTitle}>Analysis</div>
                  <div
                    className={rootClasses.analysisTextContainer}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={sanitize(analysis, {
                      html: true,
                      safe: true,
                    })}
                  />
                </div>
              </div>

              {!blocked && (
                <div className={rootClasses.innerContainer}>
                  <div className={rootClasses.sectionTitle}>Workflows</div>
                  <div>{uncompletedWorkflows}</div>
                  <div>{completedWorkflows}</div>
                </div>
              )}
            </div>
          </Paper>

          <Card className={rootClasses.userContentCard}>
            <section id="usercontent">
              <div className="messages">
                <div className="composer">
                  {showingTextBox ? (
                    <div>
                      {messageError && (
                        <div className="message-error">
                          {MESSAGE_ERROR_TEXT}
                        </div>
                      )}

                      <ReactQuill
                        className={rootClasses.quill}
                        formats={editorFormats}
                        modules={editorModules}
                        placeholder=""
                        defaultValue={pendingMessage}
                        onChange={updatePendingMessage}
                        value={messageModel?.body ? messageModel?.body : ""}
                      />
                      <br />
                      <MuiButton
                        onClick={submitMessage}
                        style={{ marginLeft: 8 }}
                        color={"primary"}
                        variant={"contained"}
                      >
                        Add note
                      </MuiButton>
                      {!license.isTagged("limited-to-free") && (
                        <MuiButton
                          onClick={() => setOpenConfirmNotifyBlumiraModal(true)}
                          style={{ marginLeft: 8 }}
                          color={"primary"}
                          variant={"text"}
                        >
                          Add note & send to Blumira support
                        </MuiButton>
                      )}
                      <MuiButton
                        onClick={cancelTextbox}
                        color={"primary"}
                        variant={"text"}
                      >
                        Cancel
                      </MuiButton>
                    </div>
                  ) : (
                    <div className={rootClasses.addNoteWrapper}>
                      <MuiButton
                        onClick={handleNewMessage}
                        color={"primary"}
                        variant={"text"}
                        startIcon={<EditIcon />}
                      >
                        Add note
                      </MuiButton>
                      {!license.isTagged("limited-to-free") && (
                        <span>(Request Blumira support here)</span>
                      )}
                    </div>
                  )}
                </div>
                <FindingMessages findingId={id} users={users} />
              </div>
              <div className="attachments">
                <FindingAttachmentsView
                  findingId={id}
                  orgId={orgId}
                  attachments={attachments}
                  reload={reload}
                />
              </div>
            </section>
          </Card>

          {/*
          Only show allowlisting accordion to a user if they are currently in an org with any license except Free or Cloud
          or if they are a Blumira superadmin.
        */}
          {superadmin ||
          (!license.isTagged("limited-to-free") &&
            !license.isTagged("limited-to-cloud")) ? (
            <Card style={{ marginTop: 20 }} datacy={"allowlistingAccordion"}>
              <Accordion
                expanded={expandAccordion}
                onChange={() => setExpandAccordion(!expandAccordion)}
              >
                <AccordionSummary
                  className={rootClasses.accordionSummary}
                  expandIcon={<KeyboardArrowDownIcon />}
                >
                  <div className={rootClasses.accordionTitleWrap}>
                    <Typography
                      className={rootClasses.accordionTitle}
                    >{`Detection Filters (${
                      ready && allowlistRequestReady
                        ? allowlist.length
                        : "Loading..."
                    })`}</Typography>
                    <IconButton
                      color={"default"}
                      onClick={(event) => handleOpenInfoModal(event)}
                      datacy={"allowlistingModalOpenButton"}
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </div>
                  {isRuleDeleted ? (
                    <Tooltip
                      title={
                        "The rule attached to this finding has been deprecated or deleted by Blumira. Creating a new filter is no longer possible."
                      }
                      placement={"left"}
                      classes={rootClasses.tooltip}
                    >
                      <div>
                        <MuiButton
                          onClick={(event) => addNewFilter(event)}
                          datacy={"allowlistingAddNewFilterBtn"}
                          color={"primary"}
                          variant={"contained"}
                          disabled
                        >
                          Add Filter
                        </MuiButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <MuiButton
                      onClick={(event) => addNewFilter(event)}
                      datacy={"allowlistingAddNewFilterBtn"}
                      color={"primary"}
                      variant={"contained"}
                    >
                      Add Filter
                    </MuiButton>
                  )}
                </AccordionSummary>
                <AccordionDetails className={rootClasses.accordionPanel}>
                  <ScrollIndicator />
                  {allowlist.length
                    ? allowlist.map((model) => (
                        <AllowlistingFilter
                          key={`${model.uuid}-allowlist`}
                          model={model}
                          fields={allowListFields}
                          canEdit={true}
                          getAllowlistFilters={getAllowlistFilters}
                          allowListFields={allowListFields}
                          users={users}
                        />
                      ))
                    : "No Filters"}
                </AccordionDetails>
              </Accordion>
            </Card>
          ) : (
            <Card style={{ marginTop: 20 }}>
              <Accordion
                expanded={expandAccordion}
                onChange={() => setExpandAccordion(!expandAccordion)}
              >
                <AccordionSummary
                  className={rootClasses.accordionSummary}
                  expandIcon={<KeyboardArrowDownIcon />}
                >
                  <div className={rootClasses.accordionTitleWrap}>
                    <Typography className={rootClasses.accordionTitle}>
                      {"Detection Filters"}
                    </Typography>
                    <IconButton
                      color={"default"}
                      onClick={(event) => handleOpenInfoModal(event)}
                      datacy={"allowlistingModalOpenButton"}
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={rootClasses.accordionPanel}>
                  <ScrollIndicator />
                  <Alert severity={"info"}>
                    Detection Filters is a feature that is currently only
                    available to users of an organization with an advanced
                    edition license. If you are interested in changing your
                    organization's license edition to enable access to this
                    feature, please contact Blumira.
                  </Alert>
                </AccordionDetails>
              </Accordion>
            </Card>
          )}

          <Card style={{ marginTop: 20 }}>
            <ScrollIndicator />
            <FindingDetails
              {...{
                evidenceLoadStatus,
                evidenceLimit,
                superadmin,
                srcCountry,
                summary,
                status,
                statusModified,
                relatedFindings,
              }}
            />
          </Card>

          <Card style={{ marginTop: 20 }}>
            <Accordion>
              <AccordionSummary
                className={rootClasses.accordionSummary}
                expandIcon={<KeyboardArrowDownIcon />}
              >
                <Typography className={rootClasses.accordionTitle}>
                  {"Resolution Notes"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={rootClasses.accordionPanel}>
                <ScrollIndicator />
                {finding.resolution ? (
                  <FindingsResolutionNotes finding={finding} />
                ) : (
                  <Typography style={{ marginLeft: 20 }}>
                    This finding has not been resolved and does not currently
                    have any resolution notes.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </Card>

          {superadmin && (
            <Card style={{ marginTop: 20 }}>
              <Accordion>
                <AccordionSummary
                  className={rootClasses.accordionSummary}
                  expandIcon={<KeyboardArrowDownIcon />}
                >
                  <Typography className={rootClasses.accordionTitle}>
                    Support
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={rootClasses.accordionPanel}>
                  <FindingSupport finding={finding} />
                </AccordionDetails>
              </Accordion>
            </Card>
          )}
        </div>

        <StyledDialog
          open={openInfoModal}
          onClose={() => setOpenInfoModal(false)}
          datacy={"allowlistingInfoModal"}
        >
          <div className={dialogClasses.infoTitleWrap}>
            <DialogTitle>What are detection filters?</DialogTitle>
            <IconButton
              color={"default"}
              onClick={() => setOpenInfoModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <Typography variant={"body2"}>
              Blumira notifies you when new findings are generated, but you
              might know that certain activity is safe. Detection filters tell
              Blumira who and what can be ignored by our findings, which reduces
              the noise of unnecessary notifications.
            </Typography>
            <List
              subheader={
                <ListSubheader className={dialogClasses.infoListItem}>
                  Detection filters let you:
                </ListSubheader>
              }
            >
              <ListItem className={dialogClasses.infoListItem}>
                <ListItemIcon className={dialogClasses.infoListIcons}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Exclude specific users or IPs from findings"}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItem>
              <ListItem className={dialogClasses.infoListItem}>
                <ListItemIcon className={dialogClasses.infoListIcons}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Stay focused on real threats"}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions className={dialogClasses.infoLinkWrap}>
            <Link href="https://blumira.help/filters">
              Learn more <OpenInNewIcon />
            </Link>
          </DialogActions>
        </StyledDialog>
        <ActionDialog
          open={openConfirmNotifyBlumiraModal}
          title={"Notify Blumira"}
          description="Are you sure you want to notify Blumira's Security Operations team that you need additional help?"
          actions={[
            {
              title: "Cancel",
              action: () => setOpenConfirmNotifyBlumiraModal(false),
            },
            {
              title: "Yes, Notify Blumira",
              action: submitMessageAndNotifyBlumira,
            },
          ]}
        />
      </ErrorBoundary>
    </Root>
  );
};

Finding.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  constants: constantsType.isRequired,
  finding: findingType.isRequired,
  gotoFinding: PropTypes.func.isRequired,
  onChangeOwner: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSaveComment: PropTypes.func.isRequired,
  onToggleTextbox: PropTypes.func.isRequired,
  orgUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageRole: PropTypes.string,
  gotoBlocks: PropTypes.func.isRequired,
  query: PropTypes.shape({}).isRequired,
  superadmin: PropTypes.bool.isRequired,
  currentUser: PropTypes.string.isRequired,
};

Finding.defaultProps = {
  pageRole: "responder",
  matches: [],
  orgUsers: [],
};

// expects state and array of nested values as
// args to determine presence in app state then
// returns a truthy bool if present else falsy
export const getReducerPropPresence = (state, nestedPropsArr) => {
  // default to false
  let returnValue = false;

  // determine whether or not the reducer
  // is present in the current app state
  const isPresent = _.get(state, nestedPropsArr, false);

  if (isPresent) returnValue = true;

  return returnValue;
};

const mapStateToProps = (state, ownProps) => {
  const userProps = ["session", "settings", "user"];
  const isCurrentUserDataPreset = getReducerPropPresence(state, userProps);

  const messageProps = ["message", "getIn"];
  const isMessageReducerPreset = getReducerPropPresence(state, messageProps);

  const currentUserId = isCurrentUserDataPreset
    ? state.session.settings.user.id
    : "";
  const comments = isMessageReducerPreset
    ? state.message.getIn(["messages", ownProps.finding.id], [])
    : [];

  return {
    comments: comments,
    license: state.license,
    currentUser: currentUserId,
    query: state.location.query || {},
    fields: Codex.language.bq.columns,
    constants: state.findings.get("constants"),
    user: isCurrentUserDataPreset ? state.session.settings.user : {},
    superadmin:
      (isCurrentUserDataPreset && state.session.settings.user.superadmin) ||
      false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onToggleTextbox: (id) => dispatch(FindingActions.toggleTextbox({ id })),
  onFieldChange: ({ id, field, value }) =>
    dispatch(FindingActions.updateField({ id, field, value })),
  onSaveComment: (message) => {
    dispatch(MessageActions.saveCommentForFinding(message));
  },
  fetchComments: () =>
    dispatch(
      MessageActions.getCommentsForFinding({
        findingId: ownProps.finding.id,
      })
    ),
  onChangeOwner: (values) => {
    return dispatch(
      setOwners({
        findingId: ownProps.finding.id,
        owners: (values || []).map((i) => i.value),
        ownerType: ownProps.pageRole || "responder",
      })
    );
  },
  gotoFinding: (id, orgId) =>
    dispatch({
      type: "PAGE",
      payload: {
        orgId,
        toplevel: "reporting",
        secondlevel: "findings",
        id1: id,
      },
    }),

  gotoBlocks:
    ({ orgId, findingId, query = {} }) =>
    (evt) => {
      evt.stopPropagation();

      const payload = {
        orgId,
        toplevel: "settings",
        secondlevel: "blocklists",
      };

      const newQuery = {
        ...query,
        findingId,
      };

      dispatch(
        pageWithPayloadAndQuery({
          payload,
          query: newQuery,
        })
      );
    },
  reload: (force) => dispatch(loadPageData(force)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Finding);
