import { DialogTitle } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "TicketFlowAccordion";
export const classes = {
  defaultEmailText: `${PREFIX}-defaultEmailText`,
  defaultEmailInput: `${PREFIX}-defaultEmailInput`,
  configureTicketsList: `${PREFIX}-configureTicketsList`,
  configureButton: `${PREFIX}-configureButton`,
  radioLabel: `${PREFIX}-radioLabel`,
  formControlLabel: `${PREFIX}-formControlLabel`,
  radioChild: `${PREFIX}-radioChild`,
};

export const StyledTicketFlowView = styled("div")(({ theme }) => ({
  [`& .${classes.defaultEmailText}`]: {
    fontSize: "0.875rem",
    marginBottom: "0.5rem",
  },

  [`& .${classes.defaultEmailInput}`]: {
    maxWidth: 400,
    width: "100%",
  },
  [`& .${classes.configureTicketsList}`]: {
    listStyleType: "none",
    padding: 0,
    "> li": {
      marginBottom: "2rem",

      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  [`& .${classes.configureButton}`]: {
    marginBottom: "1rem",
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  [`& .${classes.radioLabel}`]: {
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  [`& .${classes.formControlLabel}`]: {
    marginBottom: 0,
  },
  [`& .${classes.radioChild}`]: {
    marginBottom: "0.25rem",
    marginLeft: 32,
    "& .Mui-disabled": {
      textFillColor: "unset",
      color: theme.palette.action.disabled,
      "& fieldset": {
        borderColor: theme.palette.action.disabled,
      },
    },
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
}));

export const CustomMappingList = styled("table")(() => ({
  width: "100%",

  ">tr td": {
    padding: 4,
    "&:first-child": {
      paddingLeft: 0,
    },
    "&:last-child": {
      paddingRight: 0,
      width: 1,
    },
  },
}));

export const ArrowElement = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
  height: 4,
  margin: "0 16px",
  position: "relative",
  maxWidth: "100%",
  minWidth: 80,
  "&::after": {
    border: `6px solid ${theme.palette.text.primary}`,
    borderBottomColor: "transparent",
    borderLeftColor: "transparent",
    bottom: 0,
    content: "''",
    display: "block",
    margin: "auto",
    position: "absolute",
    right: 0,
    top: 0,
    transform: "rotate(45deg)",
  },
}));

const STYLED_STEPS_HEIGHT = "2rem";
const STYLED_STEPS_FONT_SIZE = "0.875rem";

export const StyledSteps = styled("ol")(({ theme }) => ({
  listStyle: "none",
  counterReset: "step-counter",
  padding: 0,
  "> li": {
    counterIncrement: "step-counter",
    marginBottom: "2rem",

    "&:last-child": {
      marginBottom: 0,
    },

    ".step-label": {
      marginLeft: 8,
      marginBottom: "1rem",
      minHeight: STYLED_STEPS_HEIGHT,
      fontSize: STYLED_STEPS_FONT_SIZE,
      fontWeight: "700",
      display: "inline-flex",
      alignItems: "center",
    },
  },
  "> li::before": {
    content: "counter(step-counter)",
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.text.primary}`,
    fontSize: STYLED_STEPS_FONT_SIZE,
    fontWeight: "700",
    borderRadius: "50%",
    height: STYLED_STEPS_HEIGHT,
    display: "inline-flex",
    width: STYLED_STEPS_HEIGHT,
    justifyContent: "center",
    alignItems: "center",
  },
}));
