import { styled } from "@mui/material/styles";

import { Dialog } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const PREFIX = "MSPBillingPageView";

export const classes = {
  outerContainer: `${PREFIX}-outerContainer`,
  outlinedHeaderLink: `${PREFIX}-outlinedHeaderLink`,
  headerLinksRowOuterContainer: `${PREFIX}-headerLinksRowOuterContainer`,
  headerLinksRowInnerContainer: `${PREFIX}-headerLinksRowInnerContainer`,
  clickableCardContainer: `${PREFIX}-clickableCardContainer`,
  clickableCard: `${PREFIX}-clickableCard`,
  clickableCardTitle: `${PREFIX}-clickableCardTitle`,
  clickableCardSecondaryText: `${PREFIX}-clickableCardSecondaryText`,
  clickableCardNumber: `${PREFIX}-clickableCardNumber`,
  clickableCardCTAText: `${PREFIX}-clickableCardCTAText`,
  clickableCardCTAButton: `${PREFIX}-clickableCardCTAButton`,
  accordionCard: `${PREFIX}-accordionCard`,
  accordionContainer: `${PREFIX}-accordionContainer`,
  accordionSummaryContainer: `${PREFIX}-accordionSummaryContainer`,
  accordionTitle: `${PREFIX}-accordionTitle`,
  accordionTitleRow: `${PREFIX}-accordionTitleRow`,
  alert: `${PREFIX}-alert`,
  agreementStepContainer: `${PREFIX}-agreementStepContainer`,
  agreementStepInnerContainer: `${PREFIX}-agreementStepInnerContainer`,
  superadminDirectionsContainer: `${PREFIX}-superadminDirectionsContainer`,
  minimumCommitmentText: `${PREFIX}-minimumCommitmentText`,
  tableColumnHeaderText: `${PREFIX}-tableColumnHeaderText`,
  tablePriceCell: `${PREFIX}-tablePriceCell`,
  tablePerUserText: `${PREFIX}-tablePerUserText`,
  tableRowRoot: `${PREFIX}-tableRowRoot`,
  radioTableCellContainer: `${PREFIX}-radioTableCellContainer`,
  disabledRow: `${PREFIX}-disabledRow`,
  disabledCell: `${PREFIX}-disabledCell`,
  stepTitleText: `${PREFIX}-stepTitleText`,
  stepTableTitleText: `${PREFIX}-stepTableTitleText`,
  stepLargeText: `${PREFIX}-stepLargeText`,
  currentAgreementList: `${PREFIX}-currentAgreementList`,
  radioButton: `${PREFIX}-radioButton`,
  customAgreementContainer: `${PREFIX}-customAgreementContainer`,
  customMonthlyInput: `${PREFIX}-customMonthlyInput`,
  overrideInput: `${PREFIX}-overrideInput`,
  agreementSummaryContainer: `${PREFIX}-agreementSummaryContainer`,
  termsAndConditionsContainer: `${PREFIX}-termsAndConditionsContainer`,
  checkboxContainer: `${PREFIX}-checkboxContainer`,
  boldText: `${PREFIX}-boldText`,
  termsAndConditionsTitleText: `${PREFIX}-termsAndConditionsTitleText`,
  smallTermsContainer: `${PREFIX}-smallTermsContainer`,
  customAgreementRadio: `${PREFIX}-customAgreementRadio`,
  customAgreementRadioLabel: `${PREFIX}-customAgreementRadioLabel`,
  needsApprovalContainer: `${PREFIX}-needsApprovalContainer`,
  rampTitle: `${PREFIX}-rampTitle`,
  overrideField: `${PREFIX}-overrideField`,
  overrideLabel: `${PREFIX}-overrideLabel`,
  datePickerContainer: `${PREFIX}-datePickerContainer`,
  termStartDatePicker: `${PREFIX}-termStartDatePicker`,
  customTermDatePicker: `${PREFIX}-customTermDatePicker`,
  finalCustomAgreementWrap: `${PREFIX}-finalCustomAgreementWrap`,
  agreementConfirmationDialog: `${PREFIX}-agreementConfirmationDialog`,
  agreementConfirmationDialogWrap: `${PREFIX}-agreementConfirmationDialogWrap`,
  successConfirmationCardStyles: `${PREFIX}-successConfirmationCardStyles`,
  congratsText: `${PREFIX}-congratsText`,
};

export const dialogClasses = {
  stripeLaunchDialogContainer: `${PREFIX}-stripeLaunchDialogContainer`,
  agreementTitleWrap: `${PREFIX}-agreementTitleWrap`,
  agreementIconWrap: `${PREFIX}-agreementIconWrap`,
  dialogHeaderContainer: `${PREFIX}-dialogHeaderContainer`,
  dialogTable: `${PREFIX}-dialogTable`,
  tableRowLabel: `${PREFIX}-tableRowLabel`,
  tableRowText: `${PREFIX}-tableRowText`,
  pricingTableRowLabel: `${PREFIX}-pricingTableRowLabel`,
  pricingTableRowText: `${PREFIX}-pricingTableRowText`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.outlinedHeaderLink}`]: {
    fontSize: 12,
    display: "flex",
    borderRadius: 10,
    cursor: "pointer",
    padding: "0px 10px",
    alignItems: "center",
    color: "#3C73EB", // TODO: find a match in our theme def for these
    backgroundColor: "#D9E3F7",
    "&:hover": {
      textDecoration: "none",
    },
    "& svg": {
      fontSize: 16,
      paddingLeft: 5,
    },
  },
  [`& .${classes.headerLinksRowOuterContainer}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.headerLinksRowInnerContainer}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& p": {
      fontSize: 12,
      marginBottom: 0,
    },
    "& > *:nth-child(2)": {
      marginLeft: 10,
    },
  },
  [`& .${classes.clickableCardContainer}`]: {
    display: "grid",
    marginTop: theme.shape.padding,
    gridTemplateColumns: "repeat(5, 1fr)",
    gridAutoRows: "1fr",
    gridGap: theme.spacing(2),
  },
  [`& .${classes.clickableCard}`]: {
    height: "100%",
    cursor: "pointer",
    boxShadow: "none",
    padding: theme.shape.padding,
    border: `1px solid ${theme.palette.border.xdrTrialCard}`,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  [`& .${classes.clickableCardTitle}`]: {
    fontSize: 13,
    fontWeight: "bold",
  },
  [`& .${classes.clickableCardSecondaryText}`]: {
    fontSize: 13,
    marginBottom: 5,
  },
  [`& .${classes.clickableCardCTAText}`]: {
    fontSize: 12,
    marginBottom: 0,
    color: theme.palette.primary.main,
    "& svg": {
      fontSize: 12,
    },
  },
  [`& .${classes.clickableCardCTAButton}`]: {
    fontSize: 13,
    color: theme.palette.primary.main,
    background: "none",
    border: "none",
    padding: 0,
    cursor: "pointer",
    textAlign: "left",
    fontFamily: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      outline: "2px solid " + theme.palette.primary.main,
      outlineOffset: "2px",
    },
    "&:disabled": {
      color: theme.palette.text.disabled,
      cursor: "not-allowed",
      "& .MuiSvgIcon-root": {
        fill: theme.palette.text.disabled,
      },
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  [`& .${classes.clickableCardNumber}`]: {
    fontSize: 30,
    fontWeight: "bold",
    fontFamily: '"Open Sans", sans-serif',
  },
  [`& .${classes.accordionCard}`]: {
    boxShadow: "none",
    marginTop: theme.shape.padding,
    border: `1px solid ${theme.palette.border.xdrTrialCard}`,
  },
  [`& .${classes.accordionContainer}`]: {
    padding: "5px 0px",
  },
  [`& .${classes.accordionSummaryContainer}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  [`& .${classes.accordionTitleRow}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.accordionTitle}`]: {
    fontSize: 15,
    marginBottom: 0,
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  [`& .${classes.alert}`]: {
    marginTop: theme.spacing(1),
    color: theme.palette.superadmin.main,
    backgroundColor: theme.palette.superadmin.light,
    border: `1px solid ${theme.palette.superadmin.main}`,
  },
  [`& .${classes.agreementStepContainer}`]: {
    borderRadius: 10,
    marginBottom: theme.shape.padding,
    border: `1px solid ${theme.palette.border.xdrTrialCard}`,
    "& p": {
      fontSize: 13,
      marginBottom: 0,
    },
    "&.blueHighlight": {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },
  },
  [`& .${classes.agreementStepInnerContainer}`]: {
    padding: theme.shape.padding,
  },
  [`& .${classes.superadminDirectionsContainer}`]: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: theme.shape.padding,
    marginBottom: theme.shape.padding,
    backgroundColor: theme.palette.superadmin.light,
    border: `1px solid ${theme.palette.superadmin.main}`,
    "& ul": {
      marginLeft: -20,
      marginTop: theme.shape.padding,
    },
    "& p, li": {
      fontSize: 13,
      marginBottom: 0,
      color: theme.palette.superadmin.main,
    },
  },
  [`& .${classes.minimumCommitmentText}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.tableColumnHeaderText}`]: {
    borderRadius: 10,
    padding: "2px 5px",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: theme.palette.action.toggleButtonHover,
    color: theme.palette.text.primary,
  },
  [`& .${classes.tablePerUserText}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.tablePriceCell}`]: {
    textAlign: "center",
  },
  [`& .${classes.radioTableCellContainer}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    height: "100%",
    padding: "16px",
  },
  [`& .${classes.disabledRow}`]: {
    opacity: 0.5,
  },
  [`& .${classes.tableRowRoot}`]: {
    "& .MuiTableCell-root": {
      height: "auto",
      padding: "16px",
    },
    "&:last-child td": {
      borderBottom: 0,
    },
  },
  [`& .${classes.stepTitleText}`]: {
    fontWeight: "bold",
    paddingBottom: theme.shape.padding,
  },
  [`& .${classes.stepTableTitleText}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.stepLargeText}`]: {
    fontWeight: "bold",
    fontSize: "15px !important",
    paddingBottom: theme.shape.padding,
  },
  [`& .${classes.currentAgreementList}`]: {
    listStyleType: "none",
    padding: 0,
    fontSize: 14,
  },
  [`& .${classes.radioButton}`]: {
    marginBottom: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: 13,
    },
  },
  [`& .${classes.customAgreementContainer}`]: {
    backgroundColor: theme.palette.superadmin.light,
    border: `1px solid ${theme.palette.superadmin.main}`,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: theme.shape.padding,
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.customMonthlyInput}`]: {
    "& .MuiFormLabel-root": {
      color: theme.palette.superadmin.main,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      "& .Mui-disabled": {
        backgroundColor: "rgba(204, 206, 207, 0.25)", // LIGHT_GRAY from theme
        cursor: "not-allowed",
      },
    },
  },
  [`& .${classes.overrideInput}`]: {
    width: "12%",
    "& .MuiInputLabel-root": {
      whiteSpace: "normal",
      color: "black",
    },
  },
  [`& .${classes.agreementSummaryContainer}`]: {
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.termsAndConditionsContainer}`]: {
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.checkboxContainer}`]: {
    display: "flex",
    alignItems: "center",
    border: "2px solid transparent",
    borderRadius: 10,
    marginBottom: theme.shape.padding,
    padding: "2px 5px 2px 5px",
    width: "fit-content",
    "& p": {
      fontSize: 13,
      marginBottom: 0,
    },
    "& label": {
      marginBottom: 0,
    },
    "&.blueHighlight": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  [`& .${classes.boldText}`]: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 5,
  },
  [`& .${classes.termsAndConditionsTitleText}`]: {
    fontSize: 13,
    marginBottom: 5,
    fontWeight: "bold",
  },
  [`& .${classes.smallTermsContainer}`]: {
    fontSize: 12,
  },
  [`& .${classes.customAgreementRadio}`]: {
    marginBottom: 0,
  },
  [`& .${classes.customAgreementRadioLabel}`]: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
  [`& .${classes.needsApprovalContainer}`]: {
    width: "24%",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "100%",
      border: "1px solid",
      height: 20,
      bottom: -20,
      left: 0,
      borderColor:
        "transparent rgba(77, 65, 131, 0.35) rgba(77, 65, 131, 0.35) rgba(77, 65, 131, 0.35)", // Superadmin main/dark purple color from theme
    },
    "&::before": {
      position: "absolute",
      content: '"Requires manager approval"',
      width: "63%",
      height: 20,
      bottom: -30,
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      color: theme.palette.superadmin.main,
      fontSize: 13,
      backgroundColor: theme.palette.superadmin.light,
      zIndex: 1,
    },
  },
  [`& .${classes.rampTitle}`]: {
    fontSize: "14px !important",
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  [`& .${classes.overrideField}`]: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`& .${classes.overrideLabel}`]: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: "15px",
  },
  [`& .${classes.datePickerContainer}`]: {
    paddingBottom: theme.shape.padding,
  },
  [`& .${classes.termStartDatePicker}`]: {
    paddingRight: theme.shape.padding,
  },
  [`& .${classes.customTermDatePicker}`]: {
    "& .MuiFormLabel-root": {
      color: theme.palette.superadmin.main,
    },
  },
  [`& .${classes.finalCustomAgreementWrap}`]: {
    backgroundColor: theme.palette.superadmin.light,
  },
  [`& .${classes.agreementConfirmationDialogWrap}`]: {
    position: "relative",
  },
  [`& .${classes.agreementConfirmationDialog}`]: {
    position: "absolute",
    zIndex: 5,
    width: 500,
    top: "50%",
    transform: "translateY(-50%)",
    left: "105%",
    padding: 15,
    "&:after": {
      position: "absolute",
      left: -12,
      top: "50%",
      width: 0,
      height: 0,
      content: '""',
      borderTop: "12px solid transparent",
      borderBottom: "12px solid transparent",
      borderRightWidth: "12px",
      borderRightStyle: "solid",
      borderRightColor: theme.palette.background.paper,
      transform: "translateY(-50%)",
    },
  },
  [`& .${classes.successConfirmationCardStyles}`]: {
    height: "100%",
    cursor: "pointer",
    padding: theme.shape.padding,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  [`& .${classes.congratsText}`]: {
    fontSize: 18,
    fontWeight: "bold",
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.agreementTitleWrap}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${dialogClasses.agreementIconWrap}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 105,
  },
  [`& .${dialogClasses.dialogHeaderContainer}`]: {
    display: "flex",
    paddingRight: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${dialogClasses.dialogTable}`]: {
    // marginLeft: 30,
    // marginRight: 30,
    // marginTop: 30,
    // "& td": {
    //   paddingBottom: 15,
    //   verticalAlign: "top",
    // },
  },
  [`& .${dialogClasses.tableRowLabel}`]: {
    fontWeight: "bold",
    paddingRight: 20,
    whiteSpace: "nowrap",
    fontSize: "14px",
    paddingBottom: 15,
    verticalAlign: "top",
  },
  [`& .${dialogClasses.tableRowText}`]: {
    fontSize: "14px",
    paddingBottom: 15,
    verticalAlign: "top",
  },
  [`& .${dialogClasses.pricingTableRowLabel}`]: {
    fontWeight: "bold",
    paddingRight: 20,
    whiteSpace: "nowrap",
    fontSize: "14px",
    paddingBottom: 5,
    verticalAlign: "top",
  },
  [`& .${dialogClasses.pricingTableRowText}`]: {
    fontSize: "14px",
    paddingBottom: 5,
    verticalAlign: "top",
  },
  [`& .${dialogClasses.stripeLaunchDialogContainer}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const ConnectionStatusIcon = styled(FiberManualRecordIcon)(
  ({ theme }) => ({
    "&.connected": {
      color: theme.palette.success.main,
    },
    "&.disconnected": {
      color: theme.palette.error.main,
    },
  })
);
