import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { loadPageData } from "redux/actions/Request";

import { RECEIVE_SETTINGS } from "redux/actions/Session";

import { Alert, AlertTitle } from "@mui/material";

import { get, isNil } from "lodash";

import {
  Dialog,
  Button,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Tooltip,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material";

import { Org } from "lib/models/Org";
import { LCGroup } from "lib/models/LCGroup";
import { Settings } from "lib/models/Settings";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import SimpleTable from "views/Components/SimpleTable";
import LicenseRestriction from "views/Components/License";
import { getNewTotalAgentCount } from "views/Components/BlumiraAgent/AddAgentDialog";

import {
  getErrorMessageToSet,
  sendEmailToSalesTeam,
  setLogShippingFeature,
  getInstallationScript,
  getInstallationTableColumns,
} from "./utils";

import { getChildOrgs } from "views/Pages/MSPPortal/utils";

import {
  Root,
  InstallationDialog,
  rootClasses,
  dialogClasses,
} from "./installationPageStyles";

import {
  copyTextToClipboard,
  isOrganizationChildOfNFR,
  hasLegacyLicense,
} from "utils";

import { AddAgentDialog } from "views/Components/BlumiraAgent/AddAgentDialog";
import { ActivationConfirmationDialog } from "views/Components/BlumiraAgent/ActivationConfirmationDialog";
import { BlumiraAgentActivationBanner } from "views/Components/BlumiraAgent/BlumiraAgentActivationBanner";
import { SeatCountInfoCard } from "views/Components/BlumiraAgent/SeatCountInfoCard";
import { InstallationKeySelector } from "views/Components/BlumiraAgent/InstallationKeySelector";

export const defaultKeyValue = "default";

export const isCopyButtonDisabled = (selectedKey, defaultKey) => {
  // if an invalid key is passed, disable
  if (!selectedKey) return true;

  // if the user has not selected a key we can disable
  // the copy button since there's no script to copy
  return selectedKey === defaultKey;
};

const InstallationPageView = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isKeyMasked, setIsKeyMasked] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [canAddAgents, setCanAddAgents] = useState(false);
  const [selectedGroupKey, setSelectedGroupKey] = useState("");
  const [selectedGroupModel, setSelectedGroupModel] = useState({});
  const [installationScript, setInstallationScript] = useState("");
  const [isActivatingAgent, setIsActivatingAgent] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [wasEmailSuccessful, setWasEmailSuccessful] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(defaultKeyValue);
  const [isRemoveKeyDialogOpen, setIsRemoveKeyDialogOpen] = useState(false);
  const [selectedGroupDescription, setSelectedGroupDescription] = useState("");
  const [selectedInstallationKey, setSelectedInstallationKey] =
    useState(defaultKeyValue);
  const [selectedAgentSeatCount, setSelectedAgentSeatCount] = useState(0);
  const [
    isActivationConfirmationDialogOpen,
    setIsActivationConfirmationDialogOpen,
  ] = useState(false);
  const [isAddAgentDialogOpen, setIsAddAgentDialogOpen] = useState(false);
  const [orgTotalGroupSeatCount, setOrgTotalGroupSeatCount] = useState(0);
  const [orgGroupDeviceLeftOverCount, setOrgGroupDeviceLeftOverCount] =
    useState(0);
  const [orgGroupInstalledDeviceCount, setOrgGroupInstalledDeviceCount] =
    useState(0);
  const [isOrgMaxAgentsReached, setIsOrgMaxAgentsReached] = useState(false);
  const [errorHelperText, setErrorHelperText] = useState(false);
  const [platformValue, setPlatformValue] = useState("windows");
  const [isLoading, setIsLoading] = useState(false);
  const [isRadioFieldDisabled, setIsRadioFieldDisabled] = useState(true);
  const [isRadioInfoModalOpen, setIsRadioInfoModalOpen] = useState(false);

  useEffect(() => {
    // SAs can always set agent limits
    if (props.currentUser && props.currentUser.superadmin) {
      return setCanAddAgents(true);
    }

    // administrators of MSP orgs on legacy licenses can set agent limits in child orgs
    if (props.isOrgAChildOrg) {
      let currentUserOrgRoles = props.currentUser?.orgRoles || [];

      const isAdministratorInParentOrg = currentUserOrgRoles.some(
        ({ roleId }) => roleId === 10
      );

      // if administering an NFR account has *any* legacy licenses, they can add agents
      if (
        isAdministratorInParentOrg &&
        props.doesAnyOfParentsChildrenHaveLegacyLicense
      ) {
        return setCanAddAgents(true);
      }
    } else {
      setCanAddAgents(false);
    }
  }, [
    props.isOrgAChildOrg,
    props.currentUser,
    props.doesAnyChildOrgHaveLegacyLicense,
    props.doesAnyOfParentsChildrenHaveLegacyLicense,
  ]);

  useEffect(() => {
    // get key and description from selected
    // group model to update in dialog
    const {
      description = "",
      installationKey = "",
      agentSeatCount = "",
    } = selectedGroupModel;

    setSelectedGroupKey(installationKey);
    setSelectedGroupDescription(description);
    setSelectedAgentSeatCount(agentSeatCount);
  }, [selectedGroupModel]);

  useEffect(() => {
    if (props.ready) {
      const total = getOrgGroupsSeatCount(props.keys);
      // Find max # of agents that can be added to a group seat count before reaching the org seat count limit
      if (selectedGroupModel.id) {
        setOrgTotalGroupSeatCount(
          props.currentOrg?.config?.agent_seat_count -
            total +
            selectedGroupModel.agentSeatCount
        );
      } else {
        setOrgTotalGroupSeatCount(
          props.currentOrg?.config?.agent_seat_count - total
        );
      }
      // Find # of agents an org has left over before reaching their org seat count limit
      setOrgGroupDeviceLeftOverCount(
        props.currentOrg?.config?.agent_seat_count - total
      );

      if (total === props.currentOrg?.config?.agent_seat_count) {
        setIsOrgMaxAgentsReached(true);
      } else {
        setIsOrgMaxAgentsReached(false);
      }
    }
  }, [
    props.ready,
    props.currentOrg?.config?.agent_seat_count,
    selectedGroupModel.agentSeatCount,
    props.keys,
  ]);

  useEffect(() => {
    if (props.ready) {
      const total = getOrgGroupsInstalledDevicesCount(props.keys);
      // Find # of agents an org has currently installed across all groups
      setOrgGroupInstalledDeviceCount(total);
    }
  }, [props.ready, props.keys]);

  useEffect(() => {
    setInstallationScript("");
    setPlatformValue("windows");
    setIsRadioFieldDisabled(true);
  }, [props.orgId]);

  useEffect(() => {
    if (isDetailsModalOpen) {
      handleDetailsRadioChange();
    } else {
      handleChangeInstallationKey(selectedGroupId);
      setSelectedGroupId(selectedGroupId);
    }
  }, [platformValue]);

  const getOrgGroupsSeatCount = (keys) => {
    const totalGroupSeatArray = keys.map((key) => key.agentSeatCount);

    const totalCount = totalGroupSeatArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return totalCount;
  };

  const getOrgGroupsInstalledDevicesCount = (keys) => {
    const totalGroupDeviceArray = keys.map((key) => key.agentCount);

    const totalCount = totalGroupDeviceArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return totalCount;
  };

  const handleDetailsRadioChange = async () => {
    const activeInstallationKey = await handleGetInstallationKey(
      selectedGroupModel
    );

    const script = getInstallationScript(activeInstallationKey, platformValue);
    setInstallationScript(script);
  };

  const handleEditTableRowClick = async (model) => {
    setIsSaving(false);
    setIsLoading(true);
    setIsDetailsModalOpen(true);

    model
      .read()
      .then(async (selectedLCGroup) => {
        // get key from selected model
        const activeInstallationKey = await handleGetInstallationKey(
          selectedLCGroup
        );

        // get script command to display in detail dialog
        const script = getInstallationScript(
          activeInstallationKey,
          platformValue
        );

        setInstallationScript(script);
        setSelectedGroupModel(selectedLCGroup);

        setIsLoading(false);
      })
      .catch((e) => {
        setSelectedGroupModel({});
        setIsLoading(false);
        setErrorMessage(
          "Oops, we had trouble retrieving that installation key"
        );
      });
  };

  const handleOpenKeyDialog = () => {
    const newGroupModel = new LCGroup();
    setSelectedGroupModel(newGroupModel);
    setIsDetailsModalOpen(true);
  };

  const handleCopyKeyValue = (event, model) => {
    // if we have a model defined, this is
    // happening from the row action item
    const keyToCopy = model ? model.key : selectedGroupKey;
    copyTextToClipboard(keyToCopy);
  };

  const handleGetInstallationKey = async (model) => {
    let installationKeyToReturn;

    if (model.installationKey) {
      installationKeyToReturn = model.installationKey;
    } else {
      // we need to make a request to get the installation key here
      await new LCGroup({ id: model.id }).read().then((newModel) => {
        installationKeyToReturn = newModel.installationKey;

        // set it on the model for future use
        model.set({ installationKey: newModel.installationKey });
      });
    }

    return installationKeyToReturn;
  };

  const handleCopyInstallationScript = async (contextMenu = {}) => {
    const { model } = contextMenu;

    if (model) {
      // user is copying from context menu and we
      // need to generate the installation command

      const installationKeyToUse = await handleGetInstallationKey(model);

      const scriptToCopy = getInstallationScript(
        installationKeyToUse,
        platformValue
      );
      copyTextToClipboard(scriptToCopy);
    } else {
      // we are copying from  the wizard and have
      // the installation script in memory
      copyTextToClipboard(installationScript);
    }
  };

  const handleCloseModal = () => {
    setErrorMessage("");
    setErrorHelperText("");
    setInstallationScript("");
    setPlatformValue("windows");
    setIsDetailsModalOpen(false);
    setSelectedGroupModel({});
    setSelectedGroupId(defaultKeyValue);
    setSelectedInstallationKey(defaultKeyValue);
    setIsRadioFieldDisabled(true);
    if (selectedGroupModel.id) {
      setSelectedAgentSeatCount(selectedGroupModel.agentSeatCount);
    }
  };

  const handleSubmit = () => {
    setIsSaving(true);
    // get proper action based on id presence
    const action = selectedGroupModel.id ? "update" : "create";

    // Don't allow users to type zero into the field
    if (Number(selectedAgentSeatCount) === 0) {
      setErrorHelperText(
        `Please enter a minimum of 1 - ${orgGroupDeviceLeftOverCount} more available`
      );
      setIsSaving(false);
      // Don't allow users to type a number larger than the maximum currently allowed on that group
    } else if (Number(selectedAgentSeatCount) > orgTotalGroupSeatCount) {
      setErrorHelperText(`The maximum available is ${orgTotalGroupSeatCount}`);
      setIsSaving(false);
    } else {
      // set description and agentSeatCount of group accordingly
      selectedGroupModel.set({
        description: selectedGroupDescription,
        agentSeatCount: selectedAgentSeatCount,
      });

      selectedGroupModel[action]()
        .then(async (newAgentModel) => {
          // await here so we have all keys in our props to avoid
          // material warnings that this new value is out of range
          await props.reload(true);

          handleCloseModal();
          setErrorMessage("");
          setErrorHelperText("");
          setIsSaving(false);

          // after we create, generate an installation script and
          // populate the UI for quick access
          if (action === "create") {
            setSelectedGroupId(newAgentModel.id);
            const installationKeyToUse = await handleGetInstallationKey(
              newAgentModel
            );
            setSelectedInstallationKey(installationKeyToUse);
            const script = getInstallationScript(
              installationKeyToUse,
              platformValue
            );
            setInstallationScript(script);
            setIsRadioFieldDisabled(false);
          }
        })
        .catch((e) => {
          setErrorMessage(
            "Oops, we had a problem saving that installation key."
          );
          setIsSaving(false);
          setIsRadioFieldDisabled(true);

          if (
            !selectedGroupModel.agentSeatCount ||
            selectedGroupModel.agentSeatCount > orgGroupDeviceLeftOverCount
          ) {
            setErrorHelperText(
              `Please enter a number between 1 and your available device limit of ${orgGroupDeviceLeftOverCount}`
            );
          }
        });
    }
  };

  const handleChangeInstallationKey = async (selectedGroupModelId) => {
    setSelectedGroupId(selectedGroupModelId);

    // get full model to determine if key is available
    const fullSelectedGroupModel = props.keys.find(
      ({ id }) => id === selectedGroupModelId
    );

    // if we find a model matching the selected id
    // we can use the model to retrieve an installation key
    if (fullSelectedGroupModel) {
      const installationKeyToUse = await handleGetInstallationKey(
        fullSelectedGroupModel
      );
      setSelectedInstallationKey(installationKeyToUse);
      const script = getInstallationScript(installationKeyToUse, platformValue);
      setInstallationScript(script);
      setIsRadioFieldDisabled(false);
    } else {
      // if we do not find a model, revert to default
      setSelectedInstallationKey(defaultKeyValue);
      setSelectedGroupId(defaultKeyValue);
      setInstallationScript("");
      setPlatformValue("windows");
      setIsRadioFieldDisabled(true);
    }
  };

  const handleDescriptionChange = (event) => {
    let newDescription = event.target.value;
    setSelectedGroupDescription(newDescription);
  };

  const toggleRemoveKeyDialog = () => {
    setIsRemoveKeyDialogOpen(!isRemoveKeyDialogOpen);
  };

  const handleRemoveKey = () => {
    // TODO: Need to look into error handling for certain situations here
    // i.e - if you delete a group that has agents in it the service will return an error
    selectedGroupModel
      .delete()
      .then(() => {
        props.reload(true);
        setIsDetailsModalOpen(false);
        toggleRemoveKeyDialog();
        setErrorMessage("");
        setInstallationScript("");
        setPlatformValue("windows");
        setSelectedGroupId(defaultKeyValue);
        setIsRadioFieldDisabled(true);
      })
      .catch((err) => {
        const { message: detailedErrorMessage } = err;
        toggleRemoveKeyDialog();
        setErrorMessage(
          `Oops, something went wrong deleting your key: ${detailedErrorMessage}`
        );
      });
  };

  const handleActivateDevicesFeature = async () => {
    const { isOrgAChildOrg, isOrgAParentOrg, currentOrg, currentUser } = props;

    const errorMessageToSet = getErrorMessageToSet(currentOrg);

    try {
      if (isOrgAChildOrg || isOrgAParentOrg) {
        setIsActivatingAgent(true);
        await setLogShippingFeature(
          currentOrg,
          currentUser.email,
          props.doesAnyOfParentsChildrenHaveLegacyLicense
        );
        setIsActivatingAgent(false);
        setErrorMessage("");

        // reload page for license feature updates
        window.location.reload();
      } else {
        try {
          await sendEmailToSalesTeam(currentOrg.name);
          setErrorMessage("");
          setWasEmailSuccessful(true);
        } catch (e) {
          setErrorMessage(errorMessageToSet);
          setWasEmailSuccessful(false);
        }
      }
    } catch (e) {
      // if anything goes wrong notify the user and reset
      setErrorMessage(errorMessageToSet);
      setIsActivatingAgent(false);
    }
  };

  const toggleActivationConfirmationDialog = () => {
    setWasEmailSuccessful(false);
    setIsActivationConfirmationDialogOpen(!isActivationConfirmationDialogOpen);
  };

  const handleActivateClick = () => {
    const { isOrgAChildOrg, isOrgAParentOrg } = props;

    // allow MSP NFR admins to freely grant access to
    // this feature, else present confirmation dialog
    if (isOrgAChildOrg || isOrgAParentOrg) {
      handleActivateDevicesFeature();
    } else {
      toggleActivationConfirmationDialog();
    }
  };

  const handleAddAgents = async (agentCount) => {
    // get new agent seat count for org
    const newAgentSeatCountForOrg = getNewTotalAgentCount(
      props.currentOrg.config.agent_seat_count,
      agentCount
    );

    // set new agent seat count for org - orgs are stored as JSON, update as model
    const orgModel = new Org(props.currentOrg);
    orgModel.set({ agentSeatCount: newAgentSeatCountForOrg });

    // update org model with new agent seat count
    await orgModel.update();

    // get the group model for updating agent seat count
    const selectedGroupModelForAgentAddition = props.keys.find(
      ({ id }) => id === selectedGroupId
    );

    // provided two numbers, finds sum
    const newAgentSeatCountForGroup = getNewTotalAgentCount(
      selectedGroupModelForAgentAddition.agentSeatCount,
      agentCount
    );

    // set new agent seat count on selected group model
    selectedGroupModelForAgentAddition.set({
      agentSeatCount: newAgentSeatCountForGroup,
    });

    // update selected group model with new agent seat count
    await selectedGroupModelForAgentAddition.update();
  };

  const handleSelectGroupModelForAgentAddition = (selectedGroupModelId) => {
    setSelectedGroupId(selectedGroupModelId);
  };

  const toggleAddAgentsDialog = () => {
    // if there is only one key, add agents to it
    if (props.keys.length === 1 && !isAddAgentDialogOpen) {
      const selectedGroupModelId = props.keys[0].id;
      setSelectedGroupId(selectedGroupModelId);
    }

    // if we are closing the dialog, reset selected group
    if (isAddAgentDialogOpen) setSelectedGroupId(defaultKeyValue);

    setIsAddAgentDialogOpen(!isAddAgentDialogOpen);
  };

  // TODO: repurpose for upsale
  const renderDisallow = () => {
    return;
  };

  const renderAllow = (children) => {
    return children;
  };

  const renderBanner = (children) => {
    return children;
  };

  const hideBanner = () => {
    return null;
  };

  const handleAgentSeatCountChange = (event) => {
    let newAgentSeatCount = event.target.value;
    setSelectedAgentSeatCount(newAgentSeatCount);
  };

  const handleReloadPageData = async () => {
    setIsSaving(true);
    // reset selected group
    setSelectedGroupId(defaultKeyValue);

    // refresh table data
    props.reload(true);

    // refresh settings to get new max deployable agent count
    await props.reloadSettings();

    setIsSaving(false);
  };

  const renderInstallKeyField = () => {
    if (selectedGroupModel.agentCount === selectedGroupModel.agentSeatCount) {
      return (
        <Tooltip
          title={
            "Unable to copy installation script.  Device limit reached for this key."
          }
          placement={"top"}
        >
          <div>
            <TextField
              fullWidth={true}
              value={installationScript}
              className={dialogClasses.textField}
              label={"Agent install script"}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton
                      onClick={handleCopyInstallationScript}
                      disabled={true}
                      className={dialogClasses.inputIcons}
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              disabled={true}
              datacy={"installationKeyGroupInstallScript"}
            />
          </div>
        </Tooltip>
      );
    } else {
      return (
        <TextField
          value={installationScript}
          className={dialogClasses.textField}
          label={"Agent install script"}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton
                  onClick={handleCopyInstallationScript}
                  className={dialogClasses.inputIcons}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          datacy={"installationKeyGroupInstallScript"}
        />
      );
    }
  };

  const handlePlatformValueChange = (event) => {
    setPlatformValue(event.target.value);
  };

  const contactLink =
    props.currentOrg?.market === 20
      ? "https://blumira.help/partners"
      : "https://blumira.help/help";

  const handleDisabledRadioClick = () => {
    if (isRadioFieldDisabled) {
      // Show informative modal about step 1
      setIsRadioInfoModalOpen(true);
    } else {
      return;
    }
  };

  const closeRadioInfoModal = () => {
    setIsRadioInfoModalOpen(false);
  };

  const renderMaxAgentsCard = () => {
    // If a parent account has a child on a legacy edition license OR if the child account's parent org has a legacy license OR if an org is a market other than MSP
    if (
      props.doesAnyChildOrgHaveLegacyLicense ||
      props.doesAnyOfParentsChildrenHaveLegacyLicense ||
      props.currentOrg?.market !== 20
    ) {
      return (
        <p datacy={"agentCountText"}>
          This is the limit of how many agents this account can deploy. You will
          not be invoiced based on this number, your invoice will be based on
          your contract terms and the number of agents actually deployed.
        </p>
      );
      // If MSP Parent account AND no child accounts have a legacy edition license
    } else if (
      props.isOrgAParentOrg &&
      !props.doesAnyChildOrgHaveLegacyLicense
    ) {
      return (
        <p datacy={"agentCountText"}>
          Your internal-use NFR includes free XDR users based on your tier and
          the user count drives your agent limit. If you need more users to
          increase the agent limit, please{" "}
          <Link
            href={"mailto:msp@blumira.com"}
            className={rootClasses.clickHereLink}
          >
            click here
          </Link>{" "}
          to request. Note - You will be charged for the users above what's
          included in your NFR.
          <Link
            href={"https://blumira.help/msppricing"}
            className={rootClasses.requestMoreLink}
          >
            Learn more <OpenInNewIcon className={rootClasses.requestMoreIcon} />
          </Link>
        </p>
      );
      // If the org is a child account AND the parent doesn't have a legacy license AND the child's license is M365
    } else if (
      props.isOrgAChildOrg &&
      !props.doesAnyOfParentsChildrenHaveLegacyLicense &&
      props.license._activeLicense.license_name === "M365"
    ) {
      return (
        <p datacy={"agentCountText"}>
          The M365 edition only allows for 5 agents max. If more agents are
          required, please switch to a higher edition.
          <Link
            href={"https://blumira.help/msppricing"}
            className={rootClasses.requestMoreLink}
          >
            Learn more <OpenInNewIcon className={rootClasses.requestMoreIcon} />
          </Link>
        </p>
      );
      // In all other instances show this text
      // This should display on child orgs that have a license of XDR or SIEM+ AND the parent account is on a current edition
    } else {
      return (
        <p datacy={"agentCountText"}>
          The Max Deployable agent limit is 200% of the User count. If you need
          more agents, please increase the user count using the MSP Portal.
          <Link
            href={"https://blumira.help/msppricing"}
            className={rootClasses.requestMoreLink}
          >
            Learn more <OpenInNewIcon className={rootClasses.requestMoreIcon} />
          </Link>
        </p>
      );
    }
  };

  return (
    <Root>
      {/*show error here only when dialog is closed, else it is handled in dialog */}
      {errorMessage && !isDetailsModalOpen && (
        <Alert
          className={rootClasses.errorBanner}
          severity="error"
          action={
            <a
              className={rootClasses.errorAction}
              rel={"noopener noreferrer"}
              target={"_blank"}
              href={contactLink}
            >
              CONTACT
            </a>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <LicenseRestriction
        requires="lcagent.create"
        renderAllow={hideBanner}
        renderDisallow={renderBanner}
      >
        <BlumiraAgentActivationBanner
          isOrgAParentOrg={props.isOrgAParentOrg}
          isOrgAChildOrg={props.isOrgAChildOrg}
          isActivatingAgent={isActivatingAgent}
          onActivateClick={handleActivateClick}
        />
      </LicenseRestriction>
      <LicenseRestriction
        requires="lcagent.create"
        renderAllow={renderAllow}
        renderDisallow={renderDisallow}
      >
        <div className={rootClasses.cardContainer}>
          <SeatCountInfoCard
            isLoading={isSaving || !props.ready}
            description={
              <>
                {renderMaxAgentsCard()}
                {props.isOrgAChildOrg &&
                  !canAddAgents &&
                  !props.doesAnyChildOrgHaveLegacyLicense &&
                  !props.doesAnyOfParentsChildrenHaveLegacyLicense &&
                  props.license._activeLicense.license_name !== "M365" && (
                    <p>
                      * For more agents add additional users to this
                      organization's user count, you receive two agents per
                      user.
                    </p>
                  )}
              </>
            }
            countToDisplay={props.currentOrg?.config?.agent_seat_count}
            header={"Maximum Deployable Agents"}
            style={{ width: "55%", marginRight: 15 }}
            action={
              !props.keys.length && canAddAgents ? (
                <Tooltip
                  title={"Installation key required to add agents"}
                  placement={"right"}
                  className={rootClasses.tooltip}
                >
                  <div>
                    <Button
                      color={"primary"}
                      variant={"outlined"}
                      onClick={toggleAddAgentsDialog}
                      className={rootClasses.addAgentButton}
                      disabled
                    >
                      Add Agents
                    </Button>
                  </div>
                </Tooltip>
              ) : canAddAgents ? ( // ability to add agents in child orgs is granted to MSP Admins on legacy licenses and SAs only
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  onClick={toggleAddAgentsDialog}
                  className={rootClasses.addAgentButton}
                  disabled={isSaving || !props.ready}
                >
                  Add Agents
                </Button>
              ) : props.isOrgAParentOrg &&
                !canAddAgents &&
                props.doesAnyChildOrgHaveLegacyLicense ? (
                <Link
                  href={"mailto:msp@blumira.com"}
                  className={rootClasses.requestMoreLink}
                >
                  Request more{" "}
                  <OpenInNewIcon className={rootClasses.requestMoreIcon} />
                </Link>
              ) : null
            }
          />
          <SeatCountInfoCard
            countToDisplay={orgGroupInstalledDeviceCount}
            header={"Deployed Agents"}
            style={{ width: "45%" }}
          />
        </div>

        <div className={rootClasses.agentSetupStepsContainer}>
          <p className={rootClasses.title}>
            Generate agent installation script
          </p>
          <div className={rootClasses.agentSetupStepContainer}>
            <div className={rootClasses.circularNumberContainer}>
              <p>1</p>
            </div>
            <div className={rootClasses.stepTextInnerContainer}>
              <InstallationKeySelector
                keys={props.keys}
                allowCreate={true}
                selectedGroupId={selectedGroupId}
                openKeyDialog={handleOpenKeyDialog}
                changeInstallationKey={(selectedGroupModelId) =>
                  handleChangeInstallationKey(selectedGroupModelId)
                }
              />
            </div>
          </div>
          <div className={rootClasses.agentSetupStepContainer}>
            <div className={rootClasses.circularNumberContainer}>
              <p>2</p>
            </div>
            <div className={rootClasses.stepTextOuterContainer}>
              <div className={rootClasses.stepTextInnerContainer}>
                <p>
                  Select platform
                  <a
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    href={"https://blumira.help/agent-install"}
                    className={rootClasses.stepTwoLink}
                  >
                    See supported OS versions <OpenInNewIcon />
                  </a>
                </p>
              </div>
              <RadioGroup
                aria-label="platform"
                name="platform"
                value={platformValue}
                onChange={handlePlatformValueChange}
                onClick={handleDisabledRadioClick}
              >
                <FormControlLabel
                  value="windows"
                  control={<Radio />}
                  label="Windows"
                  className={rootClasses.platformFormControlLabel}
                  disabled={isRadioFieldDisabled}
                />
                <FormControlLabel
                  value="mac"
                  control={<Radio />}
                  label="macOS"
                  className={rootClasses.platformFormControlLabel}
                  disabled={isRadioFieldDisabled}
                />
                <FormControlLabel
                  value="linux"
                  control={<Radio />}
                  label="Linux"
                  className={rootClasses.platformFormControlLabel}
                  disabled={isRadioFieldDisabled}
                />
              </RadioGroup>
            </div>
          </div>
          <div className={rootClasses.agentSetupStepContainer}>
            <div className={rootClasses.circularNumberContainer}>
              <p>3</p>
            </div>
            <div className={rootClasses.stepTextOuterContainer}>
              <div className={rootClasses.stepTextInnerContainer}>
                <p>
                  Copy custom installation script
                  {/* 
                      FYI: hiding this link until GA
                      <a 
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        href={blumiraAgentSetupUrl}
                      >
                        Blumira Agent setup guide <OpenInNewIcon/>
                      </a> 
                    */}
                </p>
              </div>
              <TextField
                value={installationScript}
                className={rootClasses.textField}
                inputProps={{ readOnly: true }}
                disabled={isRadioFieldDisabled}
              />
              <Button
                variant={"outlined"}
                className={rootClasses.copyButton}
                onClick={handleCopyInstallationScript}
                disabled={isCopyButtonDisabled(
                  selectedInstallationKey,
                  defaultKeyValue
                )}
              >
                Copy
              </Button>
            </div>
          </div>
        </div>

        <SimpleTable
          isNorthStar
          data={props.keys}
          isFetching={!props.ready}
          title={"Installation Keys"}
          columns={getInstallationTableColumns(
            orgGroupInstalledDeviceCount,
            props.currentOrg?.config?.agent_seat_count
          )}
          emptyText={<span>No keys have been added yet</span>}
          description={
            <p className={rootClasses.description}>
              Create one installation key for your entire organization or create
              installation groups using unique keys.{" "}
              <a
                href={"https://blumira.help/agent-overview"}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                Learn More <OpenInNewIcon />
              </a>
            </p>
          }
          toolbarActions={[
            {
              icon: AddCircleIcon,
              onClick: handleOpenKeyDialog,
              tooltip: "Add installation key",
              disabled: isOrgMaxAgentsReached,
            },
          ]}
          contextMenuItems={[
            {
              text: "Installation key details",
              onClick: ({ model }) => handleEditTableRowClick(model),
              datacy: "installationKeyDetailsContextMenuItem",
            },
          ]}
        />
      </LicenseRestriction>
      <InstallationDialog
        open={isDetailsModalOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth={true}
        datacy={"installationKeyGroupDetailsModal"}
      >
        <div className={dialogClasses.titleContainer}>
          <DialogTitle>Installation key details</DialogTitle>
          <div className={dialogClasses.closeIcon}>
            <IconButton color={"default"} onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <DialogContent className={dialogClasses.dialogContent}>
          {errorMessage && (
            <Alert severity="error" className={dialogClasses.dialogAlert}>
              {errorMessage}
            </Alert>
          )}
          {isLoading ? (
            <div className={dialogClasses.loadingDialogContainer}>
              <CircularProgress color={"primary"} size={30} />
              <p>Loading your installation key details...</p>
            </div>
          ) : (
            <>
              <TextField
                className={dialogClasses.textField}
                variant={"outlined"}
                label={"Description"}
                value={selectedGroupDescription}
                onChange={handleDescriptionChange}
                datacy={"installationKeyGroupDescription"}
              />
              <TextField
                className={dialogClasses.textField}
                variant={"outlined"}
                label={"Device limit for this key"}
                value={selectedAgentSeatCount}
                type={"number"}
                onChange={handleAgentSeatCountChange}
                inputProps={{
                  min:
                    selectedGroupModel.id && selectedGroupModel.agentCount !== 0
                      ? selectedGroupModel.agentCount
                      : 1,
                  max: orgTotalGroupSeatCount,
                }}
                helperText={
                  errorHelperText.length
                    ? errorHelperText
                    : `* Required (minimum of 1) - ${orgGroupDeviceLeftOverCount} more available`
                }
                error={errorHelperText.length ? true : false}
                datacy={"installationKeyGroupSeatCount"}
              />
              {/* 
                we should only show key value when the selected
                model has one i.e. it's been created already 
              */}
              {selectedGroupModel.installationKey && (
                <TextField
                  className={dialogClasses.textField}
                  inputProps={
                    isKeyMasked
                      ? {
                          style: {
                            fontFamily: "text-security-disc",
                          },
                        }
                      : {}
                  }
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment
                          className={dialogClasses.inputIcons}
                          position={"end"}
                        >
                          <IconButton
                            onClick={() => setIsKeyMasked(!isKeyMasked)}
                            disabled={
                              selectedGroupModel.agentCount ===
                              selectedGroupModel.agentSeatCount
                                ? true
                                : false
                            }
                            className={dialogClasses.inputIcons}
                          >
                            {isKeyMasked ? <RemoveRedEye /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                        <InputAdornment position={"end"}>
                          <IconButton
                            onClick={handleCopyKeyValue}
                            disabled={
                              selectedGroupModel.agentCount ===
                              selectedGroupModel.agentSeatCount
                                ? true
                                : false
                            }
                            className={dialogClasses.inputIcons}
                          >
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                  label="Key"
                  value={selectedGroupModel.installationKey}
                  disabled={
                    selectedGroupModel.agentCount ===
                    selectedGroupModel.agentSeatCount
                      ? true
                      : false
                  }
                  datacy={"installationKeyGroupInstallKey"}
                />
              )}
              {selectedGroupModel.id && (
                // installationScript && // TODO: either keep this, adjust this, or remove it...
                <>
                  <FormControl
                    component="fieldset"
                    className={dialogClasses.platformGroupWrapper}
                  >
                    <FormLabel
                      component="legend"
                      className={dialogClasses.platformFormLabel}
                    >
                      Platform
                    </FormLabel>
                    <RadioGroup
                      aria-label="platform"
                      name="platform"
                      value={platformValue}
                      onChange={handlePlatformValueChange}
                    >
                      <FormControlLabel
                        value="windows"
                        control={<Radio />}
                        label="Windows"
                        className={dialogClasses.platformFormControlLabel}
                      />
                      <FormControlLabel
                        value="mac"
                        control={<Radio />}
                        label="macOS"
                        className={dialogClasses.platformFormControlLabel}
                      />
                      <FormControlLabel
                        value="linux"
                        control={<Radio />}
                        label="Linux"
                        className={dialogClasses.platformFormControlLabel}
                      />
                    </RadioGroup>
                  </FormControl>
                  {renderInstallKeyField()}
                </>
              )}
            </>
          )}
        </DialogContent>
        {!isLoading && (
          <DialogActions className={dialogClasses.dialogActions}>
            <Button
              variant={"text"}
              onClick={toggleRemoveKeyDialog}
              className={dialogClasses.errorTextButton}
              datacy={"installationKeyGroupDeleteBtn"}
            >
              <DeleteIcon /> Remove installation key
            </Button>
            <div>
              <Button
                color={"primary"}
                style={{ marginRight: 20 }}
                onClick={handleCloseModal}
                datacy={"installationKeyGroupCancelBtn"}
              >
                Cancel
              </Button>
              <Button
                color={"primary"}
                variant={"contained"}
                onClick={handleSubmit}
                disabled={isSaving}
                datacy={"installationKeyGroupSaveBtn"}
              >
                {isSaving ? "Saving..." : "Save changes"}
              </Button>
            </div>
          </DialogActions>
        )}
      </InstallationDialog>
      <InstallationDialog
        open={isRemoveKeyDialogOpen}
        onClose={toggleRemoveKeyDialog}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        {/* TODO: Add a dynamic number after "all" here*/}
        <DialogContent>
          This will disable all endpoints that use this installation key.
        </DialogContent>
        <DialogActions>
          <Button
            variant={"text"}
            onClick={toggleRemoveKeyDialog}
            color={"primary"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveKey}
            variant={"contained"}
            className={dialogClasses.errorContainedButton}
            datacy={"installationKeyGroupDeleteConfirmBtn"}
          >
            Remove installation key
          </Button>
        </DialogActions>
      </InstallationDialog>
      <Dialog open={isRadioInfoModalOpen} onClose={closeRadioInfoModal}>
        <DialogContent>Please select an installation key</DialogContent>
        <DialogActions>
          <Button
            onClick={closeRadioInfoModal}
            variant={"contained"}
            color={"primary"}
            datacy={""}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <ActivationConfirmationDialog
        wasEmailSuccessful={wasEmailSuccessful}
        open={isActivationConfirmationDialogOpen}
        onClose={toggleActivationConfirmationDialog}
        handleActivateDevicesFeature={handleActivateDevicesFeature}
      />
      <AddAgentDialog
        keys={props.keys}
        open={isAddAgentDialogOpen}
        onClose={toggleAddAgentsDialog}
        handleAddAgents={handleAddAgents}
        selectedGroupId={selectedGroupId}
        isSuperAdmin={props.currentUser.superadmin}
        existingCount={props.currentOrg?.config?.agent_seat_count}
        changeInstallationKey={(groupModelId) =>
          handleSelectGroupModelForAgentAddition(groupModelId)
        }
        handleReload={handleReloadPageData}
      />
    </Root>
  );
};

InstallationPageView.defaultProps = {
  keys: [],
  doesAnyChildOrgHaveLegacyLicense: false, // only 19 NFRs should be 'true' @06/25/24
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const orgId = location.payload.orgId;
  const currentUser = session.settings.user;

  const orgListToFilter = get(session, `settings.userOrgs`, []);

  const currentOrg = session.settings.userOrgs.find(({ id }) => id === orgId);
  const currentOrgsParent = session.settings.userOrgs.find(
    ({ id }) => id === currentOrg?.parentId
  );

  // getChildOrgs expects a a list of orgs and an
  // org id to filter and returns an array of orgs
  const childOrgsList = getChildOrgs(orgListToFilter, orgId);
  const parentsChildOrgsList = getChildOrgs(
    orgListToFilter,
    currentOrgsParent?.id
  );

  // When the currentOrg is a parent, assess if any of its children have legacy licenses
  const doesAnyChildOrgHaveLegacyLicense = childOrgsList.some(hasLegacyLicense);
  // When the currentOrg is a child, assess if any of its parent's children have legacy licenses
  const doesAnyOfParentsChildrenHaveLegacyLicense =
    parentsChildOrgsList.some(hasLegacyLicense);

  const isOrgAChildOrg = isOrganizationChildOfNFR(currentOrg);
  const isOrgAParentOrg =
    currentOrg && currentOrg?.market === 20 && isNil(currentOrg.parentId);

  return {
    orgId,
    currentOrg,
    currentUser,
    isOrgAChildOrg,
    isOrgAParentOrg,
    license: state.license,
    doesAnyChildOrgHaveLegacyLicense,
    doesAnyOfParentsChildrenHaveLegacyLicense,
    currentOrgsParent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reload: (force) => dispatch(loadPageData(force)),
  reloadSettings: async () => {
    const settings = new Settings();
    return settings.read().then(() => {
      dispatch({ type: RECEIVE_SETTINGS, settings });
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallationPageView);
