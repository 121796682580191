import { loadPageData } from "redux/actions/Request";
import { RECEIVE_SETTINGS } from "redux/actions/Session";
import { navigateToDefault, PAGE } from "redux/actions/Page";
import { Settings } from "./lib/models/Settings";
import { auth, AUTH_STATUS } from "./lib/auth";
import { checkRoleNav } from "utils/sidebarUtils";
import { childrenPageViews } from "./views/pages";

const checkValidRoute = (state, settings) => {
  const orgId = state.page.payload.orgId;
  const currentSettings = settings ? settings : state.session.settings;
  const currentUser = currentSettings.user;

  return checkRoleNav(
    `${state.page.payload.toplevel}/${state.page.payload.secondlevel}`,
    currentUser,
    orgId,
    currentSettings
  );
};

const routes = {
  LOGIN: {
    requiresAuthentication: false,
    path: "/(login)?",
    thunk: (dispatch, getState, bag) => {
      // If the user is logged in, Manage Application Requirements
      const state = getState();
      if (auth.currentStatus() === AUTH_STATUS.LOGGED_IN) {
        if (!state.session.settings) {
          const userSubmittedOrgName = localStorage.getItem("orgName");
          const userSubmittedWebUrl = localStorage.getItem("orgWebUrl");
          const userSubmittedReferralSource =
            localStorage.getItem("referralSource");

          const newOrgParams = {
            webUrl: userSubmittedWebUrl,
            orgName: userSubmittedOrgName,
            referralSource: userSubmittedReferralSource,
          };

          // if we have an org name and web url in local storage
          // this user is using self signup and has filled out
          // our signup form to create a new organization
          const settings =
            userSubmittedOrgName && userSubmittedWebUrl
              ? new Settings(newOrgParams)
              : new Settings();

          settings.read().then(() => {
            dispatch({ type: RECEIVE_SETTINGS, settings });
            dispatch(navigateToDefault);
          });
        } else {
          dispatch(navigateToDefault);
        }
      }
    },
  },
  SIGNUP: {
    requiresAuthentication: false,
    path: "/(signup)?",
    thunk: (dispatch, getState, bag) => {
      // If the user is logged in, Manage Application Requirements
      const state = getState();
      if (auth.currentStatus() === AUTH_STATUS.LOGGED_IN) {
        if (!state.session.settings) {
          const settings = new Settings();
          settings.read().then(() => {
            dispatch({ type: RECEIVE_SETTINGS, settings });
            dispatch(navigateToDefault);
          });
        } else {
          dispatch(navigateToDefault);
        }
      }
    },
  },
  TOS: {
    requiresAuthentication: true,
    path: "/:orgId/tos",
    thunk: (dispatch, getState, bag) => {
      // Manage Application Requirements

      const state = getState();
      if (!state.session.settings) {
        const settings = new Settings();
        settings.read().then(() => {
          dispatch({ type: RECEIVE_SETTINGS, settings });
          // Manage Page Requirements

          if (settings?.user?.acceptedTos != null) {
            dispatch(navigateToDefault);
          } else if (state.page.payload.toplevel && state.page.payload.orgId) {
            dispatch(loadPageData(false));
          }
        });
      } else {
        // Manage Page Requirements

        if (state.session.settings.user.acceptedTos != null) {
          dispatch(navigateToDefault);
        } else if (state.page.payload.toplevel && state.page.payload.orgId) {
          dispatch(loadPageData(false));
        }
      }
    },
  },
  MONITORMODE: {
    requiresAuthentication: true,
    path: "/:orgId/monitor-mode",
    thunk: (dispatch, getState, bag) => {
      // Manage Application Requirements

      const state = getState();
      if (!state.session.settings) {
        const settings = new Settings();
        settings.read().then(() => {
          dispatch({ type: RECEIVE_SETTINGS, settings });
          // Manage Page Requirements
          if (state.page.payload.toplevel && state.page.payload.orgId) {
            dispatch(loadPageData(false));
          }
        });
      } else {
        // Manage Page Requirements
        if (state.page.payload.toplevel && state.page.payload.orgId) {
          dispatch(loadPageData(false));
        }
      }
    },
  },
  PAGE: {
    requiresAuthentication: true,
    path: "/:orgId/:toplevel?/:secondlevel?/:id1?/:id2?/:id3?",
    thunk: (dispatch, getState, bag) => {
      // Manage Application Requirements
      const pageConfig = childrenPageViews.find(
        (p) =>
          p.route ===
          `${bag.action.payload.toplevel}/${bag.action.payload.secondlevel}`
      );
      if (pageConfig?.redirect) {
        const splitRoute = pageConfig.redirect.split("/");
        if (splitRoute.length !== 2) {
          throw new Error(`Invalid redirect: ${pageConfig.redirect}`);
        }
        dispatch({
          type: PAGE,
          payload: {
            orgId: bag.action.payload.orgId,
            toplevel: splitRoute[0],
            secondlevel: splitRoute[1],
          },
        });
        return;
      }
      const state = getState();
      if (!state.session.settings) {
        const settings = new Settings();
        settings.read().then(() => {
          dispatch({ type: RECEIVE_SETTINGS, settings });
          // Manage Page Requirements
          if (
            state.page.payload.toplevel &&
            state.page.payload.orgId &&
            checkValidRoute(state, settings)
          ) {
            dispatch(loadPageData(false));
          } else {
            dispatch(navigateToDefault);
          }
        });
      } else {
        // if user is navigating with native browser
        // i.e. using back or forward buttons, force
        const force = bag.action?.meta?.location?.kind === "pop";

        // Manage Page Requirements
        if (
          state.page.payload.toplevel &&
          state.page.payload.orgId &&
          checkValidRoute(state)
        ) {
          dispatch(loadPageData(!!force));
        } else {
          dispatch(navigateToDefault);
        }
      }
    },
  },
};

export default routes;
