import React from "react";
import { connect } from "react-redux";
import Dashboard2 from "./Dashboard2";
import { filterSampleFindings } from "lib/util/FindingUtils";

// All jurisdictions but 30, which is Analyst findings
const ResponderPage = (props) => {
  const findings = filterSampleFindings(
    props.findings,
    props.license,
    props.integrations
  );
  return (
    <Dashboard2
      title="Responder Dashboard"
      pageRole="responder"
      {...props}
      findings={findings}
    />
  );
};

const mapStateToProps = (state, props) => ({
  license: state.license,
  theme: state.userInterface.theme ? state.userInterface.theme : "default", // to catch existing users
});

export default connect(mapStateToProps)(ResponderPage);
